/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "antd";
import { LOGO_LINK, PDF } from "../../Utils/Constant";
import KildeLogo from "../../Assets/Images/kilde-logo-white.svg";
import cookiepolicy from "../../Assets/Pdf/cookie policy.pdf";
import "./style.css";
import { Link } from "react-router-dom";
import Linkedin from "../../Assets/Images/linkedin.svg";
import X from "../../Assets/Images/x.svg";

const AuthFooter = () => {
  const [pdfModalVisible, setPdfModalVisible] = useState(false);

  const closePdfModal = () => {
    setPdfModalVisible(false);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup: Remove the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <div className="kd-authfooter-div">
        <div className="auth-footer">
          <div className="auth-footer-subdiv">
            <div className="logo auth-logo">
              <Link to={LOGO_LINK} target="_blank">
                <img
                  src={KildeLogo}
                  alt="kildelogo"
                  style={{ width: "120px", height: "40px" }}
                />
              </Link>
            </div>
            <div className="auth-footer-headers">
              <div className="links auth-footer-width ">
                <p
                  className="mt-0 cursor-pointer"
                  onClick={() => {
                    window.open("https://www.kilde.sg/", "_blank");
                  }}
                >
                  Individual
                </p>

                <p
                  onClick={() => {
                    window.open("https://www.kilde.sg/familyoffice", "_blank");
                  }}
                  className="cursor-pointer"
                >
                  Family Office
                </p>
                <p
                  className="mb-0 cursor-pointer"
                  onClick={() => {
                    window.open(
                      "https://assets.website-files.com/61c1fc0c4b29a848ee7d2928/65d5e781521a3b86742db1ff_Final%20Platform%20Stats.pdf",
                      "_blank"
                    );
                  }}
                >
                  {" "}
                  Statistics
                </p>
              </div>
              <div className="links auth-footer-width ">
                <p
                  className="mt-0 cursor-pointer"
                  onClick={() => {
                    window.open("https://www.kilde.sg/borrowers", "_blank");
                  }}
                >
                  Borrowers
                </p>
                <p
                  onClick={() => {
                    window.open("https://www.kilde.sg/about", "_blank");
                  }}
                  className="cursor-pointer"
                >
                  About
                </p>
                <p
                  className="mb-0 cursor-pointer"
                  onClick={() => {
                    window.open(
                      "https://www.kilde.sg/news-and-insights/insights",
                      "_blank"
                    );
                  }}
                >
                  {" "}
                  Insights
                </p>
              </div>
              <div className="links auth-footer-width ">
                <p
                  className="mt-0 cursor-pointer"
                  onClick={() => {
                    window.open("https://www.kilde.sg/faq", "_blank");
                  }}
                >
                  FAQ
                </p>
                <p
                  onClick={() => {
                    window.open("https://www.kilde.sg/contacts", "_blank");
                  }}
                  className="cursor-pointer"
                >
                  Contact
                </p>
                <p
                  onClick={() => {
                    window.open(
                      "https://www.kilde.sg/news-and-insights/in-the-press",
                      "_blank"
                    );
                  }}
                  className="cursor-pointer mb-0"
                >
                  Press
                </p>
              </div>
            </div>
          </div>
          <div className="social cursor-pointer">
            <p className="mt-0">Social:</p>
            <div>
              <img
                alt="linkedin"
                src={Linkedin}
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/kilde-financial-technologies/",
                    "_blank"
                  );
                }}
              ></img>
              <img
                alt="x"
                src={X}
                onClick={() => {
                  window.open("https://twitter.com/KILDE_FinTech", "_blank");
                }}
                style={{ marginLeft: "10px" }}
              ></img>
            </div>
          </div>
        </div>
        <div className="auth-footer-second-div">
          <div className="auth-footer-link">
            <div className="auth-footer-copyright">
              <Link>© 2024 Kilde</Link>
            </div>
            <div className="auth-footer-links">
              <div>
                <Link to={PDF.TERM_OF_USE} target="_blank">
                  Terms & Conditions
                </Link>
              </div>
              <div>
                <Link to={cookiepolicy} target="_blank">
                  Cookie Policy
                </Link>
              </div>
              <div>
                <Link target="_blank" to={PDF.PRIVACY_POLICY}>
                  Privacy Policy
                </Link>
              </div>
              <div>
                <Link target="_blank" to={PDF.COMPLAINT_POLICY}>
                  Complaints policy
                </Link>
              </div>
            </div>
            <div className="footer-design" style={{ textAlign: "right" }}>
              <Link
                style={{ textAlign: "right" }}
                to="https://taptap.studio/"
                target="_blank"
              >
                Design & Development
              </Link>
            </div>
          </div>
        </div>

        <Row>
          <Col xs={24} sm={24} md={20} lg={15}>
            <p className="auth-footer-botton-title  m-0">
              Disclaimer: KILDE PTE LTD is incorporated in Singapore under the
              registration no. 201929587K and holds a Capital Markets Services
              licence (CMS101016) issued by the Monetary Authority of Singapore
              to deal in capital markets products under the Securities and
              Futures Act (Cap. 289) and an Exempted Financial Advisor License
              under the Financial Adviser Act. The information on this website
              is intended for “institutional investors” and “accredited
              investors”, in accordance with the Securities and Futures Act
              (Cap. 289) of Singapore. Information provided on this website does
              not constitute an offer, invitation to invest, solicitation or
              advice on buying or selling financial instruments and other
              capital market products.
            </p>
          </Col>
        </Row>
      </div>
      <Modal
        className="sb-pdf-modal"
        centered
        open={pdfModalVisible}
        onCancel={closePdfModal}
        width={1000}
        footer={null}
      >
        <iframe
          className="mt-20"
          src={cookiepolicy}
          width="100%"
          height="500px"
          title="PDF Viewer"
        />
      </Modal>
    </div>
  );
};

export default AuthFooter;
