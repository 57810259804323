import React from "react";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../Config/Routes";

const OnboardingBanner = () => {
  const navigate = useNavigate();
  return (
    <div className="finish-onboard-div mb-16 w-100">
      <div style={{ color: "var(--kilde-blue)" }}>
        <p style={{ fontSize: 16, fontWeight: 600 }} className="mb-0 mt-0">
          Discover your investment potential!
        </p>
        <p
          className="mt-8 mb-0"
          style={{ fontSize: "14px", lineHeight: "20px" }}
        >
          Explore our deals while completing onboarding.
        </p>
      </div>
      <ButtonDefault
        title="Explore Deals"
        onClick={() => navigate(ROUTES.TRANCH_LISTING)}
      />
    </div>
  );
};

export default OnboardingBanner;
