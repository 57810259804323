import React, { useEffect, useState } from "react";
import DashboardLayout from "../../Layouts/DashboardLayout/DashboardLayout";
import { Content } from "antd/es/layout/layout";
import ReactLoading from "react-loading";
import { Breadcrumb, Button, Col, Divider, Row, Spin, message } from "antd";
import SMS from "../../Assets/Images/Icons/Dashboard/sms_icon.svg";
import Device from "../../Assets/Images/Icons/Dashboard/totp_icon.svg";

import { Link, useNavigate } from "react-router-dom";
import ROUTES from "../../Config/Routes";
import { getUser, setupSms, setupTotp } from "../../Apis/UserApi";
import { useDispatch, useSelector } from "react-redux";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault";
import twofa_SMS from "../../Assets/Images/twofasms.svg";
import twofa_TOTP from "../../Assets/Images/totp.svg";
import { setUserDetails } from "../../Redux/Action/User";
import { LoadingOutlined } from "@ant-design/icons";

const TwoFactorAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tOtpLoader, setTotpLoader] = useState(false);
  const user = useSelector((state) => state.user);
  const [smsLoader, setSmsLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);

  const handleSetupTotp = async () => {
    setTotpLoader(true);
    await setupTotp()
      .then((res) => {
        if (res) {
          navigate(ROUTES.TWO_FA_AUTHENTICATEAPP, { state: { totpRes: res } });
          setTotpLoader(false);
        }
      })
      .catch(() => {
        setTotpLoader(false);
      });
  };

  const handleConnect = async () => {
    setSmsLoader(true);
    await setupSms()
      .then((res) => {
        message.success(
          "We've sent an OTP to your mobile number. Please check your messages."
        );
        navigate(ROUTES.TWO_FA_SMS, {
          state: { code: false, set: true, codeVal: res?.code },
        });
        setSmsLoader(false);
      })
      .catch(() => {
        setSmsLoader(false);
      });
  };

  useEffect(() => {
    setPageLoader(true);
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      const response = await getUser();
      if (response) {
        await setUserDetails(response, dispatch);
        setPageLoader(false);
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setPageLoader(false);
      return null;
    }
  };

  return (
    <div>
      <DashboardLayout>
        <Spin
          indicator={
            <LoadingOutlined
              style={{ fontSize: 50, color: "var(--kilde-blue)" }}
            />
          }
          spinning={pageLoader}
        >
          <Content className="setting-page-div">
            <Breadcrumb>
              <Breadcrumb.Item
                onClick={() => navigate(ROUTES.SETTINGS)}
                className="cursor-pointer"
              >
                Personal Settings
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => navigate(ROUTES.TWO_FACTOR_AUTH)}
                className="cursor-pointer"
              >
                Two-factor authentication
              </Breadcrumb.Item>
            </Breadcrumb>
            {user?.secondFactorAuth === null ? (
              <>
                <p className="setting-head mb-0">Two-factor authentication</p>

                <p className="mt-10 mb-24 setting-page-sub-div">
                  Two-factor authentication adds an extra layer of security to
                  prevent unauthorized access. Don't wait – activate it today to
                  protect your investments!
                </p>
              </>
            ) : (
              <p className="setting-head mb-24">Two-factor authentication</p>
            )}

            {user?.secondFactorAuth === "SMS" ? (
              <div className="twofa-maindiv">
                <div style={{ display: "flex", gap: "16px" }}>
                  <img
                    src={twofa_SMS}
                    alt="sms"
                    style={{
                      marginBottom: "15px",
                    }}
                  />
                  <div>
                    <p className="twofa-sms-p mb-8">
                      You are using <b>SMS</b> services to receive one time
                      passwords for your account security.
                    </p>
                    <p className="twofa-sms-no">
                      Messages will come here <b>{user?.mobilePhone}</b>
                    </p>
                    <div className="setting-acc-info-div">
                      <p className="mb-0">
                        To change your phone number contact us at{" "}
                        <span>
                          <Link to="mailto:sales@kilde.sg">sales@kilde.sg</Link>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <Divider />
                <div className="sb-text-align ">
                  <p className="twofa-sms-no mt-0 mb-16">
                    Alternatively, you can use <b>Authenticator App</b> on your
                    mobile device to generate time based one time passwords.
                  </p>
                  <ButtonDefault
                    title="Switch to Authentificator App"
                    onClick={handleSetupTotp}
                    loading={tOtpLoader}
                  />
                  {/* <div className="twofa-whatisit">
                      <p className="mt-4">what is it?</p>
                    </div> */}
                </div>
              </div>
            ) : user?.secondFactorAuth === "TOTP" ? (
              <div className="twofa-maindiv">
                <div style={{ display: "flex", gap: "16px" }}>
                  <img src={twofa_TOTP} alt="sms" />
                  <div>
                    <p className="twofa-sms-p">
                      You are using <b>Authenticator App </b> to generate
                      one-time password for your accont security.
                    </p>
                  </div>
                </div>
                <Divider />
                <div className="sb-text-align ">
                  <p className="twofa-sms-no mt-0 mb-16">
                    Alternatively, you can setup sending of authentification
                    token using <b>SMS</b> servides.
                  </p>
                  <ButtonDefault
                    title="Switch to SMS"
                    onClick={handleConnect}
                    loading={smsLoader}
                    className="mb-4"
                  />
                  {/* <div className="twofa-whatisit">
                      <p className="mt-4">what is it?</p>
                    </div> */}
                </div>
              </div>
            ) : (
              <>
                <div className="twofa-main">
                  <div className="setting-twofa twofa-margin">
                    <div className="twofa-second-div">
                      <img
                        src={SMS}
                        alt="sms"
                        style={{ width: 48, height: 48, marginBottom: "16px" }}
                      />

                      <p className="auth-head m-0">SMS</p>
                      <p className="twofa-tag ">
                        receive SMS message <br /> with login code
                      </p>
                    </div>
                    <Button
                      className="twofa-setup-button"
                      onClick={() => navigate(ROUTES.TWO_FA_SMS)}
                    >
                      Set up
                    </Button>
                    {/* <div className="twofa-whatisit">
                      <p className="mt-4">what is it?</p>
                    </div> */}
                  </div>
                  <div
                    className="setting-twofa"
                    style={{ position: "relative" }}
                  >
                    {" "}
                    {tOtpLoader === true && (
                      <div
                        style={{
                          position: "absolute",

                          left: "50%",
                          top: "50%",
                          zIndex: 99,
                          transform: "translate(-37px, -33px)",
                        }}
                      >
                        <ReactLoading
                          type="spin"
                          color="var(--kilde-blue)"
                          height={60}
                          width={60}
                        />
                      </div>
                    )}
                    <div className="twofa-second-div">
                      <img
                        src={Device}
                        alt="authenticateapp"
                        style={{ width: 48, height: 48, marginBottom: "16px" }}
                      />
                      <p className=" auth-head m-0">Authenticator app</p>
                      <p className="twofa-tag">
                        use authenticator app on your mobile device to generate
                        one-time password for login
                      </p>
                    </div>
                    <Button
                      className="twofa-setup-button"
                      onClick={handleSetupTotp}
                    >
                      Set up
                    </Button>
                    {/* <div className="twofa-whatisit">
                      <p className="mt-4">what is it?</p>
                    </div> */}
                  </div>
                </div>
              </>
            )}
          </Content>
        </Spin>
      </DashboardLayout>
    </div>
  );
};

export default TwoFactorAuth;
