/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FinishOnboardingModal from "../../../Layouts/DashboardLayout/FinishOnboardingModal";
import DashboardLayout from "../../../Layouts/DashboardLayout/DashboardLayout";
import Balance from "./Balance";
import "./Style.css";
import { Row, Tabs } from "antd";
import Deposit from "./Deposit";
import AddBank from "./AddBank";
import Withdraw from "./Withdraw";
import ChangeBank from "./ChangeBank";
import { getUser } from "../../../Apis/UserApi";
import { setUserDetails } from "../../../Redux/Action/User";
import { useDispatch, useSelector } from "react-redux";
import CurrencyExchange from "./CurrencyExchange";
import { GetBankAccountApi } from "../../../Apis/WalletApi";
import { setAccountDetails } from "../../../Redux/Action/Wallet";
import FinishOnboarding from "../Investment/FinishOnboarding";

const Wallet = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [tabKey, setTabKey] = useState(false);
  const user = useSelector((state) => state.user);
  const accountNo = useSelector(
    (state) => state?.wallet?.bankAccount?.bankAccounts
  );

  useEffect(() => {
    getBankAccountNo();
  }, []);

  const getBankAccountNo = async () => {
    try {
      const response = await GetBankAccountApi();
      if (response) {
        await setAccountDetails(response, dispatch);
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    const response = await getUser();
    if (response) {
      await setUserDetails(response, dispatch);
      return response;
    }
  };

  const onChange = (key) => {
    setTabKey(key);
  };

  const items = [
    {
      key: "1",
      label: "Deposit",
      children: <Deposit />,
    },
    {
      key: "2",
      label: "Withdraw",
      children: <Withdraw tabKey={tabKey} />,
    },
    {
      key: "3",
      label: "Currency Exchange",
      children: <CurrencyExchange />,
    },
  ];

  return (
    <DashboardLayout>
      <div className="wallet-maindiv">
        {user?.investorStatus !== "ACTIVE" ||
        (user?.secondFactorAuth === null &&
          user?.twoFaCheckEnabled === true) ? (
          <div className="mb-24">
            <FinishOnboarding />
          </div>
        ) : null}
        <p className="mt-0 mb-24 setting-head">Wallet</p>
        <Row className="mt-24 media-borrower-b-row">
          <Balance />
        </Row>
        <Row className="mt-16 media-borrower-b-row">
          {accountNo?.length > 0 ? <ChangeBank /> : <AddBank />}
        </Row>

        <div className="mt-40">
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            className="wallet-tab"
          />
        </div>
      </div>
      <FinishOnboardingModal
        title="Wallet"
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </DashboardLayout>
  );
};

export default Wallet;
