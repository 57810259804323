import API_ROUTES from "../Config/ApiRoutes";
import { EMPTY_ARRAY, REQUEST_METHODS } from "../Utils/Constant";
import { apiHandler, dowloadReports, TrancheAcceptancedowload } from "../Utils/Helpers";

export const TranchListing = async (data) => {
    try {
        const url = API_ROUTES.DASHBOARD.TRANCH_LISTING;
        const result = await apiHandler(REQUEST_METHODS.POST, url, data);
        return result;
    } catch {
        return EMPTY_ARRAY;
    }
};

export const InvestTranche = async (data) => {
    try {
        const url = API_ROUTES.DASHBOARD.INVEST_TRANCHE;
        const result = await apiHandler(REQUEST_METHODS.POST, url, data);
        return result;
    } catch {
        return EMPTY_ARRAY;
    }
};

export const trancheAcceptanceDownload = async (data) => {
    try {
        const url = `${API_ROUTES.DASHBOARD.TRANCHE_NO_OF_ACCEPTANCE}?trancheUuid=${data.trancheId}&amount=${data.amount}`;
        const result = await TrancheAcceptancedowload(REQUEST_METHODS.GET, url, {},);
        return result;
    } catch {
        return EMPTY_ARRAY;
    }
};

export const RequestCurrencyExchange = async (data) => {
    try {
        const url = API_ROUTES.DASHBOARD.CURRENCY_EXCHANGE;
        const result = await apiHandler(REQUEST_METHODS.POST, url, data);
        return result;
    } catch {
        return EMPTY_ARRAY;
    }
};
export const DashboardApi = async (data) => {
    try {
        const url = API_ROUTES.DASHBOARD.DASHBOARD;
        const result = await apiHandler(REQUEST_METHODS.POST, url, data);
        return result;
    } catch {
        return EMPTY_ARRAY;
    }
};

export const Invest = async (data) => {
    try {
        const url = API_ROUTES.DASHBOARD.INVEST;
        const result = await apiHandler(REQUEST_METHODS.POST, url, data);
        return result;
    } catch {
        return EMPTY_ARRAY;
    }
};

export const CommitInvest = async (data) => {
    try {
        const url = API_ROUTES.DASHBOARD.COMMIT_INVEST;
        const result = await apiHandler(REQUEST_METHODS.POST, url, data);
        return result;
    } catch {
        return EMPTY_ARRAY;
    }
};

export const CancelInvest = async (data) => {
    try {
        const url = API_ROUTES.DASHBOARD.CANCEL_INVEST;
        const result = await apiHandler(REQUEST_METHODS.POST, url, data);
        return result;
    } catch {
        return EMPTY_ARRAY;
    }
};
export const CapitalCallList = async (data) => {
    try {
        const url = API_ROUTES.DASHBOARD.CAPITAL_CALL_REQUEST_LIST;
        const result = await apiHandler(REQUEST_METHODS.POST, url, data);
        return result;
    } catch {
        return EMPTY_ARRAY;
    }
};

export const CapitalCallRequest = async (data) => {
    try {
        const url = API_ROUTES.DASHBOARD.CAPITAL_CALL_REQUEST;
        const result = await apiHandler(REQUEST_METHODS.POST, url, data);
        return result;
    } catch {
        return EMPTY_ARRAY;
    }
};
export const CancelCapitalCallRequest = async (data) => {
    try {
        const url = API_ROUTES.DASHBOARD.CANCEL_CAPITAL_CALL;
        const result = await apiHandler(REQUEST_METHODS.POST, url, data);
        return result;
    } catch {
        return EMPTY_ARRAY;
    }
};
export const InvestmentSummary = async (data) => {
    try {
        const url = API_ROUTES.DASHBOARD.INVESTMENT_SUMMARY;
        const result = await apiHandler(REQUEST_METHODS.POST, url, data);
        return result;
    } catch {
        return EMPTY_ARRAY;
    }
};
export const UnsubscribeTranche = async (data) => {
    try {
        const url = API_ROUTES.DASHBOARD.TRANCHE_UNSUBSCRIBE;
        const result = await apiHandler(REQUEST_METHODS.POST, url, data);
        return result;
    } catch {
        return EMPTY_ARRAY;
    }
};

export const AccountStatementSummary = async (data) => {
    try {
        const url = API_ROUTES.DASHBOARD.ACCOUNT_STATEMENT_SUMMARY;
        const result = await apiHandler(REQUEST_METHODS.POST, url, data);
        return result;
    } catch {
        return EMPTY_ARRAY;
    }
};

export const AccountStatementSummaryDownload = async (data) => {
    try {
        const url = API_ROUTES.DASHBOARD.ACCOUNT_STATEMENT_SUMMARY_DOWNLOAD;
        const result = await dowloadReports(REQUEST_METHODS.POST, url, data,);
        return result;
    } catch {
        return EMPTY_ARRAY;
    }
};

