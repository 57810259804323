import {
  Button,
  Col,
  Modal,
  Pagination,
  Row,
  Select,
  Spin,
  Table,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import ROUTES from "../../../Config/Routes";
import { useNavigate } from "react-router-dom";
import Arrow from "../../../Assets/Images/arrow.svg";
import Close from "../../../Assets/Images/Icons/Dashboard/close_icon.svg";
// import Down_arrow from "../../../Assets/Images/Icons/Dashboard/down_arrow.svg";
import "./style.css";
import {
  InvestmentSummary,
  UnsubscribeTranche,
} from "../../../Apis/DashboardApi";
import { britishFormatDate } from "../../../Utils/Helpers";
import ButtonDefault from "../../../Components/ButtonDefault/ButtonDefault";
import InvestmentCard from "./InvestmentCard";
import { LoadingOutlined } from "@ant-design/icons";
import NoInvestmentIcon from "../../../Assets/Images/noinvestement.svg";
import { formatCurrency } from "../../../Utils/Reusables";

const DashboardInvestment = ({ showButtonActive, currencyCode }) => {
  const navigate = useNavigate();
  const [totalItem, setTotalItem] = useState();
  const [investSummaryList, setInvestSummaryList] = useState();
  const [investSummaryLoader, setInvestSummaryLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemPerPage] = useState(5);
  const [cancelCommitModal, setCancelCommitModal] = useState(false);
  const [cancelCommitLoading, setCancelCommitLoading] = useState(false);
  const [cancelCommitInvestId, setCancelCommitInvestId] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [principal_invest_sort, setPrincipal_invest_sort] = useState("");
  const [maturityDate_sort, setMaturityDate_sort] = useState("");
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [handleField, setHandleField] = useState(null);
  const { Option } = Select;
  const [filterVisible, setFilterVisible] = useState(false);
  const [isStatusClicked, setIsStatusClicked] = useState(false);

  const handleFilterChange = (value) => {
    setSelectedFilter(value);
    setFilterVisible(false);
  };

  const handleClearFilter = () => {
    setSelectedFilter(null);
    setFilterVisible(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleViewTranche = (trancheUuid) => {
    navigate(`${ROUTES.TRANCH_INVEST}/${trancheUuid}`);
  };
  const outstandingPaymentColumns = [
    {
      title: "Tranche number / Company",
      dataIndex: "trancheNumber",
    },
    {
      title: "Kilde Rating",
      dataIndex: "kildeRating",
    },
    {
      title: "Interest Rate",
      dataIndex: "interestRate",
    },
    {
      title: "Interest Received",
      dataIndex: "interestReceived",
    },
    {
      title: "Outstanding Principal",
      dataIndex: "outstandingPrincipal",
      sorter: (a, b) => a.age - b.age,
      sortOrder: principal_invest_sort,
      onHeaderCell: () => ({
        onClick: () => {
          if (principal_invest_sort === "ascend") {
            setPrincipal_invest_sort("descend");
            setMaturityDate_sort("");
          } else if (principal_invest_sort === "descend") {
            setPrincipal_invest_sort("");
          } else {
            setPrincipal_invest_sort("ascend");
            setMaturityDate_sort("");
          }
        },
        sortDirections: ["ascend", "descend", "ascend"],
      }),
    },
    {
      title: "Maturity Date",
      dataIndex: "maturityDate",
      sorter: (a, b) => a.age - b.age,
      sortOrder: maturityDate_sort,
      onHeaderCell: () => ({
        onClick: () => {
          if (maturityDate_sort === "ascend") {
            setMaturityDate_sort("descend");
            setPrincipal_invest_sort("");
          } else if (maturityDate_sort === "descend") {
            setMaturityDate_sort("");
          } else {
            setMaturityDate_sort("ascend");
            setPrincipal_invest_sort("");
          }
        },
        sortDirections: ["ascend", "descend", "ascend"],
      }),
    },
    {
      title: "Next Payment",
      dataIndex: "nextPayment",
    },
    {
      title: () => (
        <div
          onClick={() => {
            setFilterVisible(!filterVisible);
            setIsStatusClicked(!filterVisible);
          }}
          style={{
            color: isStatusClicked ? "#22B5E9" : "inherit",
            cursor: "pointer",
          }}
        >
          Status
        </div>
      ),
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Outstanding",
          value: "OUTSTANDING",
        },
        {
          text: "Committed",
          value: "COMMITED",
        },
        {
          text: "Repaid",
          value: "REPAID",
        },
      ],
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8, display: "flex", flexDirection: "column" }}>
          <Select
            defaultValue={selectedFilter || "Select Status"}
            onChange={(value) => {
              handleFilterChange(value);
              confirm({ closeDropdown: true });
            }}
          >
            <Option value="OUTSTANDING">
              <p className="invest-repaid-p">Outstanding</p>
            </Option>
            <Option value="COMMITED">
              <p className="invest-outstanding-p">Committed</p>
            </Option>
            <Option value="REPAID">
              <p className="invest-committed-p">Repaid</p>
            </Option>{" "}
          </Select>
          <Button
            onClick={() => {
              handleClearFilter();
              confirm({ closeDropdown: true });
            }}
            className="mt-4"
            disabled={selectedFilter === null}
          >
            Clear Filter
          </Button>
        </div>
      ),
      filterDropdownOpen: filterVisible,
      onFilterDropdownOpenChange: (visible) => setFilterVisible(visible),
      onFilter: (value, record) => record.status.includes(value),
    },
    {
      title: " ",
      dataIndex: "arrow",
    },
    {
      title: " ",
      dataIndex: "sign",
    },
  ];

  const outstandingPaymentData =
    investSummaryList?.length > 0 &&
    investSummaryList?.map((item, index) => {
      return {
        key: index,
        uuid: item?.uuid,
        trancheNumber: (
          <div className="cursor-pointer">
            <p className="m-0">{item?.trancheNumber}</p>
            <h3 className="m-0">{item?.companyName}</h3>
          </div>
        ),
        kildeRating: item?.creditRating,
        interestRate: formatCurrency("", item?.interestRate) + "%",

        interestReceived:
          item?.investmentStatus === "COMMITED"
            ? "-"
            : formatCurrency(item?.currencySymbol, item?.interestPaid),
        outstandingPrincipal: (
          <>
            <p className="m-0">
              {formatCurrency(item?.currencySymbol, item?.principalInvestment)}
            </p>
            {item?.investmentStatus === "OUTSTANDING" &&
            item?.principalOsByAutoInvest > 0 ? (
              <p className="m-0">
                {`Auto: ${formatCurrency(
                  item?.currencySymbol,
                  item?.principalOsByAutoInvest
                )}`}
              </p>
            ) : item?.investmentStatus === "COMMITED" &&
              item?.principalSubscribedByAutoInvest > 0 ? (
              <p className="m-0">
                {`Auto: ${formatCurrency(
                  item?.currencySymbol,
                  item?.principalSubscribedByAutoInvest
                )}`}
              </p>
            ) : null}
          </>
        ),
        maturityDate: britishFormatDate(item?.maturityDate),
        nextPayment: (
          <div>
            <p className="dashborad-inv-text">
              {item?.investmentStatus === "OUTSTANDING"
                ? britishFormatDate(item?.nextPaymentDate)
                : item?.investmentStatus === "COMMITED"
                ? britishFormatDate(item?.nextPaymentDateOfSubscribed)
                : "-"}
            </p>
            <p className="dashborad-inv-text">
              {item?.investmentStatus === "OUTSTANDING"
                ? item?.nextPaymentDate === null
                  ? ""
                  : formatCurrency(
                      item?.currencySymbol,
                      item?.nextPaymentAmount
                    )
                : item?.investmentStatus === "COMMITED"
                ? item?.nextPaymentDateOfSubscribed === null
                  ? ""
                  : formatCurrency(
                      item?.currencySymbol,
                      item?.nextPaymentAmountOfSubscribed
                    )
                : null}
            </p>
            {item?.dpd > 0 ? (
              <p className="invest-table-warning-msg">
                {item?.investmentStatus === "OUTSTANDING"
                  ? `Days past due: ${item?.dpd}`
                  : null}
              </p>
            ) : null}
          </div>
        ),
        status:
          item?.investmentStatus === "OUTSTANDING" ? (
            <p className="invest-repaid-p">Outstanding</p>
          ) : item?.investmentStatus === "REPAID" ? (
            <p className="invest-committed-p">Repaid</p>
          ) : (
            <p className="invest-outstanding-p">Committed</p>
          ),
        arrow: (
          <div onClick={() => handleViewTranche(item?.uuid)}>
            <img src={Arrow} alt="right_arrow" className="cursor-pointer" />
          </div>
        ),
        sign:
          item?.investmentStatus === "COMMITED" ? (
            <div
              className="cursor-pointer"
              onClick={() => handleOpenModal(item?.uuid)}
            >
              <img src={Close} alt="close_icon" />
            </div>
          ) : null,
      };
    });
  const handleOpenModal = (capitalId) => {
    setCancelCommitInvestId(capitalId);
    setCancelCommitModal(true);
  };
  const onShowSizeChange = (current, pageSize) => {
    setItemPerPage(pageSize);
  };

  const handleChange = (page) => {
    setCurrentPage(page);
    handleInvestPortfolioList(page, itemsPerPage);
  };

  const handleInvestPortfolioList = (page, items) => {
    setInvestSummaryLoader(true);
    const filterPayload = {
      page: page,
      pageSize: items,
      currencyCode: currencyCode,
      ordering: [],
    };
    if (principal_invest_sort !== "") {
      filterPayload.ordering.push({
        field: "principal_invest",
        asc: principal_invest_sort === "ascend" ? true : false,
      });
    }
    if (maturityDate_sort !== "") {
      filterPayload.ordering.push({
        field: "maturity_date",
        asc: maturityDate_sort === "ascend" ? true : false,
      });
    }
    if (selectedFilter !== null) {
      filterPayload["investmentStatus"] = selectedFilter;
    }
    InvestmentSummary(filterPayload).then(async (summary) => {
      setInvestSummaryLoader(false);
      setTotalItem(summary?.totalItems);
      setInvestSummaryList(summary?.items);
    });
  };

  const resetPageAndFetch = () => {
    setCurrentPage(1);
    handleInvestPortfolioList(1, itemsPerPage);
  };

  useEffect(() => {
    if (
      principal_invest_sort !== undefined ||
      maturityDate_sort !== undefined ||
      selectedFilter !== undefined ||
      currencyCode !== undefined ||
      itemsPerPage !== 5
    ) {
      resetPageAndFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    principal_invest_sort,
    maturityDate_sort,
    selectedFilter,
    currencyCode,
    itemsPerPage,
  ]);

  const handleCancelCommitInvestment = async () => {
    setCancelCommitLoading(true);
    const data = {
      trancheUuid: cancelCommitInvestId,
    };
    try {
      const response = await UnsubscribeTranche(data);
      if (response?.trancheUuid) {
        message.success("Committed investment successfully canceled.");
        setCancelCommitModal(false);
        handleInvestPortfolioList(currentPage, itemsPerPage);
        setCancelCommitLoading(false);
        setCancelCommitInvestId("");
      } else {
        setCancelCommitLoading(false);
      }
    } catch (error) {
      message.error(error?.message);
      setCancelCommitLoading(false);
      throw error;
    }
  };
  const handleFieldValue = (field) => {
    setHandleField(field);
  };
  const handleFieldWiseSorting = (value) => {
    if (handleField === "principal_invest") {
      setPrincipal_invest_sort(value === "ascend" ? true : false);
    } else if (handleField === "maturity_date") {
      setMaturityDate_sort(value === "ascend" ? true : false);
    } else {
      setHandleField(value);
      setPrincipal_invest_sort("");
      setMaturityDate_sort("");
    }
  };
  // const handleClearFilterandSorting = () => {
  //   setPrincipal_invest_sort("");
  //   setMaturityDate_sort("");
  //   setSelectedFilter(null);
  // };
  return (
    <Row className="mt-0 mb-10 media-borrower-b-row">
      {showButtonActive && windowWidth <= 768 ? (
        <div>
          <div className="sorting-div">
            <div className="d-flex">
              <p className="cursor-pointer m-0">
                Sort by:
                <Select
                  defaultValue="Outstanding Payment"
                  className="dashboard-sorting-selectbox"
                  onChange={(value) => handleFieldValue(value)}
                  options={[
                    {
                      value: "principal_invest",
                      label: "Outstanding Principal",
                    },
                    {
                      value: "maturity_date",
                      label: "Maturity Date",
                    },
                    {
                      value: "investmentStatus",
                      label: "Status",
                    },
                  ]}
                />
              </p>
              {handleField === "principal_invest" ||
              handleField === "maturity_date" ||
              handleField === null ? (
                <p className="cursor-pointer m-0">
                  Sort from:
                  <Select
                    placeholder="Highest"
                    className="dashboard-sorting-selectbox"
                    onChange={(value) => handleFieldWiseSorting(value)}
                    options={[
                      {
                        value: "descend",
                        label: "Highest",
                      },
                      {
                        value: "ascend",
                        label: "Lowest",
                      },
                    ]}
                  />
                </p>
              ) : (
                <p className="cursor-pointer m-0">
                  Filter by:
                  <Select
                    placeholder="Select Status"
                    defaultValue="OUTSTANDING"
                    className="dashboard-sorting-selectbox"
                    onChange={(value) => setSelectedFilter(value)}
                    options={[
                      {
                        value: "OUTSTANDING",
                        label: (
                          <p className="invest-outstanding-p">Outstanding</p>
                        ),
                      },
                      {
                        value: "COMMITED",
                        label: <p className="invest-committed-p">Committed</p>,
                      },
                      {
                        value: "REPAID",
                        label: <p className="invest-repaid-p">Repaid</p>,
                      },
                    ]}
                  />
                </p>
              )}
            </div>
            {/* <div>
              <p className="cursor-pointer m-0">
                <ButtonDefault
                  title="Clear sort & filter"
                  onClick={handleClearFilterandSorting}
                />
              </p>
            </div> */}
          </div>
          {investSummaryLoader ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 50, color: "var(--kilde-blue)" }}
                />
              }
              spinning={investSummaryLoader}
            />
          ) : investSummaryList?.length > 0 ? (
            investSummaryList?.map((item, index) => (
              <Col xs={24} sm={24} md={24} lg={8} key={index} className="mb-20">
                <InvestmentCard item={item} onCancelCommit={handleOpenModal} />
              </Col>
            ))
          ) : (
            <div className="no-investment-div ">
              <div className="sb-flex-column-item-center">
                <img src={NoInvestmentIcon} alt="no investment yet" />
                <p
                  style={{
                    color: "var(--black-20, #1A202C33)",
                    fontSize: "12px",
                    lineHeight: "18px",
                    fontWeight: "400",
                  }}
                >
                  No Investments yet
                </p>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Col className="gutter-row infomation-div" xs={24} sm={24} md={24}>
          {showButtonActive === false ? null : (
            <p className="m-0 mb-16 tranch-head">Investments</p>
          )}
          <Table
            scroll={{ x: "auto" }}
            columns={outstandingPaymentColumns}
            dataSource={outstandingPaymentData}
            className="trache-table outstanding-pay-table"
            pagination={false}
            loading={
              investSummaryLoader
                ? {
                    indicator: (
                      <div>
                        <Spin />
                      </div>
                    ),
                  }
                : false
            }
            onRow={(record) => {
              return {
                onClick: () => {
                  handleViewTranche(record.uuid);
                },
              };
            }}
          />
        </Col>
      )}
      <Col xs={24}>
        {investSummaryList?.length > 0 ? (
          <Pagination
            className="tranch-table-pagination"
            pageSize={itemsPerPage}
            current={currentPage}
            total={totalItem}
            onChange={handleChange}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            pageSizeOptions={["5", "20", "50", "100"]}
            locale={{
              items_per_page: " ",
            }}
          />
        ) : null}
      </Col>
      {/* sure or cancel committed investment modal */}
      <Modal
        centered
        open={cancelCommitModal}
        onCancel={() => {
          setCancelCommitModal(false);
        }}
        width={464}
        footer={null}
        maskClosable={false}
        className="withdraw-modal"
        closable={false}
      >
        <p className="mt-0 wallet-sub-head mb-24 sb-text-align">
          Are you sure you want to cancel committed Investment?
        </p>
        <div className="sb-text-align d-flex">
          <Button
            className="remove-modal-back-btn mr-8 w-100"
            onClick={() => {
              setCancelCommitModal(false);
            }}
          >
            Cancel
          </Button>
          <ButtonDefault
            loading={cancelCommitLoading}
            style={{ width: "100%" }}
            title="Confirm"
            onClick={() => {
              handleCancelCommitInvestment();
            }}
          />
        </div>
      </Modal>
    </Row>
  );
};
export default DashboardInvestment;
