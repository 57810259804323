import React, { useEffect } from "react";
import {
  Route,
  Routes,
  Navigate,
  Outlet,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";
import RegisterPage from "./Pages/RegisterPage/RegisterPage";
import LoginPage from "./Pages/LoginPage/LoginPage";
import TwoFAPage from "./Pages/TwoFAPage/TwoFAPage";
import VerificationPage from "./Pages/VerificationPage/VerificationPage";
import KYCStepperIndividualPage from "./Pages/KYCStepperIndividualPage/KYCStepperIndividualPage";
import KYCStepperOrganizationPage from "./Pages/KYCStepperOrganizationPage/KYCStepperOrganizationPage";
import ROUTES from "./Config/Routes";
import { isAuthenticated } from "./Config/authService";
import EmailVerificationPage from "./Pages/EmailVerificationPage/EmailVerificationPage";
import ForgotPasswordPage from "./Pages/ForgotPasswordPage/ForgotPasswordPage";
import EmailVerifiedPage from "./Pages/EmailVerificationPage/EmailVerifiedPage";
import VerifyPage from "./Pages/EmailVerificationPage/VerifyPage";
import SingpassRegisterPage from "./Pages/SingpassRegisterPage/SingpassRegisterPage";
import { auth } from "./Utils/firebase";
import Cookies from "js-cookie";
import { message } from "antd";
import KildeAccreditedAccess from "./Pages/KildeAccreditedAccess/KildeAccreditedAccess";
import RejectedUser from "./Pages/KYCStepperIndividualPage/RejectedUser/RejectedUser";

import "./App.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import "./index.css";
import GlobalVariabels from "./Utils/GlobalVariabels";
import SingpassLoader from "./Pages/SingpassRegisterPage/SingpassLoader";
import TranchListingPage from "./Pages/DashBoard/TranchListingPage/TranchListingPage";
import Borrower from "./Pages/DashBoard/Borrower/Borrower";
import Settings from "./Pages/Settings/Settings.jsx";
import ChangePassword from "./Pages/Settings/ChangePassword.jsx";
import AdditionalDocument from "./Pages/Settings/AdditionalDocument.jsx";
import TwoFactorAuth from "./Pages/Settings/TwoFactorAuth.jsx";
import TwofaSms from "./Pages/Settings/Twofa-SMS.jsx";
import TwofaAunthenticatorApp from "./Pages/Settings/Twofa-authapp.jsx";
import AccountStatement from "./Pages/DashBoard/AccountStatement/AccountStatement.jsx";
import Wallet from "./Pages/DashBoard/Wallet/wallet.jsx";
import Dashboard from "./Pages/DashBoard/Dashboard.jsx";
import CreateStrategyPage from "./Pages/CreateStrategyPage/CreateStrategyPage.jsx";
import EditStrategyPage from "./Pages/EditStratergyPage/EditStratergyPage.jsx";
import VeriffPage from "./Pages/VeriffPage/VeriffPage.jsx";
import { trackPage } from "./Utils/Analytics.js";

const PrivateRoute = () => {
  return isAuthenticated() ? <Outlet /> : <Navigate to={ROUTES.LOGIN} />;
};

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    trackPage(
      {
        name: location.pathname,
        path: location.pathname,
      },
      location
    );
  }, [location]);

  useEffect(() => {
    if (user) {
      if (user?.investorStatus === "REJECTED") {
        return navigate(ROUTES.KILDE_REJECTED_USER);
      }
    }
  }, [navigate, user]);

  useEffect(() => {
    const checkAndRefreshToken = async () => {
      const auth_inv_token = Cookies.get("auth_inv_token");
      if (auth_inv_token) {
        try {
          setTimeout(async () => {
            const idToken = await auth.currentUser?.getIdToken(
              /* forceRefresh */ true
            );

            if (idToken !== undefined) {
              Cookies.set("auth_inv_token", idToken);
            }
          }, 500);
        } catch (error) {
          message.error(error.message);
        }
      } else {
        Cookies.remove("user");
      }
    };
    checkAndRefreshToken();
  }, []);

  const currentUrl = window.location.href;
  if (currentUrl) {
    const urlSearchParams = new URLSearchParams(new URL(currentUrl).search);
    const code = urlSearchParams.get("code");
    if (code) {
      sessionStorage.setItem("sCode", code);
      window.location.href = ROUTES.SINGPASS_REGISTER;
    }
  }

  let currentVersion = GlobalVariabels.NODE_VERSION;
  let currentEnv = GlobalVariabels.NODE_ENV;

  return (
    <>
      <Routes>
        {/* PUBLIC ROUTES START */}
        <Route path="" element={<Navigate to="/login" replace />} />
        <Route path={ROUTES.REGISTER} element={<RegisterPage />} />
        <Route
          path={ROUTES.SINGPASS_REGISTER}
          element={<SingpassRegisterPage currentUrl={currentUrl} />}
        />
        <Route path={ROUTES.LOGIN} element={<LoginPage />} />
        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
        <Route path={ROUTES.VERIFY} element={<VerifyPage />} />
        <Route
          path={ROUTES.KILDE_ACCREDITED_USER}
          element={<KildeAccreditedAccess />}
        />
        <Route path={ROUTES.KILDE_REJECTED_USER} element={<RejectedUser />} />
        <Route path={ROUTES.CALLBACK} element={<SingpassLoader />} />
        <Route path={ROUTES.EMAIL_VERIFIED} element={<EmailVerifiedPage />} />
        {/* PUBLIC ROUTES END */}

        {/* PRIVATE ROUTES START */}
        <Route element={<PrivateRoute />}>
          <Route path={ROUTES.TWO_FA} element={<TwoFAPage />} />
          <Route
            path={ROUTES.EMAIL_VERIFICATION}
            element={<EmailVerificationPage />}
          />
          <Route path={ROUTES.VERIFICATION} element={<VerificationPage />} />
          <Route
            path={ROUTES.INDIVIDUAL_VERIFICATION}
            element={<KYCStepperIndividualPage />}
          />
          <Route
            path={ROUTES.ORGANIZATION_VERIFICATION}
            element={<KYCStepperOrganizationPage />}
          />
          <Route path={ROUTES.TRANCH_LISTING} element={<TranchListingPage />} />
          {/* <Route path={ROUTES.BORROWER} element={<Borrower />} /> */}
          <Route
            path={`${ROUTES.TRANCH_INVEST}/:slug`}
            element={<Borrower />}
          />
          <Route path={ROUTES.SETTINGS} element={<Settings />} />
          <Route path={ROUTES.CHANGE_PASSWORD} element={<ChangePassword />} />
          <Route
            path={ROUTES.ADDITIONAL_DOCUMENT}
            element={<AdditionalDocument />}
          />
          <Route path={ROUTES.TWO_FACTOR_AUTH} element={<TwoFactorAuth />} />
          <Route path={ROUTES.TWO_FA_SMS} element={<TwofaSms />} />
          <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
          <Route
            path={ROUTES.ACCOUNT_STATEMENT}
            element={<AccountStatement />}
          />
          <Route path={ROUTES.WALLET} element={<Wallet />} />
          <Route
            path={ROUTES.TWO_FA_AUTHENTICATEAPP}
            element={<TwofaAunthenticatorApp />}
          />
          <Route
            path={ROUTES.CREATE_STRATEGY}
            element={<CreateStrategyPage />}
          />
          <Route
            path={`${ROUTES.EDIT_STRATEGY}/:slug`}
            element={<EditStrategyPage />}
          />
        </Route>
        {/* PRIVATE ROUTES END */}

        {/* Other routes go here */}
        <Route path="*" element={<LoginPage />} />
        <Route path="/test" element={<VeriffPage />} />
      </Routes>

      {currentEnv === "DEV" && (
        <div className="kl-fixed-text" style={{ marginRight: 30 }}>
          <p style={{ textAlign: "right", color: "#ddd" }}>
            Dev Version: {currentVersion}
          </p>
        </div>
      )}
    </>
  );
}

export default App;
