import { Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import DeviceMobile from "../../Assets/Images/DeviceMobile.svg";
import OtpInput from "react18-input-otp";
import { enableSMS, getUser, setupSms } from "../../Apis/UserApi.js";
import GlobalVariabels from "../../Utils/GlobalVariabels.js";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault.jsx";
import { Link, useNavigate } from "react-router-dom";
import { setCurrentSate } from "../../Redux/Action/common.js";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../../Redux/Action/User.js";
import ROUTES from "../../Config/Routes.js";

const TwoFAModal = ({ twoFaModal, setTwoFaModal, setShouldNavigate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [time, setTime] = useState(59);
  const [setUpSMS, setSetUpSMS] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [otp, setOtp] = useState("");
  const [twoFaLoader, setTwoFaLoader] = useState(false);
  let currentEnv = GlobalVariabels.NODE_ENV;
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const current = useSelector((state) => state.common.current);

  const getUserDetails = async () => {
    try {
      const response = await getUser();
      if (response) {
        await setUserDetails(response, dispatch);
        return response;
      } else {
        console.error("Error fetching data:");
        return null;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  useEffect(() => {
    const intervalTimer = setInterval(() => {
      if (isTimerRunning) {
        setTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(intervalTimer);
            setIsTimerRunning(false);
          }
          return prevTime - 1;
        });
      }
    }, 1000);

    return () => clearInterval(intervalTimer);
  }, [isTimerRunning]);

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTwoFaAuth = async () => {
    setTwoFaLoader(true);
    await setupSms()
      .then((res) => {
        message.success(
          "We've sent an OTP to your mobile number. Please check your messages."
        );
        setIsTimerRunning(true);
        setTime(59);
        setSetUpSMS(res);
        setTwoFaLoader(false);
      })
      .catch(() => {
        setTwoFaLoader(false);
      });
  };

  const handleEnableSMS = async () => {
    setTwoFaLoader(true);
    if (otp !== "") {
      const requestBody = {
        smsToken: otp,
      };
      const response = await enableSMS(requestBody);
      if (!response) {
        window?.dataLayer?.push({
          event: "authenticationTwoFactorConfirm",
        });
        message.success(
          "You've successfully enabled Two-Factor Authentication for your account."
        );
        setTwoFaLoader(false);
        setTwoFaModal(false);
        if (user?.singpassUser === true) {
          if (user?.investorType === "INDIVIDUAL") {
            navigate(ROUTES.INDIVIDUAL_VERIFICATION);
          } else {
            navigate(ROUTES.ORGANIZATION_VERIFICATION);
          }
        } else {
          if (user?.investorType === "INDIVIDUAL") {
            setCurrentSate(current + 1, dispatch);
          } else {
            setCurrentSate(2, dispatch);
          }
        }
      } else {
        setTwoFaLoader(false);
      }
    } else {
      setTwoFaLoader(false);
      message.error("Please enter OTP!");
    }
  };

  const skipForNow = async () => {
    setTwoFaModal(false);
    if (user?.singpassUser === true) {
      setShouldNavigate(true);
    } else {
      if (user?.investorType === "INDIVIDUAL") {
        setCurrentSate(current + 1, dispatch);
      } else {
        setCurrentSate(2, dispatch);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const inputStyle = {
    border: "1px solid #1A202C1A",
    borderRadius: "16px",
    width: windowWidth <= 380 ? "35px" : windowWidth <= 576 ? "45px" : "56px",
    marginLeft: "8px",
    marginRight: "8px",
    height: windowWidth <= 380 ? "35px" : windowWidth <= 576 ? "45px" : "56px",
    fontSize:
      windowWidth <= 380 ? "14px" : windowWidth <= 576 ? "16px" : "18px",
    fontWeight: "400",
  };

  return (
    <div>
      <Modal
        open={twoFaModal}
        footer={null}
        onCancel={() => setTwoFaModal(false)}
        maskClosable={false}
        closable={user?.secondFactorAuth === null ? true : false}
      >
        <div style={{ padding: "20px" }}>
          <div className="sb-flex-column-item-center">
            <img src={DeviceMobile} alt="mobile" className="kl-mobile" />
          </div>
          <div>
            <p className="kl-title m-0">Two-Factor Authentication</p>
            {setUpSMS?.phoneNumber ? (
              <p className="kl-subtitle mt-10">
                Enter the verification code we sent to
              </p>
            ) : (
              <p className="kl-subtitle mt-10">
                ”We highly encourage you to enable Two-Factor Authentication as
                an additional security measure to safeguard your investments.“
              </p>
            )}
          </div>
          <div className="kl-title">
            <p style={{ fontSize: 18 }}>{user?.mobilePhone}</p>
            {setUpSMS?.phoneNumber && (
              <p style={{ fontSize: 14 }}>Type your 6 digit security code</p>
            )}
            {setUpSMS?.phoneNumber && (
              <div className="sb-otp-input-div">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  inputStyle={inputStyle}
                  numInputs={6}
                  renderSeparator={<span></span>}
                  isInputNum={true}
                  inputProps={{ type: "number", inputMode: "numeric" }}
                  renderInput={(props) => (
                    <input {...props} type="number" inputMode="numeric" />
                  )}
                />
              </div>
            )}
          </div>
          <div className="sb-TwoFa-actions">
            {currentEnv === "DEV" && setUpSMS?.code && (
              <div>
                <p style={{ textAlign: "right", color: "#ddd" }}>
                  Authentication Code: {setUpSMS?.code}
                </p>
              </div>
            )}
            <ButtonDefault
              title={setUpSMS?.phoneNumber ? "Submit" : "Set Up"}
              block={true}
              onClick={
                setUpSMS?.phoneNumber ? handleEnableSMS : handleTwoFaAuth
              }
              loading={twoFaLoader}
            />
          </div>

          <div className="sb-flex-column-item-center">
            {isTimerRunning && (
              <div className="kl-countdown resend-otp">
                <p>Resend OTP in</p>
                <span id="timer">{isTimerRunning ? `(${time}s)` : ""}</span>
              </div>
            )}
            {setUpSMS?.phoneNumber && (
              <p className="kl-subtitle mt-5">
                Didn’t get the code ?{" "}
                <Link
                  style={{ fontSize: 14 }}
                  onClick={isTimerRunning ? null : handleTwoFaAuth}
                  id="actionTimer"
                  className={isTimerRunning ? "kl-p-none" : "kl-cursor-pointer"}
                >
                  Resend
                </Link>{" "}
                or{" "}
                <Link style={{ fontSize: 14, color: "var(--kilde-blue)" }}>
                  Call Us
                </Link>
              </p>
            )}
            {user?.secondFactorAuth === null && (
              <p className="kl-subtitle mt-5">
                <Link
                  style={{ fontSize: 14, color: "var(--kilde-blue)" }}
                  onClick={skipForNow}
                >
                  Skip for now
                </Link>
              </p>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TwoFAModal;
