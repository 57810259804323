import React from "react";
import { Col, Row } from "antd";
import CalendarBlank from "../../../Assets/Images/Icons/Dashboard/CalendarBlank.svg";
import Subscription_fees from "../../../Assets/Images/Icons/subscription_fees_tx.svg";
import Deposit from "../../../Assets/Images/Icons/deposit_tx.svg";
import Withdraw_request from "../../../Assets/Images/Icons/withdraw_request_tx.svg";
import Withdraw from "../../../Assets/Images/Icons/withdraw_tx.svg";
import Repaid from "../../../Assets/Images/Icons/repaid_tx.svg";
import Settlement from "../../../Assets/Images/Icons/settlement_tx.svg";
import Internal_ts from "../../../Assets/Images/Icons/internal_ts_tx.svg";
import Referral_payment from "../../../Assets/Images/Icons/referral_payment_tx.svg";
import Repayment from "../../../Assets/Images/Icons/repayment_tx.svg";
import Currency_exchange from "../../../Assets/Images/Icons/currency_exchange.tx.svg";
import Fee from "../../../Assets/Images/Icons/fee_tx.svg";

import {
  britishFormatDateWithTime,
  formatCurrency,
} from "../../../Utils/Reusables";
import "./style.css";

const TransactionCard = ({ item, currencySymbol }) => {
  return (
    <Col xs={24} sm={24} md={12} className="transaction-card mt-8">
      <div className="transaction-card-topDiv">
        <div className="transaction-card-date-div">
          <img src={CalendarBlank} alt="calendar_blank" />
          <p className="m-0 transaction-date-label">
            {britishFormatDateWithTime(item?.postingTs)}
          </p>
        </div>
        <div className="transaction-btn-div">
          {item?.txTitle === "Subscription (Principal)" ? (
            <div className="acc-status-div subscription">
              <img src={Subscription_fees} alt="Subscription_fees" />
              <p>Subscription (Principal)</p>
            </div>
          ) : item?.txTitle === "Subscription (Accrued interest)" ? (
            <div className="acc-status-div subscription">
              <img src={Subscription_fees} alt="Subscription_fees" />
              <p>Subscription (Accrued interest)</p>
            </div>
          ) : item?.txTitle === "Subscription (Fee)" ? (
            <div className="acc-status-div subscription">
              <img src={Subscription_fees} alt="Subscription_fees" />
              <p>Subscription (Fee)</p>
            </div>
          ) : item?.txTitle === "Deposit" ? (
            <div className="acc-status-div deposit">
              <img src={Deposit} alt="deposit" />
              <p>Deposit</p>
            </div>
          ) : item?.txTitle === "Withdrawal request" ? (
            <div className="acc-status-div widthdrawal-request">
              <img src={Withdraw_request} alt="Subscription_fees" />
              <p>Widthdrawal Request</p>
            </div>
          ) : item?.txType === "REPAID" ? (
            <div className="acc-status-div repaid">
              <img src={Repaid} alt="Subscription_fees" />
              <p>Repaid</p>
            </div>
          ) : item?.txType === "WITHDRAW" ? (
            <div className="acc-status-div withdraw">
              <img src={Withdraw} alt="Subscription_fees" />
              <p>Widthdraw</p>
            </div>
          ) : item?.txTitle === "Settlement (Principal)" ? (
            <div className="acc-status-div settlement">
              <img src={Settlement} alt="Subscription_fees" />
              <p>Settlement (Principal)</p>
            </div>
          ) : item?.txTitle === "Settlement (Fee)" ? (
            <div className="acc-status-div settlement">
              <img src={Settlement} alt="Subscription_fees" />
              <p>Settlement (Fee)</p>
            </div>
          ) : item?.txTitle === "Settlement (Accrued interest)" ? (
            <div className="acc-status-div settlement">
              <img src={Settlement} alt="Subscription_fees" />
              <p>Settlement (Accrued interest)</p>
            </div>
          ) : item?.txTitle === "Repayment (Interest)" ? (
            <div className="acc-status-div repayment">
              <img src={Repayment} alt="Subscription_fees" />
              <p>Repayment (Interest)</p>
            </div>
          ) : item?.txTitle === "Repayment (Principal)" ? (
            <div className="acc-status-div repayment">
              <img src={Repayment} alt="Subscription_fees" />
              <p>Repayment (Principal)</p>
            </div>
          ) : item?.txTitle === "REPAYMENT (WHT)" ? (
            <div className="acc-status-div repayment">
              <img src={Repayment} alt="Subscription_fees" />
              <p>REPAYMENT (WHT)</p>
            </div>
          ) : item?.txTitle === "Currency exchange (in)" ? (
            <div className="acc-status-div currency-exchange">
              <img src={Currency_exchange} alt="Subscription_fees" />
              <p>Currency Exchange (incoming)</p>
            </div>
          ) : item?.txTitle === "Currency exchange request" ? (
            <div className="acc-status-div currency-exchange">
              <img src={Currency_exchange} alt="Subscription_fees" />
              <p>Currency Exchange</p>
            </div>
          ) : item?.txTitle === "Currency exchange (out)" ? (
            <div className="acc-status-div currency-exchange">
              <img src={Currency_exchange} alt="Subscription_fees" />
              <p>Currency Exchange (outgoing)</p>
            </div>
          ) : item?.txType === "REFERRAL_PAYMENT" ? (
            <div className="acc-status-div referral-payment">
              <img src={Referral_payment} alt="Subscription_fees" />
              <p>Referral Payment</p>
            </div>
          ) : item?.txType === "INTERNAL_TRANSFER" ? (
            <div className="acc-status-div internal-transfer">
              <img src={Internal_ts} alt="Subscription_fees" />
              <p>Internal transfer</p>
            </div>
          ) : item?.txTitle === "FEE (INVESTOR_MANAGEMENT_FEE)" ? (
            <div className="acc-status-div fee">
              <img src={Fee} alt="fee_tx" />
              <p>FEE (INVESTOR_MANAGEMENT_FEE)</p>
            </div>
          ) : null}
        </div>
      </div>
      <Row className="mt-12">
        <Col xs={12} lg={12}>
          <p className="mb-8 mt-0 card-info-tag">TX ID</p>
        </Col>
        <Col xs={12} lg={12}>
          <p className="mb-8 mt-0 dashboard-card-val-tag">{item.id}</p>
        </Col>
        <Col xs={12} lg={12}>
          <p className="m-0 card-info-tag">Tranche number / </p>
          <p className="mb-8 mt-0 card-info-tag">Borrower </p>
        </Col>
        <Col xs={12} lg={12}>
          <p className="m-0 dashboard-card-val-tag">{item?.trancheNumber}</p>
          <p className="trans-company-label mb-8">{item?.companyName}</p>
        </Col>
        <Col xs={12} lg={12}>
          <p className="mb-8 mt-0 card-info-tag">Amount</p>
        </Col>
        <Col xs={12} lg={12}>
          <p className="mb-8 mt-0 dashboard-card-val-tag">
            {formatCurrency(currencySymbol, item?.amount)}
          </p>
        </Col>
        <Col xs={12} lg={12}>
          <p className="mb-8 mt-0 card-info-tag">Amount reserved</p>
        </Col>
        <Col xs={12} lg={12}>
          <p className="mb-8 mt-0 dashboard-card-val-tag">
            {formatCurrency(currencySymbol, item?.amountReserved)}
          </p>
        </Col>
        <Col xs={12} lg={12}>
          <p className="mb-8 mt-0 card-info-tag">Balance</p>
        </Col>
        <Col xs={12} lg={12}>
          <p className="mb-8 mt-0 dashboard-card-val-tag">
            {formatCurrency(currencySymbol, item?.balance)}
          </p>
        </Col>
      </Row>
    </Col>
  );
};

export default TransactionCard;
