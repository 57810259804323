import axios from "axios";
import { notification } from "antd";
import { ErrorResponse } from "./ErrorResponse.js";
import GlobalVariabels from "../Utils/GlobalVariabels.js";
import { countries } from "countries-list";

export const apiHandler = async (
  method,
  url,
  requestBody = {},
  page = null,
  limit = null
) => {
  try {
    const Headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    let baseURL = GlobalVariabels.NODE_ENV === "DEV" ? `${url}` : `${url}`;

    if (page) {
      baseURL = `${baseURL}?page=${page}&limit=${limit}`;
    }

    const data = {
      method,
      url: baseURL,
      headers: Headers,
      data: JSON.stringify(requestBody),
    };

    return axios(data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let err;
        if (error?.response?.data?.status === 403) {
          window.location.href = "/login";
        } else if (error?.response?.data?.error === "Internal Server Error") {
          notification.error({
            type: "error",
            message: "Please Try after Sometime",
            description: "Internal server error!",
          });
        } else if (
          error?.response?.data?.error ===
          "Account not found for given current account number"
        ) {
          notification.error({
            type: "error",
            message: "Account Not Found",
            description:
              "No account was found for the provided current account number. Please verify the account number and try again.",
          });
        } else if (
          Object.keys(error?.response?.data?.fieldErrors)?.length > 0 ||
          error.response?.data?.fieldErrors?.length > 0
        ) {
          err = error?.response?.data?.fieldErrors;
        } else {
          err = error?.response?.data?.errors;
        }
        ErrorResponse(err);
        return error?.response?.data;
      });
  } catch (error) {
    return error;
  }
};

export const dowloadReports= async (
  method,
  url,
  requestBody = {},
  page = null,
  limit = null
) => {
  try {
    let baseURL = GlobalVariabels.NODE_ENV === "DEV" ? `${url}` : `${url}`;

    if (page) {
      baseURL = `${baseURL}?page=${page}&limit=${limit}`;
    }
    return axios.post(baseURL,requestBody,{ responseType: 'arraybuffer' })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let err;
        if (error?.response?.data?.status === 403) {
          window.location.href = "/login";
        } else if (error?.response?.data?.error === "Internal Server Error") {
          notification.error({
            type: "error",
            message: "Please Try after Sometime",
            description: "Internal server error!",
          });
        }
         else if (
          Object.keys(error?.response?.data?.fieldErrors)?.length > 0 ||
          error.response?.data?.fieldErrors?.length > 0
        ) {
          err = error?.response?.data?.fieldErrors;
        } else {
          err = error?.response?.data?.errors;
        }
        ErrorResponse(err);
        return error?.response?.data;
      });
  } catch (error) {
    return error;
  }
};

export const TrancheAcceptancedowload = async (
  method,
  url,
  requestBody = {},
  page = null,
  limit = null
) => {
  try {
    let baseURL = GlobalVariabels.NODE_ENV === "DEV" ? `${url}` : `${url}`;

    if (page) {
      baseURL = `${baseURL}?page=${page}&limit=${limit}`;
    }
    return axios({
      method: method,
      url: baseURL,
      data: requestBody,
      responseType: 'arraybuffer'
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let err;
        if (error?.response?.data?.status === 403) {
          window.location.href = "/login";
        } else if (error?.response?.data?.error === "Internal Server Error") {
          notification.error({
            type: "error",
            message: "Please Try after Sometime",
            description: "Internal server error!",
          });
        } else if (
          Object.keys(error?.response?.data?.fieldErrors)?.length > 0 ||
          error.response?.data?.fieldErrors?.length > 0
        ) {
          err = error?.response?.data?.fieldErrors;
        } else {
          err = error?.response?.data?.errors;
        }
        ErrorResponse(err);
        return error?.response?.data;
      });
  } catch (error) {
    return error;
  }
};

export const withoutTokenApiHandler = async (
  method,
  url,
  requestBody = {},
  page = null,
  limit = null
) => {
  try {
    const Headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    let baseURL = GlobalVariabels.NODE_ENV === "DEV" ? `${url}` : `${url}`;

    if (page) {
      baseURL = `${baseURL}?page=${page}&limit=${limit}`;
    }

    const data = {
      method,
      url: baseURL,
      headers: Headers,
      data: JSON.stringify(requestBody),
    };
    return axios(data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error?.response?.data?.error === "no investor found") {
          notification.error({
            type: "error",
            message: "Oops! User not found",
            description:
              "We couldn't find an account associated with this email. Please double-check your email address or create a new account.",
          });
        } else if (error?.response?.data?.error === "wrong credentials") {
          notification.error({
            type: "error",
            message: "Invalid credentials",
            description: "Invalid username or password. Please try again",
          });
        } else if (error?.response?.data?.error === "Too many attempts") {
          notification.error({
            type: "error",
            message: "Please try again later.",
            description:
              "You have exceeded the maximum number of attempts. Please wait for some time before trying again",
          });
        } else if (error?.response?.data?.error === "Internal Server Error") {
          notification.error({
            type: "error",
            message: "Please Try after Sometime",
            description: "Internal server error!",
          });
        } else if (error?.response?.data) {
          ErrorResponse(error?.response?.data?.fieldErrors);
          return error?.response?.data;
        } else {
          return error;
        }
      });
  } catch (error) {
    return error;
  }
};

export const getCountries = () => {
  const allCountries = Object.keys(countries).map((k) => ({
    key: k,
    value: countries[k].name,
  }));

  // Sort countries by name
  allCountries.sort((a, b) => a.value.localeCompare(b.value));

  // Find Singapore and move it to the top
  const singaporeIndex = allCountries.findIndex((item) => item.key === "SG");
  if (singaporeIndex !== -1) {
    const singaporeItem = allCountries[singaporeIndex];
    allCountries.splice(singaporeIndex, 1);
    allCountries.unshift(singaporeItem);
  }

  return allCountries;
};

export const getCookie = (cookieName) => {
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    const [name, value] = cookie.split("=");
    if (name === cookieName) {
      return value;
    }
  }
  return "";
};

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader?.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader?.result;
      resolve(baseURL);
    };
  });
};

export const checkStepStatus = (data, step) => {
  if (step === "PERSONAL_DETAILS") {
    window.dataLayer.push({
      event: "app_button_click_tracker", // Consistent event name
      button_text: "Submit", // Capture button text
      button_id: "btn-personal-details-submit", // Assign a unique button ID
    });
  }
  const StepStatus = data?.includes(step);
  return StepStatus;
};

export const deleteAllCookies = () => {
  // Get all cookies
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
};

// getting ga_client_id
export function get_ga_clientid() {
  var cookie = {};
  document.cookie.split(";").forEach(function (el) {
    var splitCookie = el.split("=");
    var key = splitCookie[0].trim();
    var value = splitCookie[1];
    cookie[key] = value;
  });
  return cookie["_ga"].substring(6);
}

export function redirectToVue(appToRedirect) {
  let originalCookies = document.cookie;
  function parseCookies(cookiesString) {
    return cookiesString.split("; ").reduce(function (acc, cookie) {
      var parts = cookie.split("=");
      acc[parts[0]] = parts[1];
      return acc;
    }, {});
  }

  function setCookies(cookiesObject, domain) {
    for (var cookieName in cookiesObject) {
      if (cookiesObject.hasOwnProperty(cookieName)) {
        document.cookie =
          cookieName +
          "=" +
          cookiesObject[cookieName] +
          "; domain=" +
          domain +
          "; path=/;";
      }
    }
  }

  var parsedCookies = parseCookies(originalCookies);
  // Set cookies for the common root domain
  setCookies(parsedCookies, ".kilde.sg");
  // Redirect to the new subdomain
  if (appToRedirect === "vue") {
    window.location.href = `${GlobalVariabels.RIDIRECT_TO_VUE_URL}/redirect`;
  } else if (appToRedirect === "react") {
    window.location.href = `${GlobalVariabels.API_BASE_URL}/dashboard`;
  } else {
    window.location.href = `${GlobalVariabels.RIDIRECT_TO_VUE_URL}/redirect`;
  }
}

export const checkOnboardingStatus = (step, regtankStatus) => {
  const completedStatuses = [
    "LIVENESS_PASSED",
    "APPROVED",
    "WAIT_FOR_APPROVAL",
    "Score Generated",
    "COMPLETED",
    null,
  ];

  if (step?.length === 0 && completedStatuses.includes(regtankStatus)) {
    return "COMPLETED";
  } else {
    return "PENDING";
  }
};

export const getPasswordStrength = (password) => {
  if (password?.length < 6) {
    return 0;
  } else if (password?.length < 8) {
    return 30;
  } else if (
    /[A-Z]/.test(password) &&
    /[a-z]/.test(password) &&
    password?.length >= 10 &&
    /[0-9]/.test(password) &&
    /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(password)
  ) {
    return 100;
  } else {
    return 60;
  }
};

export const formateDate = (inputDate) => {
  const date = new Date(inputDate);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const monthName = months[monthIndex];
  const formattedDate = `${day} ${monthName} ${year}`;

  return formattedDate;
};

export const britishFormatDate = (inputDate) => {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};
