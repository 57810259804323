import React from "react";
import ButtonDefault from "../../../Components/ButtonDefault/ButtonDefault";
import checkCircle from "../../../Assets/Images/CheckCircle.svg";
import "./style.css";
import { redirectToVue } from "../../../Utils/Helpers";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../Config/Routes";

const ThankYou = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const handleExplore = () => {
    if (user?.vwoFeatures.redirectApp?.appToRedirect === "vue") {
      redirectToVue(user?.vwoFeatures.redirectApp?.appToRedirect);
    } else {
      navigate(ROUTES.TRANCH_LISTING);
    }
  };
  return (
    <div className="thankyou-mainDiv mt-40">
      <div className="kd-thankyou">
        <img src={checkCircle} alt="CheckCircle" />
        <div className="sub-thankyou-div">
          <p className="sb-verification-title mt-5 mb-10">
            Congratulations! Your verification is complete.
          </p>
          <p className="sb-pi-subtitle mt-0">
            Our team is reviewing your documents, which may take 2-3 business
            days. Once approved, you can start investing.
          </p>
          <p className="sb-pi-subtitle mt-0">
            Explore our investment opportunities while our team is reviewing
            your documents. For your security, please activate two-factor
            authentication.
          </p>
          <div style={{ marginTop: 40 }}>
            <ButtonDefault
              title="Explore Investment Opportunities"
              onClick={handleExplore}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
