import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "antd";
import VideoRecorder from "react-video-recorder";
import Elipse from "../../../Assets/Images/Ellipse.svg";
import { useDispatch, useSelector } from "react-redux";
import { setLivenessDetails } from "../../../Redux/Action/KycIndividual";
import FaceGIF from "../../../Assets/Images/face-scan.gif";

import "./style.css";

const LivenessPage = () => {
  const [state, setState] = useState(0);
  const livenessVideo = useSelector((state) => state.kycIndividual.liveness);
  const webcamRef = useRef(null);
  const dispatch = useDispatch();

  const onClick = (e) => {
    setState(1);
  };

  useEffect(() => {
    if (livenessVideo === "") {
      setState(0);
    }
  }, [livenessVideo]);

  return (
    <div>
      <p className="sb-verification-title">Verify Your Identity</p>
      <p className="mt-0 head-userId">
        To complete the KYC/AML screening, please upload a copy of your
        identification document and perform a liveness test.
      </p>
      <div className="identify-proof-mainDiv mt-40">
        <p className="kl-pi-subdivtitle" style={{ paddingBottom: "5px" }}>
          Liveness Check
        </p>
        <p className="sb-pi-subtitle mt-0 mb-20">
          Get your camera ready, move left, then right, then up, then down.
        </p>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} className="gutter-row">
            <div className="sb-liveness-actions">
              {state === 0 ? (
                <div className="sb-liveness-subdiv">
                  <img src={FaceGIF} alt="face-gif" style={{ width: 200 }} />

                  <div className="sb-liveness-button">
                    <Button
                      style={{ width: 130, height: 40 }}
                      onClick={onClick}
                      className="btn-ready"
                    >
                      I am ready
                    </Button>
                  </div>
                </div>
              ) : state === 1 ? (
                <div style={{ display: "flex", gap: 30 }}>
                  <div
                    style={{ width: 400, height: 355, borderRadius: "50%" }}
                    className="video-record-div"
                  >
                    <VideoRecorder
                      onRecordingComplete={(videoBlob) => {
                        setLivenessDetails(videoBlob, dispatch);
                      }}
                      ref={webcamRef}
                      muted
                      timeLimit={3000}
                    />
                  </div>
                </div>
              ) : (
                <div className="sb-liveness-sucessdiv">
                  <img
                    src={Elipse}
                    alt="LivenessPhoto"
                    className="sb-liveness-image"
                  />

                  <div className="sb-liveness-button">
                    <p className="sb-liveness-success">Scanner Successful!</p>
                    <p className="sb-liveness-content">
                      Face scanning was correctly done.
                      <br /> Please continue this process.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col
            sm={24}
            md={24}
            lg={24}
            xl={12}
            className="gutter-row liveness-tranch-col"
          >
            <div className="sb-liveness-actions h-100">
              <div className="sub-liveness">
                <ul className="sb-text-align-start liveness-ul">
                  <li>
                    <p className="color-blank">
                      Recommended video duration: 1-3 seconds
                    </p>
                  </li>
                  <li>
                    <p className="color-blank">
                      Increase your screen to its maximum brightness.
                    </p>
                  </li>
                  <li>
                    <p className="color-blank">
                      Make sure that your face is not covered with a mask or
                      sunglasses.
                    </p>
                  </li>
                  <li>
                    <p className="color-blank">
                      Move to a well-lit place that is not in direct sunlight.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LivenessPage;
