import React from "react";
import { PieChart, Pie, Cell, Label } from "recharts";
import "./style.css";
import { formatCurrency } from "../../../Utils/Reusables";

const PieChartComponent = ({
  displayData,
  allValuesZero,
  COLORS,
  GRAY_COLOR,
  dashboardData,
}) => (
  <div className="pie-chart-wrapper">
    <PieChart width={120} height={125} className="pie-chart">
      <Pie
        data={displayData}
        innerRadius={50}
        outerRadius={60}
        fill="#F8F7F2"
        paddingAngle={3}
        dataKey="value"
        cornerRadius={100}
      >
        {displayData.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={allValuesZero ? GRAY_COLOR : COLORS[index % COLORS.length]}
          />
        ))}
      </Pie>
      <Pie
        data={displayData}
        dataKey="value"
        innerRadius={0}
        outerRadius={45}
        isAnimationActive={false}
      >
        {displayData.map((entry, index) => (
          <Cell key={`inner-cell-${index}`} fill="#F8F7F2" stroke="#F8F7F2" />
        ))}
        <Label
          position="center"
          fill="#111"
          style={{
            fontSize: "12px",
            fontWeight: "600",
            fontFamily: "Inter Tight",
            lineHeight: "18px",
            color: "#000",
          }}
        >
          {formatCurrency(
            dashboardData?.investorSummary?.currencySymbol,
            dashboardData?.investorSummary?.totalAssets
          )}
        </Label>
      </Pie>
    </PieChart>
  </div>
);

export default PieChartComponent;
