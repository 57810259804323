import React, { useState } from "react";
import { Button, Col, Input, Row, Select } from "antd";
import { getCountries } from "../../../Utils/Helpers";
import Hyphen from "../../../Assets/Images/Hyphen.svg";
import Close_black_icon from "../../../Assets/Images/close_black_icon.svg";
import ArrowUpAndDownIcon from "../../../Assets/Images/SVGs/ArrowLineUpDown.svg";

import { setTranchFilter } from "../../../Redux/Action/Dashboard";
import { useDispatch } from "react-redux";
import Percent from "../../../Assets/Images/Percent.svg";
import { getCountryNameByCode } from "../../../Utils/Reusables";
import "./style.css";

const CommonFilter = ({ trancheCountry }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(null);
  const countryList = getCountries();
  const matchingObjects = countryList?.filter((obj) =>
    trancheCountry?.includes(obj.key)
  );

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterData, setFilterData] = useState({
    fromInterest: "",
    toInterest: "",
    currency: "",
    industry: "",
    EarlyRedemptionFrequency: "",
    country: "",
  });
  const filteredSelectedFilters = selectedFilters?.filter(
    (filter) =>
      filter?.val !== undefined && filter?.val !== null && filter?.val !== "-"
  );

  const handleChange = async (e) => {
    const { name, value } = e.target;
    if (name === "fromInterest" && value.toString() > 100) {
      return;
    } else if (name === "toInterest" && value.toString() > 100) {
      return;
    } else {
      setFilterData((prevFilterData) => {
        return {
          ...prevFilterData,
          [name]: value,
        };
      });
      setTranchFilter({ data: { ...filterData, [name]: value } }, dispatch);
      // Use directly the value obtained from the event
      if (name === "fromInterest" && filterData.toInterest !== undefined) {
        const val = `${value}-${filterData.toInterest}`;
        setSelectedFilters((prevFilters) => {
          const existingIndex = prevFilters.findIndex(
            (filter) => filter.key === "InterestRate"
          );
          const updatedFilters = [...prevFilters];
          if (existingIndex !== -1) {
            updatedFilters[existingIndex].val = val;
          } else {
            updatedFilters.push({ key: "InterestRate", val });
          }
          return updatedFilters;
        });
      } else if (
        name === "toInterest" &&
        filterData.fromInterest !== undefined
      ) {
        const val = `${filterData.fromInterest}-${value}`;
        setSelectedFilters((prevFilters) => {
          const existingIndex = prevFilters.findIndex(
            (filter) => filter.key === "InterestRate"
          );
          const updatedFilters = [...prevFilters];
          if (existingIndex !== -1) {
            updatedFilters[existingIndex].val = val;
          } else {
            updatedFilters.push({ key: "InterestRate", val });
          }
          return updatedFilters;
        });
      }
    }
  };

  const handleChangeCurrency = async (e) => {
    if (filterData.currency === e) {
      setActiveTab(null);
      setFilterData({ ...filterData, currency: "" });
      setTranchFilter({ data: { ...filterData, currency: "" } }, dispatch);
    } else {
      setActiveTab(e);
      setFilterData({ ...filterData, currency: e });
      setTranchFilter({ data: { ...filterData, currency: e } }, dispatch);
    }
  };

  const handleIndustry = async (e) => {
    setFilterData({ ...filterData, industry: e });
    await setTranchFilter({ data: { ...filterData, industry: e } }, dispatch);
    let val = e;
    setSelectedFilters((prevFilters) => {
      const existingIndex = prevFilters.findIndex(
        (filter) => filter.key === "industry"
      );
      if (existingIndex !== -1) {
        const updatedFilters = [...prevFilters];
        updatedFilters[existingIndex] = { key: "industry", val };
        return updatedFilters;
      } else {
        return [...prevFilters, { key: "industry", val }];
      }
    });
  };

  const handleCountry = async (e) => {
    setFilterData({ ...filterData, country: e?.key });
    setTranchFilter({ data: { ...filterData, country: e?.key } }, dispatch);
    const val = getCountryNameByCode(countryList, e?.key);
    setSelectedFilters((prevFilters) => {
      const existingIndex = prevFilters.findIndex(
        (filter) => filter.key === "country"
      );
      if (existingIndex !== -1) {
        const updatedFilters = [...prevFilters];
        updatedFilters[existingIndex] = { key: "country", val };
        return updatedFilters;
      } else {
        return [...prevFilters, { key: "country", val }];
      }
    });
  };
  const handleFrequency = async (e) => {
    setFilterData({ ...filterData, EarlyRedemptionFrequency: e });
    setTranchFilter(
      { data: { ...filterData, EarlyRedemptionFrequency: e } },
      dispatch
    );
    let val = e;
    setSelectedFilters((prevFilters) => {
      const existingIndex = prevFilters.findIndex(
        (filter) => filter.key === "EarlyRedemptionFrequency"
      );
      if (existingIndex !== -1) {
        const updatedFilters = [...prevFilters];
        updatedFilters[existingIndex] = {
          key: "EarlyRedemptionFrequency",
          val,
        };
        return updatedFilters;
      } else {
        return [...prevFilters, { key: "EarlyRedemptionFrequency", val }];
      }
    });
  };
  const handleFilterClose = (filter, filterIndex) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = prevFilters?.filter(
        (_, index) => index !== filterIndex
      );
      return updatedFilters;
    });
    // Remove fromInterest and toInterest if the filter key is "InterestRate"
    if (filter.key === "InterestRate") {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        fromInterest: "",
        toInterest: "",
      }));
      setTranchFilter(
        {
          data: {
            ...filterData,
            fromInterest: "",
            toInterest: "",
          },
        },
        dispatch
      );
    } else {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        [filter.key]: "",
      }));
      setTranchFilter({ data: { ...filterData, [filter.key]: "" } }, dispatch);
    }
  };
  const handleClearAll = () => {
    setActiveTab(null);
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      fromInterest: "",
      toInterest: "",
      currency: "",
      industry: "",
      EarlyRedemptionFrequency: "",
      country: "",
    }));
    setSelectedFilters([]);
    setTranchFilter(
      {
        data: {
          fromInterest: "",
          toInterest: "",
          currency: "",
          industry: "",
          EarlyRedemptionFrequency: "",
          country: "",
        },
      },
      dispatch
    );
  };

  const handleKeyDown = (event) => {
    const charCode = event.keyCode;
    // Allow only numbers, backspace, delete, tab, escape, enter, and period
    if (
      (charCode < 48 || charCode > 57) && // not a number
      charCode !== 8 && // backspace
      charCode !== 46 && // delete
      charCode !== 9 && // tab
      charCode !== 27 && // escape
      charCode !== 13 && // enter
      charCode !== 110 && // period
      charCode !== 190 // period
    ) {
      event.preventDefault();
    }
  };

  const handleInput = (event) => {
    const value = event.target.value;
    // Ensure the value is a number
    if (!/^\d*\.?\d*$/.test(value)) {
      handleChange({
        target: { name: "toInterest", value: value.replace(/[^\d.]/g, "") },
      });
      handleChange({
        target: { name: "fromInterest", value: value.replace(/[^\d.]/g, "") },
      });
    }
  };

  return (
    <div>
      <Row className="filter-modal">
        {/* <Col sx={24} className="filter-modal-head">
          Show Filtres
        </Col>
        <Divider className="filter-modal-divider" /> */}

        <Col sx={24} className="modal-filtered-data">
          {filteredSelectedFilters?.length > 0 && (
            <Col sx={24} className="w-100">
              <div className="sb-flex-justify-between align-center mb-15">
                <p className="modal-title m-0">Filters</p>
                <p
                  className="modal-subtitle m-0 cursor-pointer"
                  onClick={handleClearAll}
                >
                  Clear All
                </p>
              </div>
            </Col>
          )}

          {filteredSelectedFilters?.length > 0 &&
            filteredSelectedFilters?.map((filter, index) => (
              <Row key={index} className="gutter-row">
                <Col sx={24}>
                  {filter?.val && (
                    <div className="filterlist">
                      <span className="filterData">
                        {filter.key}: {filter.val}
                      </span>
                      <img
                        src={Close_black_icon}
                        alt="close_icon"
                        onClick={() => handleFilterClose(filter, index)}
                        style={{ marginLeft: 5, cursor: "pointer" }}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            ))}
        </Col>
      </Row>
      <div className="filter-media-input-div">
        <p className="filter-subtitle mt-0">Interest Rate</p>
        <Row gutter={10}>
          <Col xs={11} className="gutter-row">
            <div>
              <Input
                value={filterData?.fromInterest}
                placeholder="from"
                className="kl-filter-input"
                name="fromInterest"
                onChange={handleChange}
                style={{ position: "relative" }}
                type="number"
                onKeyDown={handleKeyDown}
                onInput={handleInput}
              />
              <img
                src={Percent}
                alt="percentage"
                style={{ position: "absolute", top: 10, right: 16 }}
              />
            </div>
          </Col>
          <Col xs={2} className="gutter-row" style={{ marginTop: 5 }}>
            <img src={Hyphen} alt="Hyphen" />
          </Col>
          <Col xs={11} className="gutter-row">
            <div>
              <Input
                value={filterData?.toInterest}
                placeholder="to"
                className="kl-filter-input"
                name="toInterest"
                onChange={handleChange}
                style={{ position: "relative" }}
                type="number"
                onKeyDown={handleKeyDown}
                onInput={handleInput}
              />
              <img
                src={Percent}
                alt="percentage"
                style={{ position: "absolute", top: 10, right: 16 }}
              />
            </div>
          </Col>
        </Row>
        <p className="filter-subtitle mt-24 ">Early Redemption Frequency</p>
        <Row>
          <Col xs={24} className="gutter-row">
            <Select
              value={
                filterData?.EarlyRedemptionFrequency !== undefined &&
                filterData?.EarlyRedemptionFrequency !== null &&
                filterData?.EarlyRedemptionFrequency !== ""
                  ? filterData?.EarlyRedemptionFrequency
                  : undefined
              }
              suffixIcon={<img src={ArrowUpAndDownIcon} alt="arrow-icon" />}
              allowClear
              placeholder="Select Frequency (m)"
              onChange={handleFrequency}
              className="kd-filter-select"
              options={[
                { value: "YES", label: "Yes" },
                { value: "NO", label: "No" },
              ]}
            />
          </Col>
        </Row>
        <p className="filter-subtitle mt-24 ">Currency</p>
        <Row className="currency-filter-div">
          <Button
            onClick={() => handleChangeCurrency("USD")}
            className={activeTab === "USD" ? "active-tab" : null}
          >
            USD
          </Button>
          <Button
            onClick={() => handleChangeCurrency("SGD")}
            className={activeTab === "SGD" ? "active-tab" : null}
          >
            SGD
          </Button>
          <Button
            onClick={() => handleChangeCurrency("EUR")}
            className={activeTab === "EUR" ? "active-tab" : null}
          >
            EUR
          </Button>
        </Row>
        <p className="filter-subtitle mt-24">Country</p>
        <Row>
          <Col className="gutter-row" xs={24}>
            <Select
              value={
                filterData?.country !== undefined &&
                filterData?.country !== null
                  ? matchingObjects.find(
                      (country) => country.key === filterData?.country
                    )?.value
                  : undefined
              }
              showSearch
              suffixIcon={<img src={ArrowUpAndDownIcon} alt="arrow-icon" />}
              allowClear
              placeholder="Select a Сountry"
              onChange={(value, key) => handleCountry(key)}
              className="kd-filter-select"
              options={matchingObjects}
            />
          </Col>
        </Row>
        <p className="filter-subtitle mt-24 ">Industry</p>
        <Row>
          <Col className="gutter-row" xs={24}>
            <Select
              value={
                filterData?.industry !== undefined &&
                filterData?.industry !== null &&
                filterData?.industry !== ""
                  ? filterData?.industry
                  : undefined
              }
              showSearch
              suffixIcon={<img src={ArrowUpAndDownIcon} alt="arrow-icon" />}
              allowClear
              placeholder="Select Industry"
              onChange={handleIndustry}
              className="kd-filter-select"
              options={[
                { value: "REAL_ESTATE", label: "REAL_ESTATE" },
                { value: "LENDING", label: "LENDING" },
              ]}
            />
          </Col>
        </Row>
      </div>
      {filteredSelectedFilters?.length > 0 &&
        filteredSelectedFilters !== undefined && (
          <div className="custom-modal-overlay">
            <div className="sb-flex-justify-between align-center mb-15">
              <p className="modal-title m-0">Filters</p>
              <p
                className="modal-subtitle m-0 cursor-pointer"
                onClick={handleClearAll}
              >
                Clear All
              </p>
            </div>
            <Row className="show-filter-list">
              {filteredSelectedFilters?.length > 0 &&
                filteredSelectedFilters?.map((filter, index) => (
                  <div key={index} className="filter-item">
                    {filter?.val && (
                      <Row key={index} className="gutter-row">
                        <div className="filterlist">
                          <span className="filterData">
                            {filter.key}: {filter.val}
                          </span>
                          <img
                            src={Close_black_icon}
                            alt="close_icon"
                            onClick={() => handleFilterClose(filter, index)}
                            style={{ marginLeft: 5, cursor: "pointer" }}
                          />
                        </div>
                      </Row>
                    )}
                  </div>
                ))}
            </Row>
          </div>
        )}
    </div>
  );
};
export default CommonFilter;
