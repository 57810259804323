import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import ButtonDefault from "../../../Components/ButtonDefault/ButtonDefault";
import { useNavigate } from "react-router-dom";
import { handleFinish } from "../../../Utils/Reusables";
import ROUTES from "../../../Config/Routes";

const Deposit = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user);

  return (
    <Col className="gutter-row" xl={11} lg={16} md={24} sm={24} xs={24}>
      <Row>
        <Col
          sm={24}
          md={24}
          lg={24}
          className="gutter-row wallet-info-div"
          style={{ flex: 1 }}
        >
          {(user?.verificationState === "" ||
            user?.verificationState === null ||
            user?.verificationState === "WAITING_INVESTOR_DATA") &&
          user?.investorStatus !== "ACTIVE" ? (
            <>
              <p className="wallet-sub-head mt-0 mb-16">
                Your account is not active yet!
              </p>

              <p className="wallet-unactive-ins mt-0 mb-16">
                {user?.investorType === "INDIVIDUAL" ? (
                  "You will be able to deposit and invest funds once your account is activated."
                ) : (
                  <>
                    Complete onboarding and KYB to unlock full platform
                    features: investing, deposits, withdrawals, and currency
                    exchange. Contact{" "}
                    <a
                      href="mailto:sales@kilde.sg"
                      style={{ color: "var(--kilde-blue)" }}
                    >
                      sales@kilde.sg
                    </a>{" "}
                    for assistance.
                  </>
                )}
              </p>
              <ButtonDefault
                title="Finish Onboarding"
                onClick={() => handleFinish(user, navigate)}
              />
            </>
          ) : user?.investorStatus !== "ACTIVE" &&
            (user?.verificationState === "MANUAL_REVIEW" ||
              user?.verificationState === "COMPLETED") &&
            user?.secondFactorAuth !== null ? (
            <>
              <p className="wallet-sub-head mt-0 mb-16">
                Your account is not active yet!
              </p>
              <p className="wallet-unactive-ins mt-0 mb-16">
                {user?.investorType === "INDIVIDUAL" ? (
                  "We are currently reviewing the documents you have submitted. Once the review process is complete and your KYC requirements are met, your account will be activated. After activation, you will be able to deposit funds and start investing."
                ) : (
                  <>
                    Complete onboarding and KYB to unlock full platform
                    features: investing, deposits, withdrawals, and currency
                    exchange. Contact{" "}
                    <a
                      href="mailto:sales@kilde.sg"
                      style={{ color: "var(--kilde-blue)" }}
                    >
                      sales@kilde.sg
                    </a>{" "}
                    for assistance.
                  </>
                )}
              </p>
            </>
          ) : (user?.investorStatus !== "ACTIVE" ||
              user?.verificationState === "MANUAL_REVIEW" ||
              user?.verificationState === "COMPLETED") &&
            user?.secondFactorAuth === null &&
            user?.twoFaCheckEnabled === true ? (
            <>
              <p className="wallet-sub-head mt-0 mb-16">
                Secure Your Investments: 2FA Required
              </p>
              <p className="wallet-unactive-ins mt-0 mb-16">
                Enable two-factor authentication for secure investing.It's
                mandatory to start investing and managing your portfolio.Your
                security is our top priority.
              </p>
              <ButtonDefault
                title="Set Up 2FA Now"
                onClick={() => navigate(ROUTES.TWO_FACTOR_AUTH)}
              />
            </>
          ) : (
            <>
              <Row>
                <p className="mt-0 wallet-sub-head mb-20">
                  Bank transfer details
                </p>
              </Row>
              <Row className="mb-20">
                <p className="deposit-list-tag">
                  1. Make a deposit by bank transaction, using the credentials
                  on the right.
                </p>
                <p className="deposit-list-tag">
                  2. Please remember to add your Investor's Account Number in
                  Payment Details!
                </p>
                <p className="deposit-list-tag">
                  3. Wait for the deposit to be processed (can take up to two
                  business days).
                </p>
                <p className="deposit-list-tag">4. Put your money to work!</p>
              </Row>
              <Col className="deposit-bank-transffer-detail-div">
                <Row>
                  <Col xs={12} lg={12}>
                    <p className="mb-5 mt-0 deposit-list-title">Bank</p>
                  </Col>
                  <Col xs={12} lg={12} className="sb-text-align-end">
                    <p className="mb-5 mt-0 card-val-tag">DBS Bank Singapore</p>
                  </Col>
                  <Col xs={12} lg={12}>
                    <p className="mb-5 mt-0 deposit-list-title">
                      Beneficiary name
                    </p>
                  </Col>
                  <Col xs={12} lg={12}>
                    <p className="mb-5 mt-0 card-val-tag">
                      RH TRUST CO. (S) PTE LTD – KILDE MC AC
                    </p>
                  </Col>
                  <Col xs={12} lg={12}>
                    <p className="m-0 mb-5 deposit-list-title">
                      Beneficiary Bank Account Number
                    </p>
                  </Col>
                  <Col xs={12} lg={12}>
                    <p className="m-0 mb-5 card-val-tag">072-034833-8</p>
                  </Col>
                  <Col xs={12} lg={12}>
                    <p className="m-0 mb-5 deposit-list-title">
                      Beneficiary Registration Number
                    </p>
                  </Col>
                  <Col xs={12} lg={12}>
                    <p className="m-0 mb-5 card-val-tag">201533125Z</p>
                  </Col>
                  <Col xs={12} lg={12}>
                    <p className="m-0 mb-5 deposit-list-title">
                      Beneficiary Bank SWIFT/BIC Code
                    </p>
                  </Col>
                  <Col xs={12} lg={12}>
                    <p className="m-0 mb-5 card-val-tag">DBSSSGSG</p>
                  </Col>
                  <Col xs={12} lg={12}>
                    <p className="m-0 mb-5 deposit-list-title">
                      Beneficiary Bank Address
                    </p>
                  </Col>
                  <Col xs={12} lg={12}>
                    <p className="m-0 mb-5 card-val-tag">
                      12 Marina Boulevard, DBS Asia Central, Marina Bay
                      Financial Centre Tower 3, Singapore 018982
                    </p>
                  </Col>
                  <Col xs={12} lg={12}>
                    <p className="m-0 mb-0 deposit-list-title">
                      Beneficiary Address
                    </p>
                  </Col>
                  <Col xs={12} lg={12}>
                    <p className="m-0 mb-0 card-val-tag">
                      63 Market Street #09-01 Bank of Singapore Centre,
                      Singapore 048942
                    </p>
                  </Col>
                  <Col xs={24} lg={24} className="mt-20">
                    <p className="filter-subtitle m-0">Payment Details</p>
                  </Col>
                  <Col xs={12} lg={12}>
                    <p className="m-0 mb-5 deposit-list-title">
                      Investor Account Number
                    </p>
                  </Col>
                  <Col xs={12} lg={12}>
                    <p className="m-0 mb-5 card-val-tag">{user?.number}</p>
                  </Col>
                  <Col xs={12} lg={12}>
                    <p className="m-0 mb-5 deposit-list-title">
                      Payment instruction
                    </p>
                  </Col>
                  <Col xs={12} lg={12}>
                    <p className="m-0 mb-5 card-val-tag">
                      OUR: Charges are borne by the Remitter
                    </p>
                  </Col>
                </Row>
              </Col>
            </>
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default Deposit;
