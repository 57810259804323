import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Checkbox,
  Input,
  message,
  Modal,
  Progress,
  Form,
  Row,
  Col,
  Divider,
  Button,
  notification,
} from "antd";

import InputDefault from "../../Components/InputDefault/InputDefault.jsx";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault.jsx";

import GlobalConfig from "../../Utils/GlobalVariabels.js";
import {
  GoogleRegisterApi,
  RegisterApi,
  generateSignpassCode,
  getSignPassConfig,
  getUser,
} from "../../Apis/UserApi.js";
import ROUTES from "../../Config/Routes.js";
import { validatePassword } from "../../Utils/Reusables.js";

import PrivacyPdf from "../../Assets/Pdf/Privacy-policy.pdf";
import SingpassLogo from "../../Assets/Images/singpass.svg";
import GoogleIcon from "../../Assets/Images/Icons/google_icon.svg";
// import AppleIcon from "../../Assets/Images/Icons/apple_icon.svg";
// import Expert_Professional_Investor from "../../Assets/Pdf/Expert_Professional_Investor.pdf";
// import Accredited_Investor from "../../Assets/Pdf/Accredited_Investor.pdf";
// import Institutional_Investor from "../../Assets/Pdf/Institutional_Investor.pdf";

import "./style.css";
import Cookies from "js-cookie";
import ReactLoading from "react-loading";

import AuthLayout from "../../Layouts/BlankHeaderLayout/AuthLayout.jsx";
import { getPasswordStrength, get_ga_clientid } from "../../Utils/Helpers.js";
import { PublicEventApi } from "../../Apis/PublicApi.js";
import PhoneInput from "react-phone-input-2";
import ButtonIcon from "../../Components/ButtonIcon/ButtonIcon.jsx";
import { useGoogleLogin } from "@react-oauth/google";
import { PDF } from "../../Utils/Constant.js";
import GlobalVariabels from "../../Utils/GlobalVariabels.js";
import { identify, trackEvent } from "../../Utils/Analytics.js";

const SignUp = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    password: "",
    cPassword: "",
    referralCode: "",
  });
  const [mobileNumberClass, setMobileNumberClass] = useState("");
  const [countryCode, setCountryCode] = useState("sg");
  const strength = getPasswordStrength(formData?.password);
  const [validationErrors, setValidationErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    cPassword: false,
    referralCode: false,
  });
  const [loader, setLoader] = useState(false);
  const [noPass, setNoPass] = useState(false);
  const [cPassErr, setCPassErr] = useState(false);
  const [checked, setChecked] = useState(false);
  const [noCheckErr, setCheckErr] = useState(false);

  const [googleLoader, setGoogleLoader] = useState(false);
  // const [appleLoader, setAppleLoader] = useState(false);

  const [termsPdf, setTermsPdf] = useState(false);
  const [privacyPdf, setPrivacyPdf] = useState(false);
  const [singPassLoader, setSingPassLoader] = useState(false);
  const [referralCodeValid, setReferralCodeValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [referralCodeValidModal, setReferralCodeValidModal] = useState(false);
  const [referralModal, setReferralModal] = useState(false);
  const [googleRequestBody, setGoogleRequestBody] = useState();
  const [finalGoogleLoader, setFinalGoogleLoader] = useState(false);

  useEffect(() => {
    const getSingPass = async () => {
      await getSignPassConfig()
        .then((respose) => {
          const jsonString = JSON.stringify(respose);
          Cookies.set("singpass_config", jsonString);
        })
        .catch((err) => {
          console.log("singpass_config", err);
        });
    };
    getSingPass();
  }, []);

  const handleSingPass = async () => {
    setSingPassLoader(true);
    let singpassGetConfig = Cookies.get("singpass_config");
    if (singpassGetConfig) {
      const singpassConfigration = decodeURIComponent(singpassGetConfig);
      const singpassConfig = JSON.parse(singpassConfigration);
      if (
        singpassConfig?.clientId &&
        singpassConfig?.purposeId &&
        singpassConfig?.redirectUrl &&
        singpassConfig?.scope
      ) {
        setSingPassLoader(true);
        await generateSignpassCode()
          .then((response) => {
            Cookies.set("singpass_code", response?.codeChallenge);
            Cookies.set("sid", response?.sid);
            setSingPassLoader(false);
            window.location.href = `${GlobalConfig?.SINGPASS_REDIRECT_URL}?client_id=${singpassConfig?.clientId}&code_challenge=${response?.codeChallenge}&code_challenge_method=${GlobalConfig?.SINGPASS_CODE_CC_METHOD}&purpose_id=${singpassConfig?.purposeId}&redirect_uri=${singpassConfig?.redirectUrl}&response_type=${GlobalConfig?.SINGPASS_RESPONSE_TYPE}&scope=${singpassConfig?.scope}`;
            PublicEventApi("regSingPass");
            // window.location.reload();
          })
          .catch((error) => {
            console.log(error);
            notification.error({
              type: "error",
              message: "Oops! Something happened.",
              description:
                "We're on it! If this continues, please contact support at sales@kilde.sg.",
            });
            setSingPassLoader(false);
          });
      }
    }
  };
  const HandleCreateUser = async () => {
    setLoader(true);
    setValidationErrors({
      email: true,
      firstName: true,
      lastName: true,
    });

    if (!formData?.password || !validatePassword(formData?.password)) {
      if (checked === false) {
        setCheckErr(true);
        setLoader(false);
      }
      setLoader(false);
      return setNoPass(true);
    } else if (formData?.cPassword !== formData?.password) {
      setLoader(false);
      return setCPassErr(true);
    }

    if (checked === false) {
      setLoader(false);
      return setCheckErr(true);
    }

    if (formData?.email) {
      let data;
      if (
        formData?.referralCode === null ||
        formData?.referralCode === undefined ||
        formData?.referralCode === ""
      ) {
        data = {
          firstName: formData?.firstName,
          lastName: formData?.lastName,
          email: formData?.email,
          mobileNumber: formData?.mobileNumber,
          password: formData?.password,
          passwordRepeat: formData?.cPassword,
          acceptTermsOfUse: true,
          acceptPrivacyPolicy: true,
          gaClientId: get_ga_clientid(),
          url: `${window.location.origin}`,
        };
      } else {
        data = {
          firstName: formData?.firstName,
          lastName: formData?.lastName,
          email: formData?.email,
          mobileNumber: formData?.mobileNumber,
          password: formData?.password,
          passwordRepeat: formData?.cPassword,
          acceptTermsOfUse: true,
          acceptPrivacyPolicy: true,
          gaClientId: get_ga_clientid(),
          url: `${window.location.origin}`,
          referralCode: formData?.referralCode,
        };
      }

      if (data && formData?.mobileNumber) {
        RegisterApi(data)
          .then(async (response) => {
            if (response?.token) {
              trackEvent("Investor: registered");
              Cookies.set("auth_inv_token", response?.token);
              setLoader(false);
              navigate(ROUTES.EMAIL_VERIFICATION);
              message.success("We have sent verification email please check!");
              PublicEventApi("personalData");
            } else {
              if (Object.values(response?.fieldErrors).length > 0) {
                setLoader(false);
                if (
                  response?.fieldErrors?.referralCode !== undefined &&
                  response?.fieldErrors?.email !== undefined
                ) {
                  setReferralCodeValid(true);
                  setEmailValid(true);
                }
                if (response?.fieldErrors?.referralCode) {
                  setReferralCodeValid(true);
                } else if (response?.fieldErrors?.email) {
                  setEmailValid(true);
                }
              }
            }
          })
          .catch((error) => {
            console.error("Error during login:", error);
            setLoader(false);
          });
      } else {
        setLoader(false);
        setMobileNumberClass("sb-phone-empty");
      }
    }
  };

  const handleOpenReferral = () => {
    if (!checked) {
      message.error("Please accept the Terms of Use & Privacy Policy");
      return setCheckErr(true);
    } else {
      setCheckErr(false);
      setReferralModal(true);
    }
  };

  const handleBlur = () => {
    const number = formData?.mobileNumber.substring(countryCode?.length).trim();
    if (number !== "") {
      setMobileNumberClass("");
    } else {
      setMobileNumberClass("sb-phone-empty");
    }
  };

  const handlePhoneChange = async (value, country) => {
    setCountryCode(country.dialCode);
    const number = value.substring(country.dialCode?.length).trim();
    const isValueValid = value.trim() === "";
    setFormData((prevAddress) => ({
      ...prevAddress,
      mobileNumber: "+" + value,
    }));

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      mobileNumber: isValueValid,
    }));

    setFormData({
      ...formData,
      mobileNumber: "+" + value,
    });
    if (number !== "") {
      setMobileNumberClass("");
    } else {
      setMobileNumberClass("sb-phone-empty");
    }
  };

  // const handleAppleSignin = async (provider, data) => {
  //   if (!checked) {
  //     return setCheckErr(true);
  //   } else {
  //     setCheckErr(false);
  //   }
  //   setAppleLoader(true);
  // };

  const handleOpenTermsPdf = () => {
    setTermsPdf(true);
  };

  const handleCloseTermsPdf = () => {
    setTermsPdf(false);
  };

  const handlePaste = (e) => {
    e.preventDefault();
  };

  const handleGoogle = async (data) => {
    if (data) {
      setReferralModal(true);
    }
    setGoogleLoader(false);
    let requestBody = {
      gaClientId: get_ga_clientid(),
      url: `${window.location.origin}`,
      acceptPrivacyPolicy: true,
      acceptTermsOfUse: true,
      email: data?.email,
      firstName: data?.given_name,
      lastName: data?.family_name,
    };
    setGoogleRequestBody(requestBody);
  };

  const finalGoogleApi = () => {
    setFinalGoogleLoader(true);
    let requestBody;
    if (
      formData?.referralCode === null ||
      formData?.referralCode === undefined ||
      formData?.referralCode === ""
    ) {
      requestBody = googleRequestBody;
    } else {
      requestBody = {
        ...googleRequestBody,
        referralCode: formData?.referralCode,
      };
    }
    GoogleRegisterApi(requestBody)
      .then(async (response) => {
        setFinalGoogleLoader(false);
        setFormData({
          ...formData,
          referralCode: "",
        });
        if (response?.token) {
          trackEvent("Investor: registered");
          identify({
            email: googleRequestBody?.email,
          });
          Cookies.set("auth_inv_token", response?.token);
          window?.dataLayer?.push({
            event: "google-success",
            userid: googleRequestBody?.email,
          });
          window?.dataLayer?.push({
            event: "registration_verification_google",
          });
          getUser().then(async (profileResponse) => {
            Cookies.set("user", JSON.stringify(profileResponse));
          });
          setGoogleRequestBody("");
          setGoogleLoader(false);
          setLoader(false);
          navigate(ROUTES.VERIFICATION);
        } else {
          if (Object.values(response?.fieldErrors).length > 0) {
            setFormData({
              ...formData,
              referralCode: "",
            });
            setGoogleLoader(false);
            setLoader(false);
            setReferralModal(false);
          }
        }
      })
      .catch((error) => {
        console.error("Error during login:", error);
        setGoogleLoader(false);
        setLoader(false);
      });
  };

  const login = useGoogleLogin({
    onSuccess: async (credentialResponse) => {
      const { access_token } = credentialResponse;

      try {
        const userInfoResponse = await fetch(
          GlobalVariabels.GOOGLE_USERINFO_URI,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (userInfoResponse.ok) {
          const userInfo = await userInfoResponse.json();
          handleGoogle(userInfo);
        } else {
          console.error(
            "Failed to fetch user information:",
            userInfoResponse.statusText
          );
        }
      } catch (error) {
        console.error("Error fetching user information:", error);
      }
    },
  });

  return (
    <>
      <AuthLayout>
        {singPassLoader && (
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              zIndex: 99,
            }}
          >
            <ReactLoading
              type="spin"
              color="var(--kilde-blue)"
              height={60}
              width={60}
            />
          </div>
        )}
        <div className="sb-onboarding-register-form-container">
          <div className="register-padd-container mt-50">
            <p className="kl-pi-title mb-10 mt-0">Welcome!</p>
            <p className="sb-pi-subtitle m-0">
              Sign up and create your account in just 3 minutes.
            </p>
          </div>

          <div className="register-btn-div">
            <p className="mt-5 mb-16 sb-pi-singpass-subtitle">
              Kilde products are intended for accredited, professional and
              institutional investors.{" "}
              <span
                className="cursor-pointer"
                onMouseEnter={(e) => {
                  e.target.style.color = "var(--kilde-blue)";
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = "rgba(26, 32, 44, 0.4)";
                }}
                onClick={() =>
                  window.open(
                    "https://www.kilde.sg/investing/what-does-it-mean-to-be-an-accredited-investor-in-singapore",
                    "_blank"
                  )
                }
              >
                Learn more.
              </span>
            </p>
            {/* <ButtonIcon
      icon={<img src={SingpassLogo} alt="dsfsdf" />}
      loading={singPassLoader}
      onClick={handleSingPass}
      style={{ backgroundColor: "#dd4b39" }}
      block={true}
    /> */}
            <div className="register-padd-container">
              <Button
                className="btn-singpass"
                loading={singPassLoader}
                onClick={handleSingPass}
                id="btn-singpass-signup"
              >
                <p className="mb-5 singpass-text">Sign Up with </p>
                <img src={SingpassLogo} alt="singpass_logo" />
              </Button>
            </div>

            <p className="mt-5 mb-16 singpass-sub-text">
              All Singpass users can apply with Myinfo - a data platform that
              pulls your personal data across participating government agencies
              to fill out e-forms.{" "}
              <span
                className="cursor-pointer"
                style={{ color: "var(--kilde-blue)" }}
                onClick={() =>
                  window.open(
                    "https://www.singpass.gov.sg/main/individuals/",
                    "_blank"
                  )
                }
              >
                Read more
              </span>
            </p>

            {/* <div className="sb-flex-column-item-center mt-20 w-100">
      <LoginSocialApple
        client_id={CLIENT_ID.APPLE_CLIENTID}
        scope={"name email"}
        redirect_uri={
          "https://plenty-planets-beam-42-118-51-2.loca.lt/account/login"
        }
        onResolve={({ provider, data }) => {
          handleAppleSignin(provider, data);
        }}
        onReject={(err) => {
          console.log(err);
        }}
        className="w-100"
      >
        <ButtonIcon
          title="Sign up with Apple"
          icon={<img src={AppleIcon} alt="AppleIcon" />}
          loading={appleLoader}
          block={true}
        />
      </LoginSocialApple>
    </div> */}
            <div className="register-padd-container">
              <div className="sb-flex-column-item-center mt-20 w-100 login-div">
                {checked === false ? (
                  <ButtonIcon
                    title="Sign up with Google"
                    icon={<img src={GoogleIcon} alt="google_icon" />}
                    loading={googleLoader}
                    className="google-btn"
                    onClick={handleOpenReferral}
                    id="btn-google-signup"
                  />
                ) : (
                  <ButtonIcon
                    title="Sign up with Google"
                    icon={<img src={GoogleIcon} alt="google_icon" />}
                    loading={googleLoader}
                    className="google-btn"
                    onClick={() => login()}
                    id="btn-google-signup"
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className="sb-login-actions register-padd-container"
            style={{ gap: 4 }}
          >
            <Divider plain>
              <p style={{ color: "#ababab" }}> Or with Email </p>
            </Divider>
          </div>
          <div className="register-padd-container mb-50">
            <Form
              onFinish={HandleCreateUser}
              name="wrap"
              labelCol={{ flex: "110px" }}
              labelAlign="left"
              labelWrap
              wrapperCol={{ flex: 1 }}
              colon={false}
            >
              <div className="sb-login-form">
                <Row gutter={16}>
                  <Col className="gutter-row mb-15" md={12} sm={12} xs={24}>
                    <InputDefault
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      validationState={setValidationErrors}
                      focusing={validationErrors?.firstName}
                      onChange={({ target }) =>
                        setFormData({ ...formData, firstName: target.value })
                      }
                      required={true}
                      errorMsg={"First name is required"}
                    />
                  </Col>
                  <Col className="gutter-row mb-15" md={12} sm={12} xs={24}>
                    <InputDefault
                      type="text"
                      name="lastName"
                      placeholder="last Name"
                      validationState={setValidationErrors}
                      focusing={validationErrors?.lastName}
                      onChange={({ target }) =>
                        setFormData({ ...formData, lastName: target.value })
                      }
                      required={true}
                      errorMsg={"Last name is required"}
                    />
                  </Col>
                  <Col className="gutter-row mb-15" md={24} sm={24} xs={24}>
                    <div
                      className={emailValid === true ? "referral-error" : ""}
                    >
                      <InputDefault
                        validationState={setValidationErrors}
                        focusing={validationErrors?.email || emailValid}
                        placeholder="Email"
                        type="email"
                        name="email"
                        required={true}
                        onChange={({ target }) => {
                          setFormData({ ...formData, email: target.value });
                          setEmailValid(false);
                        }}
                        errorMsg={"Email is Required"}
                      />
                    </div>
                  </Col>
                  <Col
                    className="gutter-row mb-15 sb-text-align-start"
                    md={24}
                    sm={24}
                    xs={24}
                  >
                    <PhoneInput
                      className={`sb-phone-field ${mobileNumberClass}`}
                      country={countryCode}
                      value={formData.mobileNumber}
                      onChange={handlePhoneChange}
                      enableSearch
                      onBlur={handleBlur}
                    />
                    <span className="phone-error-msg">
                      {mobileNumberClass === "sb-phone-empty"
                        ? "Please enter mobile number"
                        : ""}
                    </span>
                  </Col>
                  <Col className="gutter-row mb-15" md={24} sm={24} xs={24}>
                    <div style={{ marginBottom: 12 }}>
                      <Input.Password
                        name="password"
                        placeholder="Password"
                        style={{ height: 40, borderRadius: "12px" }}
                        type="password"
                        onChange={({ target }) => {
                          setFormData({ ...formData, password: target.value });
                          if (noPass) {
                            if (validatePassword(formData?.password) === true) {
                              setNoPass(false);
                            } else {
                              setNoPass(true);
                            }
                          }
                        }}
                        status={noPass ? "error" : ""}
                        required={true}
                      />

                      <div style={{ marginBottom: "-15px" }}>
                        <Progress
                          percent={strength}
                          size="small"
                          showInfo={false}
                        />
                      </div>
                      <div className="sb-text-align-start">
                        <small style={{ fontSize: 10, color: "#999" }}>
                          Use at least 10 characters, 1 uppercase, lowercase,
                          special character and number in your password
                        </small>
                      </div>
                      <div>
                        {noPass && (
                          <label
                            className="error-msg"
                            style={{
                              display: "block",
                              marginTop: "-8px",
                              marginBottom: 12,
                              fontSize: "12px",
                            }}
                          >
                            Use atleast 10 characters, 1 uppercase, lowecase,
                            special character and number in your password
                          </label>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col className="gutter-row mb-15" md={24} sm={24} xs={24}>
                    <div style={{ marginTop: "-15px" }}>
                      <Input.Password
                        name="password"
                        placeholder="Confirm Password"
                        type="password"
                        style={{ height: 40, borderRadius: "12px" }}
                        onChange={({ target }) => {
                          setCPassErr(false);
                          setFormData({ ...formData, cPassword: target.value });
                          if (target.value !== formData?.password) {
                            setCPassErr(true);
                            setValidationErrors({
                              ...validationErrors,
                              cPassword: false,
                            });
                          }
                        }}
                        status={cPassErr ? "error" : ""}
                        onBlur={() => {
                          setCPassErr(false);
                          if (formData?.cPassword !== formData?.password) {
                            setCPassErr(true);
                          }
                        }}
                        required={true}
                        onPaste={handlePaste}
                      />

                      {cPassErr && (
                        <label
                          className="error-msg"
                          style={{
                            display: "block",
                            marginTop: "1px",
                            fontSize: "12px",
                          }}
                        >
                          Passwords don't match!
                        </label>
                      )}
                    </div>
                  </Col>
                  <Col className="gutter-row mb-15" md={24} sm={24} xs={24}>
                    <div
                      className={
                        referralCodeValid === true ? "referral-error" : ""
                      }
                    >
                      <InputDefault
                        name="ReferralCode"
                        placeholder="Referral code"
                        type="text"
                        validationState={setValidationErrors}
                        focusing={validationErrors?.referralCode}
                        onChange={({ target }) => {
                          setFormData({
                            ...formData,
                            referralCode: target.value,
                          });
                          setReferralCodeValid(false);
                        }}
                        required={false}
                      />
                    </div>
                  </Col>
                  <Col className="gutter-row mb-15" md={24} sm={24} xs={24}>
                    <div style={{ textAlign: "start" }}>
                      <p className="m-0">
                        <Checkbox
                          className="checkbox-kilde"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setCheckErr(false);
                            } else {
                              setCheckErr(true);
                            }
                            setChecked(e.target.checked);
                          }}
                          style={{ marginRight: 5 }}
                        />{" "}
                        I accept{" "}
                        <span
                          className="cursor-pointer"
                          style={{ color: "var(--kilde-blue)" }}
                          onClick={() => setPrivacyPdf(true)}
                        >
                          Terms of use
                        </span>{" "}
                        and
                        <span
                          className="cursor-pointer"
                          style={{ color: "var(--kilde-blue)" }}
                          onClick={handleOpenTermsPdf}
                        >
                          {" "}
                          Privacy Policy
                        </span>
                      </p>
                      {noCheckErr ? (
                        <label
                          className="error-msg mt-0"
                          style={{
                            display: "block",
                            marginBottom: 12,
                            marginLeft: 10,
                            fontSize: "12px",
                          }}
                        >
                          Please accept the Terms of Use & Privacy Policy
                        </label>
                      ) : null}
                    </div>
                  </Col>
                  <Col
                    className="gutter-row mb-10 mt-10"
                    md={24}
                    sm={24}
                    xs={24}
                  >
                    <ButtonDefault
                      title="Create account"
                      loading={loader}
                      style={{ width: "100%" }}
                      block={true}
                      id="btn-create-account"
                    />
                  </Col>
                </Row>
              </div>
            </Form>
            <div className="sb-forgot-new-here">
              <p className="sb-pi-subtitle mt-0 mb-50">
                Already have an account?
                <Link className="fp-link" to={ROUTES.LOGIN}>
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>
        {/* singup with google modal */}
        <Modal
          centered
          open={referralModal}
          onCancel={() => {
            setReferralModal(false);
          }}
          width={460}
          footer={null}
          maskClosable={false}
        >
          <p>
            Got a referral code from a friend? Use it here to unlock your
            reward! (T&C apply).
          </p>
          <div
            className={referralCodeValidModal === true ? "referral-error" : ""}
          >
            <InputDefault
              name="ReferralCode"
              placeholder="Referral code"
              type="text"
              validationState={setValidationErrors}
              focusing={validationErrors?.referralCode}
              onChange={({ target }) => {
                setFormData({ ...formData, referralCode: target.value });
                setReferralCodeValidModal(false);
              }}
              required={true}
              errorMsg={"Please enter referral code"}
            />
          </div>

          <div className="sb-text-align mt-20">
            <ButtonDefault
              title="Skip and Continue"
              loading={finalGoogleLoader}
              onClick={() => finalGoogleApi()}
            />
          </div>
        </Modal>
        {/* Privacy PDF Modal */}
        <Modal
          className="sb-pdf-modal"
          centered
          open={termsPdf}
          onCancel={handleCloseTermsPdf}
          width={1000}
          footer={null}
        >
          <iframe
            className="mt-20"
            src={`${PrivacyPdf}#toolbar=0`}
            width="100%"
            height="500px"
            title="PDF Viewer"
          />
        </Modal>
        {/* Terms of use PDF Modal */}
        <Modal
          className="sb-pdf-modal"
          centered
          open={privacyPdf}
          onCancel={() => setPrivacyPdf(false)}
          width={1000}
          footer={null}
        >
          <iframe
            className="mt-20"
            src={`${PDF.TERM_OF_USE}#toolbar=0`}
            width="100%"
            height="500px"
            title="PDF Viewer"
          />
        </Modal>
      </AuthLayout>
    </>
  );
};

export default SignUp;
