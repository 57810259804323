import React, { useState } from "react";
import DashboardLayout from "../../Layouts/DashboardLayout/DashboardLayout";
import { Content } from "antd/es/layout/layout";
import { Breadcrumb, Button, Col, Modal, Row, Upload, message } from "antd";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault";
import axios from "axios";
import { AdditionalDocumentUploadApi } from "../../Apis/UserApi";
import ROUTES from "../../Config/Routes";
import { useNavigate } from "react-router-dom";

const AdditionalDocument = () => {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [isRemove, setIsRemove] = useState(false);
  const [isRemoveFile, setIsRemoveFile] = useState();
  const [docLoading, setDocLoading] = useState(false);

  const props = {
    onRemove: (file) => {
      setIsRemove(true);
      setIsRemoveFile(file);
    },
    beforeUpload: (file) => {
      setFileList((prevFileList) => [...prevFileList, file]);
      return false;
    },
    fileList,
  };

  const handleDeleteFile = () => {
    const index = fileList.indexOf(isRemoveFile);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
    setIsRemove(false);
  };

  const handleBack = () => {
    setIsRemove(false);
  };

  const handleAdditionalDoc = async () => {
    try {
      setDocLoading(true);
      if (fileList && fileList.length > 0) {
        const formData = new FormData();
        for (let i = 0; i < fileList.length; i++) {
          formData.append(`files`, fileList[i]);
        }
        if (formData) {
          const response = await axios.post(`/api/v2/guest/upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (response && response?.data?.fileReference?.length > 0) {
            await AdditionalDocumentUploadApi({
              fileReferences: response?.data?.fileReference,
            });
            message.success("Documents uploaded successfully!");
            setFileList([]);
          }
        }
      }
    } catch (error) {
      message.error("Failed to upload documents. Please try again.");
      setDocLoading(false);
    } finally {
      setDocLoading(false);
    }
  };

  return (
    <div>
      <DashboardLayout>
        <Content className="setting-page-div">
          <Breadcrumb>
            <Breadcrumb.Item
              onClick={() => navigate(ROUTES.SETTINGS)}
              className="cursor-pointer"
            >
              Personal Settings
            </Breadcrumb.Item>
            <Breadcrumb.Item>Additional documents</Breadcrumb.Item>
          </Breadcrumb>
          <p className="setting-head">Additional documents</p>

          <Row gutter={16}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={16}
              className="gutter-row setting-twofa-div"
            >
              <p className="mt-0 filter-subtitle">
                Upload Additional Documents
              </p>
              <p className="add-doc-setting mt-0 mb-16">
                If the support team has asked for more documents, please upload
                them here.
              </p>
              <div className="setting-upload mt-0">
                <Upload {...props} multiple>
                  <Button className="setting-main-upload-btn">
                    <div>
                      <p className="m-0 add-doc-input-head">
                        Additional documents
                      </p>
                      <p className="mt-5 mb-0">
                        Drag & Drop or click here to select a file
                      </p>
                    </div>
                  </Button>
                </Upload>
              </div>
              <div className="mt-16">
                <ButtonDefault
                  onClick={handleAdditionalDoc}
                  title="Submit Files"
                  loading={docLoading}
                  disabled={fileList.length > 0 ? false : true}
                />
              </div>
            </Col>
          </Row>
        </Content>
      </DashboardLayout>
      {/* File remove modal */}
      <Modal
        centered
        open={isRemove}
        onCancel={() => {
          setIsRemove(false);
        }}
        width={400}
        footer={null}
        maskClosable={false}
      >
        <h3 className="sb-text-align">
          Are you sure you want to delete this document?
        </h3>

        <div className="sb-text-align d-flex mt-20">
          <Button
            className="remove-modal-back-btn mr-8 w-100"
            onClick={handleBack}
          >
            Back
          </Button>
          <ButtonDefault
            style={{ width: "100%" }}
            title="Delete"
            onClick={() => handleDeleteFile()}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AdditionalDocument;
