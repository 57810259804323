/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, Col, Form, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import ButtonDefault from "../../../Components/ButtonDefault/ButtonDefault";
import InputDefault from "../../../Components/InputDefault/InputDefault";
import {
  CommitInvest,
  Invest,
  InvestTranche,
  trancheAcceptanceDownload,
} from "../../../Apis/DashboardApi";
import { ErrorResponse } from "../../../Utils/ErrorResponse";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setTrancheResponse } from "../../../Redux/Action/Investor";
import Borrower_warning from "../../../Assets/Images/Icons/Dashboard/borrower_warning.svg";
import { formatCurrency } from "../../../Utils/Reusables";

const StartInvesting = ({ TrancheRes, setLoader }) => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [investModal, setInvestModal] = useState(false);
  const [investLoader, setInvestLoader] = useState(false);
  const [commiInvestLoader, setcommitInvestLoader] = useState(false);
  const [investAmount, setInvestAmount] = useState({
    amount: "",
  });
  const [investmentVal, setInvestmentVal] = useState();
  const [checked, setChecked] = useState(false);
  const [noCheckErr, setCheckErr] = useState(false);
  const [walletBalance, setWalletBalance] = useState();
  const user = useSelector((state) => state.user);
  const [validationErrors, setValidationErrors] = useState({
    amount: false,
  });

  const [downloadPdfLoader, setDownloadPdfLoader] = useState(false);

  useEffect(() => {
    const fetchBalance = async () => {
      if (user?.accounts !== undefined) {
        const accBalance = await handleCheckBalance(user?.accounts);
        setWalletBalance(accBalance);
      }
    };

    fetchBalance();
  }, [user?.accounts]);

  const handleCheckBalance = async (accounts) => {
    for (const currency of accounts) {
      if (currency?.currencyCode === TrancheRes?.tranche?.currencyCode) {
        return currency?.balance;
      }
    }
    return null;
  };

  const handleChange = (e) => {
    setValidationErrors({
      amount: false,
    });
    setInvestAmount({ ...investAmount, [e.target.name]: e.target.value });
  };

  const handleInvest = () => {
    setInvestLoader(true);
    if (
      user?.secondFactorAuth === null &&
      user?.twoFaCheckEnabled === true &&
      user?.investorStatus === "ACTIVE"
    ) {
      message.error("Enable Two Factor Authentication to start investing");
      setInvestLoader(false);
    } else if (investAmount?.amount === "") {
      setValidationErrors({
        amount: true,
      });
      message.error("Please enter an amount.");
      setInvestLoader(false);
    } else if (
      investAmount?.amount < TrancheRes?.tranche?.nominalValue &&
      investAmount?.amount !== ""
    ) {
      setValidationErrors({
        amount: true,
      });
      message.error("Please enter amount more than nominal value");
      setInvestLoader(false);
    } else if (investAmount?.amount > walletBalance) {
      finalInvest();
      setValidationErrors({
        amount: true,
      });
    } else {
      setValidationErrors({
        amount: false,
      });
      finalInvest();
    }
  };

  const finalInvest = () => {
    const requestBody = {
      trancheUuid: TrancheRes?.tranche?.uuid,
      amount: investAmount?.amount,
      acceptTerms: false,
      acceptLetterOfWaiver: false,
    };

    Invest(requestBody)
      .then(async (investmentData) => {
        if (Object.keys(investmentData)?.length > 0) {
          setInvestmentVal(investmentData?.offer);
          setInvestLoader(false);
          setInvestModal(true);
        } else {
          setInvestLoader(false);
        }
      })
      .catch((error) => {
        ErrorResponse(error?.code);
        setInvestLoader(false);
      });
  };

  const handleCommitInvestment = () => {
    setcommitInvestLoader(true);
    if (checked === false) {
      setcommitInvestLoader(false);
      return setCheckErr(true);
    } else {
      const requestBody = {
        trancheUuid: TrancheRes?.tranche?.uuid,
        amount: investAmount?.amount,
      };
      CommitInvest(requestBody)
        .then(async (commitInvestmentData) => {
          if (Object.keys(commitInvestmentData)?.length > 0) {
            message.success(
              "Subscription confirmed! Settlement process will begin shortly and updates will follow"
            );
            setInvestmentVal("");
            setcommitInvestLoader(false);
            setInvestModal(false);
            handleGetTranche();
          } else {
            setcommitInvestLoader(false);
          }
        })
        .catch((error) => {
          ErrorResponse(error?.code);
          setcommitInvestLoader(false);
        });
    }
  };

  const handleGetTranche = () => {
    setLoader(true);
    const requestBody = {
      trancheUuid: slug,
    };
    InvestTranche(requestBody).then(async (tracheRes) => {
      await setTrancheResponse(tracheRes, dispatch);
      setLoader(false);
    });
  };

  const handleDownLoadTrancheAcceptancePdf = () => {
    const data = {
      trancheId: slug,
      amount: investAmount?.amount,
    };
    try {
      setDownloadPdfLoader(true);

      trancheAcceptanceDownload(data)
        .then(async (response) => {
          if (!response.data || response.data.size === 0) {
            throw new Error("Empty file received from server");
          }

          const contentType = response.headers["content-type"];
          const blob = new Blob([response.data], { type: contentType });

          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = response.headers["k-filename"] || "download.pdf";
          link.click();
          setDownloadPdfLoader(false);
        })
        .catch((error) => {
          console.error("Error fetching account summary:", error);
          setDownloadPdfLoader(false);
        });
    } catch (e) {
      console.error("Error fetching account summary:", e);
      setDownloadPdfLoader(false);
    }
  };

  return (
    <Col className="gutter-row add-info-div" lg={24} md={24} sm={24} xs={24}>
      <p className="add-info-head m-0">
        {TrancheRes?.tranche?.subscriptionEnabled === true
          ? "Start investing"
          : "Fully funded"}
      </p>
      <Form
        onFinish={handleInvest}
        name="wrap"
        labelAlign="left"
        labelWrap
        colon={false}
      >
        <div
          className="mt-15 start-invest-div"
          style={{
            display: "flex",
            alignItems: "start",
            flexDirection: window.innerWidth <= 576 ? "column" : "row",
            flexWrap: "nowrap",
          }}
        >
          <div
            style={{ flex: 1, width: "100%" }}
            className={validationErrors.amount === true ? "error-border" : ""}
          >
            <div style={{ position: "relative" }}>
              <InputDefault
                value={investAmount?.amount}
                placeholder="Enter amount to invest"
                type="text"
                name="amount"
                onChange={handleChange}
                required={true}
                errorMsg={"Amount is Required"}
                style={{ backgroundColor: "#ffffff" }}
                disabled={
                  TrancheRes?.tranche?.subscriptionEnabled === false ||
                  user?.investorStatus !== "ACTIVE" ||
                  (user?.secondFactorAuth === null &&
                    user?.twoFaCheckEnabled === true)
                }
              />
              {validationErrors.amount === true && (
                <div className="borrower-warning-logo">
                  <img src={Borrower_warning} alt="Borrower_warning" />
                </div>
              )}
            </div>
            {validationErrors.amount === true &&
            investAmount.amount !== "" &&
            investAmount?.amount < TrancheRes?.tranche?.nominalValue ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                Please enter an amount more than the nominal value
              </span>
            ) : // ) : investAmount?.amount > walletBalance &&
            //   validationErrors.amount === true &&
            //   investAmount.amount !== "" ? (
            //   <span style={{ color: "red", fontSize: "12px" }}>
            //     Insufficient {TrancheRes?.tranche?.currencyCode} balance in your
            //     wallet.
            //   </span>
            investAmount?.amount === "" && validationErrors.amount === true ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                Please enter an amount.
              </span>
            ) : null}
            {TrancheRes?.tranche?.subscriptionEnabled === true ? (
              <p className="add-info-note mt-4 mb-0">
                Min. amount{" "}
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  TrancheRes?.tranche?.nominalValue
                )}
                , max{" "}
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  TrancheRes?.tranche?.principalAvailable
                )}
              </p>
            ) : null}
          </div>
          <div style={{ width: window.innerWidth <= 576 ? "100%" : null }}>
            <Button
              className="invest-btn"
              loading={investLoader}
              style={{ width: "100%" }}
              disabled={
                TrancheRes?.tranche?.subscriptionEnabled === false ||
                user?.investorStatus !== "ACTIVE"
              }
              onClick={handleInvest}
            >
              Invest
            </Button>
          </div>
        </div>
      </Form>

      <Modal
        centered
        open={investModal}
        onCancel={() => {
          setInvestModal(false);
          setCheckErr(false);
        }}
        width={460}
        footer={null}
        maskClosable={false}
        className="invest-modal"
      >
        <p className="m-0 tranch-head mb-24">Start investing</p>

        <div
          className="start-invest-div"
          style={{ display: "flex", alignItems: "start" }}
        >
          <div
            style={{ flex: 1 }}
            className={
              validationErrors.amount === true ? "error-border-modal" : ""
            }
          >
            <div style={{ position: "relative" }}>
              <InputDefault
                placeholder="Enter amount to invest"
                type="text"
                name="amount"
                onChange={handleChange}
                required={true}
                errorMsg={"Amount is Required"}
                style={{ backgroundColor: "#ffffff" }}
                disabled={
                  TrancheRes?.tranche?.subscriptionEnabled === false ||
                  user?.investorStatus !== "ACTIVE"
                }
                value={investAmount?.amount}
                prefix={
                  <span>
                    {TrancheRes?.tranche?.currencyCode === "USD"
                      ? "$"
                      : TrancheRes?.tranche?.currencyCode === "SGD"
                      ? "S$"
                      : "€"}
                  </span>
                }
              />
              {validationErrors.amount === true && (
                <div className="borrower-warning-logo">
                  <img src={Borrower_warning} alt="Borrower_warning" />
                </div>
              )}
            </div>
            {validationErrors.amount === true &&
            investAmount.amount !== "" &&
            investAmount?.amount < TrancheRes?.tranche?.nominalValue ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                Please enter an amount more than the nominal value
              </span>
            ) : investAmount?.amount > walletBalance &&
              validationErrors.amount === true &&
              investAmount.amount !== "" ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                Insufficient {TrancheRes?.tranche?.currencyCode} balance in your
                wallet.
              </span>
            ) : investAmount?.amount === "" &&
              validationErrors.amount === true ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                Please enter an amount.
              </span>
            ) : null}
            {TrancheRes?.tranche?.subscriptionEnabled === true ? (
              <p className="add-info-note-model mt-4 mb-0">
                Min. amount{" "}
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  TrancheRes?.tranche?.nominalValue
                )}
                , max{" "}
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  TrancheRes?.tranche?.principalAvailable
                )}
              </p>
            ) : null}
          </div>
          <div>
            <Button
              className="invest-btn"
              loading={investLoader}
              style={{ width: "100%" }}
              disabled={
                TrancheRes?.tranche?.subscriptionEnabled === false ||
                user?.investorStatus !== "ACTIVE"
              }
              onClick={handleInvest}
            >
              Invest
            </Button>
          </div>
        </div>
        <div className="mt-24">
          <div className="sb-flex-justify-between mb-16">
            <div>
              <p className="m-0">Principal</p>
            </div>
            <div>
              <p className="m-0">
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  investmentVal?.principal
                )}
              </p>
            </div>
          </div>
          <div className="sb-flex-justify-between mb-16">
            <div>
              <p className="m-0">Accrued interest</p>
            </div>
            <div>
              <p className="m-0">
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  investmentVal?.accruedInterest === null
                    ? 0
                    : investmentVal?.accruedInterest
                )}
              </p>
            </div>
          </div>
          <div className="sb-flex-justify-between mb-16">
            <div>
              <p className="m-0">Subscription fee</p>
            </div>
            <div>
              <p className="m-0">
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  investmentVal?.investorSubscriptionFee === null
                    ? 0
                    : investmentVal?.investorSubscriptionFee
                )}
              </p>
            </div>
          </div>
          <div className="sb-flex-justify-between mb-16">
            <div>
              <p className="m-0 fw-600">Total funds to commit</p>
            </div>
            <div>
              <p className="m-0 fw-600">
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  investmentVal?.totalCostForInvestor === null
                    ? 0
                    : investmentVal?.totalCostForInvestor
                )}
              </p>
            </div>
          </div>
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setCheckErr(false);
              } else {
                setCheckErr(true);
              }
              setChecked(e.target.checked);
            }}
            key="borrower"
            className="checkbox-kilde"
          >
            I accept{" "}
            <Button
              loading={downloadPdfLoader}
              onClick={handleDownLoadTrancheAcceptancePdf}
              className="acceptance-btn"
              style={{ color: "var(--kilde-blue)", fontSize: "14px" }}
            >
              investment terms
            </Button>
          </Checkbox>
          {noCheckErr && (
            <label
              className="error-msg mt-0"
              style={{
                display: "block",
                marginBottom: 12,
                fontSize: "12px",
              }}
            >
              Please accept the Investment terms
            </label>
          )}
        </div>

        <div className="sb-text-align mt-24">
          <ButtonDefault
            style={{ width: "100%" }}
            title="Commit"
            onClick={handleCommitInvestment}
            loading={commiInvestLoader}
            disabled={
              investmentVal?.principal <= 0 ||
              investAmount?.amount > walletBalance
            }
          />
        </div>
      </Modal>
    </Col>
  );
};

export default StartInvesting;
