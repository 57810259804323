import ROUTES from "../Config/Routes";

import Cookies from "js-cookie";

import { REGTANK_ERROR_MESSAGES } from "./Constant";
import { checkStepStatus, redirectToVue } from "./Helpers";

export function capitalizeFirstLetter(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export function validatePassword(password) {
  var re = /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/;
  return re.test(password);
}

export function camelCaseSting(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
}

export function getCountryNameByCode(countryList, code) {
  const country = countryList?.find((country) => country?.key === code);
  return country ? country?.value : null;
}

export function formatNumber(number) {
  const formattedNumber = number.toLocaleString("en-SG", {
    style: "currency",
    currency: "SGD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formattedNumber;
}

export function handleFinish(user, navigate) {
  if (user?.investorStatus === "ACTIVE") {
    redirectToVue();
  } else {
    if (
      checkStepStatus(user?.waitingVerificationSteps, "QUESTIONNAIRE") === false
    ) {
      if (user?.investorType === "INDIVIDUAL") {
        navigate(ROUTES.INDIVIDUAL_VERIFICATION);
      } else {
        navigate(ROUTES.ORGANIZATION_VERIFICATION);
      }
    } else {
      navigate(ROUTES.VERIFICATION);
    }
  }
}

export const getTransformedCountries = (countries) => {
  const transformed = countries?.map((country) => ({
    key: country.code,
    value: country.name,
  }));
  transformed?.unshift({ key: "ALL", value: "All countries" });

  return transformed;
};

export const getTransformedIndustries = (industries) => {
  const transformed = industries?.map((industry) => ({
    key: industry.code,
    value: industry.name,
  }));
  transformed?.unshift({ key: "ALL", value: "All industries" });

  return transformed;
};
export const getTransformedProductTypes = (products) => {
  const transformed = products?.map((product) => ({
    key: product.code,
    value: product.name,
  }));
  transformed?.unshift({ key: "ALL", value: "All product type" });

  return transformed;
};
export const getTransformedLoanOriginator = (originators) => {
  const transformed = originators?.map((originator) => ({
    key: originator.code,
    value: originator.name,
  }));
  transformed?.unshift({ key: "ALL", value: "All originators" });

  return transformed;
};

export const generateErrorMessages = (codes) => {
  return codes.map((code) => REGTANK_ERROR_MESSAGES[code]);
};

export const formatDateWithTime = (dateString) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
};

export const britishFormatDateWithTime = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const isPM = hours >= 12;
  hours = hours % 12 || 12;
  const formattedHours = String(hours).padStart(2, "0");

  const formattedDate = `${day}/${month}/${year} ${formattedHours}:${minutes} ${
    isPM ? "PM" : "AM"
  }`;

  return formattedDate;
};

export const SupportChatButton = () => {
  const getUser = Cookies.get("user");
  const user = getUser && JSON.parse(getUser);
  return (
    <div
      data-tf-popover="rSEPG1V2"
      data-tf-opacity="100"
      data-tf-iframe-props="title=Kilde Customer Support Chat"
      data-tf-transitive-search-params
      data-tf-button-color="#0445AF"
      data-tf-medium="snippet"
      data-tf-hidden={`email=${user?.email},investor_number=${user?.number}`}
      style={{ all: "unset" }}
    />
  );
};

export const stepperRedirection = (user) => {
  if (
    user?.vwoFeatures?.identityVerificationSystem?.idvSystemToUse ===
      "veriff" &&
    checkStepStatus(user?.waitingVerificationSteps, "IDENTITY_VERIFICATION") ===
      false
  ) {
    return 3;
  } else {
    return 1;
  }
};

export const formatCamelCaseToTitle = (str) => {
  const words = str?.replace(/([A-Z])/g, " $1").trim();
  return words
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const formatCurrency = (currencySymbol, value) => {
  const formatter = new Intl.NumberFormat("en-SG", {
    style: "currency",
    currency: "SGD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  let formattedValue = formatter.format(value).replace("$", "").trim();
  if (value < 0) {
    formattedValue = `-${currencySymbol}${formattedValue.slice(1)}`;
  } else {
    formattedValue = `${currencySymbol}${formattedValue}`;
  }

  // Check if there is one decimal point
  if (formattedValue.includes(".") && !formattedValue.includes("..")) {
    const decimalIndex = formattedValue.indexOf(".");
    const decimalPart = formattedValue.slice(decimalIndex + 1);
    if (decimalPart.length === 1) {
      formattedValue += "0";
    }
  }

  return formattedValue;
};

export const getFilenameDetails = (filename) => {
  // Get the file format (extension) by finding the last dot in the filename
  const lastDotIndex = filename.lastIndexOf(".");
  const fileFormat =
    lastDotIndex !== -1 ? filename.substring(lastDotIndex) : "";

  // Get the base name without the file extension
  const baseName =
    lastDotIndex !== -1 ? filename.substring(0, lastDotIndex) : filename;

  // Check if the base name is longer than 20 characters
  if (baseName.length > 20) {
    // Truncate the base name to the first 20 characters and add "..."
    return `${baseName.substring(0, 20)}...${fileFormat}`;
  } else {
    // If the base name is shorter than or equal to 20 characters, return it as is with the file format
    return filename;
  }
};
