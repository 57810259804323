import React from "react";
import { Button } from "antd";

import "./style.css";

const ButtonIcon = ({ title, onClick, loading, style, icon, block, id }) => {
  return (
    <Button
      className="kl-button-icon"
      // icon={icon}
      onClick={onClick}
      loading={loading}
      block={block}
      id={id}
    >
      {/* <span> */}
      {icon}
      {/* <img src={icon} alt="" /> */}
      {/* </span> */}
      {title && <span className="kl-button-icon-title">{title}</span>}
    </Button>
  );
};

export default ButtonIcon;
