/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { Col, Pagination, Row, Skeleton, Table, Tag, Button } from "antd";
import FilterComponent from "./FilterComponent";
import ManualInvestmentCard from "./ManualInvestmentCard";
import SlidersHorizontal from "../../../Assets/Images/SlidersHorizontal.svg";
import Arrow from "../../../Assets/Images/arrow.svg";
import ShowFilterModal from "./ShowFilterModal";
import { TranchListing } from "../../../Apis/DashboardApi";
import ROUTES from "../../../Config/Routes";
import { useNavigate } from "react-router-dom";
import { britishFormatDate } from "../../../Utils/Helpers";
import "./style.css";
import { setTranchFilter } from "../../../Redux/Action/Dashboard";
import File from "../../../Assets/Images/File.svg";
import { formatCurrency } from "../../../Utils/Reusables";

const ManualInvestment = ({ showButtonActive }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filterData = useSelector((state) => state);
  const fromInterest = filterData?.dashboard?.tranchFilter?.data?.fromInterest;
  const toInterest = filterData?.dashboard?.tranchFilter?.data?.toInterest;
  const currency = filterData?.dashboard?.tranchFilter?.data?.currency;
  const industry = filterData?.dashboard?.tranchFilter?.data?.industry;
  const earlyRedemptionFrequency =
    filterData?.dashboard?.tranchFilter?.data?.EarlyRedemptionFrequency;
  const country = filterData?.dashboard?.tranchFilter?.data?.country;

  const [filterModal, setFilterModal] = useState(false);
  const [tranchList, setTranchList] = useState();
  const [totalItem, setTotalItem] = useState();
  const [itemsPerPage, setItemPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const [tranchLoader, setTranchLoader] = useState(false);
  const [trancheCountry, setTrancheCountry] = useState();

  const onShowSizeChange = (current, pageSize) => {
    setItemPerPage(pageSize);
  };

  const handleTranchListing = (page) => {
    setTranchLoader(true);
    const filterPayload = {
      page: page,
      pageSize: itemsPerPage,
      ordering: [
        {
          field: "created_at",
          asc: false,
        },
      ],
    };

    if (fromInterest) {
      filterPayload.interestRateFrom = fromInterest;
    }
    if (toInterest) {
      filterPayload.interestRateTo = toInterest;
    }
    if (currency) {
      filterPayload.currencyCode = currency;
    }
    if (industry) {
      filterPayload.industry = industry;
    }
    if (earlyRedemptionFrequency) {
      filterPayload.earlyRedemptionFrequency = earlyRedemptionFrequency;
    }
    if (country) {
      filterPayload.country = country;
    }

    TranchListing(filterPayload).then((trachRes) => {
      setTranchLoader(false);
      setTotalItem(trachRes?.totalItems);
      setTranchList(trachRes?.items);
      setTrancheCountry(trachRes?.countries);
    });
  };

  useEffect(() => {
    setTranchFilter(
      {
        data: {
          fromInterest: "",
          toInterest: "",
          currency: "",
          industry: "",
          EarlyRedemptionFrequency: "",
          country: "",
        },
      },
      dispatch
    );
  }, [dispatch]);

  const resetPageAndFetch = debounce(() => {
    setCurrentPage(1);
    handleTranchListing(1);
  }, 300);

  useEffect(() => {
    if (
      fromInterest !== undefined ||
      toInterest !== undefined ||
      earlyRedemptionFrequency !== undefined ||
      country !== undefined ||
      currency !== undefined ||
      industry !== undefined ||
      itemsPerPage !== 6
    ) {
      resetPageAndFetch();
    }

    return () => {
      resetPageAndFetch.cancel();
    };
  }, [
    fromInterest,
    toInterest,
    earlyRedemptionFrequency,
    country,
    currency,
    industry,
    itemsPerPage,
  ]);

  const handleChange = (page) => {
    setCurrentPage(page);
    handleTranchListing(page);
  };

  const columns = [
    {
      title: "Tranche number / Borrower",
      dataIndex: "TrancheNumber",
    },
    {
      title: "",
      dataIndex: "Blank",
    },
    {
      title: "Currency",
      dataIndex: "Currency",
    },
    {
      title: "Minimum investment",
      dataIndex: "MinimumInvestment",
    },
    {
      title: "Industry",
      dataIndex: "Industry",
    },
    {
      title: "Interest Rate",
      dataIndex: "InterestRate",
    },
    {
      title: "Available to invest",
      dataIndex: "AvailableInvest",
    },
    {
      title: "Maturity Date",
      dataIndex: "MaturityDate",
    },
    {
      title: "Bond Sold",
      dataIndex: "BondSold",
    },
    {
      title: "",
      dataIndex: "RightArrow",
    },
  ];

  const data = tranchList?.map((index) => ({
    key: { index },
    Country:
      index?.countries?.length > 0
        ? index?.countries?.map((countryCode, index) => (
            <span
              style={{
                filter: "drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.10))",
              }}
              key={index}
              className={`mb-5 ml-5 mt-0 fi fi-${countryCode.toLowerCase()}`}
            />
          ))
        : "-",
    TrancheNumber: (
      <div
        onClick={() => {
          navigate(`${ROUTES.TRANCH_INVEST}/${index?.uuid}`);
        }}
        className="cursor-pointer"
      >
        <p className="m-0">{index?.trancheNumber}</p>
        <h3 className="m-0">{index?.details?.title}</h3>
      </div>
    ),
    Blank: (
      <div>
        {index?.capitalCallFrequency === 0 ? (
          ""
        ) : (
          <Tag color="default" className="tranche-tag">
            Early redemption
          </Tag>
        )}
        <br />
        <Tag color="default" className="tranche-tag">
          {index?.collateral[0]}
        </Tag>
      </div>
    ),
    Currency: index?.currencyCode,
    MinimumInvestment: formatCurrency("$", index?.principalIssued),
    Industry: index?.trancheType
      ?.toLowerCase()
      .replace(/_/g, " ")
      .replace(/\b\w/g, (c) => c.toUpperCase()),
    InterestRate: formatCurrency("", index?.interestRate) + "%",
    AvailableInvest: formatCurrency("$", index?.principalAvailable),
    MaturityDate: britishFormatDate(index?.maturityDate),
    BondSold: formatCurrency("", index?.debenturesSold),
    RightArrow: (
      <div
        onClick={() => {
          navigate(`${ROUTES.TRANCH_INVEST}/${index?.uuid}`);
        }}
      >
        <img src={Arrow} alt="right_arrow" className="cursor-pointer" />
      </div>
    ),
  }));

  return (
    <Row gutter={window.innerWidth >= 576 ? 20 : 0}>
      <Col xs={5} className="invest-filter">
        <FilterComponent trancheCountry={trancheCountry} />
        <ShowFilterModal
          filterModal={filterModal}
          setFilterModal={setFilterModal}
        />
      </Col>

      <Col xs={24} md={19} lg={19} className="gutter-row">
        {showButtonActive === true ? (
          <p className="tranche-header mt-0 mb-24">Investments</p>
        ) : (
          ""
        )}
        <Col xs={24} className="invest-col">
          <Button
            className="invest-showfilterbutton"
            onClick={() => setFilterModal(true)}
            style={{ height: 36 }}
          >
            Show Filtres
            <img src={SlidersHorizontal} alt="img" />
          </Button>
        </Col>

        {showButtonActive === true ? (
          <>
            <Row gutter={20}>
              {tranchLoader === false ? (
                tranchList?.length > 0 ? (
                  tranchList?.map((tranchInfo, index) => (
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={8}
                      key={index}
                      className="mb-20"
                    >
                      <ManualInvestmentCard tranchInfo={tranchInfo} />
                    </Col>
                  ))
                ) : (
                  <div className="not-found-container">
                    <img alt="nothing found" src={File} />
                    <p className="not-found-text">Nothing found</p>
                  </div>
                )
              ) : (
                [0, 1, 2].map((index) => (
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}
                    id={index}
                    className="mb-20"
                  >
                    <div className="tranch-list-skeleton">
                      <Skeleton.Button loading={true} active size="large" />
                    </div>
                  </Col>
                ))
              )}
            </Row>
            {tranchList?.length > 0 && (
              <Pagination
                className="tranch-table-pagination"
                defaultCurrent={1}
                defaultPageSize={itemsPerPage}
                current={currentPage}
                total={totalItem}
                onChange={handleChange}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                pageSizeOptions={["6", "20", "50", "100"]}
                locale={{
                  items_per_page: " ",
                }}
              />
            )}
          </>
        ) : (
          <Col xs={24} className="list-view-div">
            <h3 className="mt-0">Investments</h3>
            <div className="table-container">
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                className="trache-table"
                loading={tranchLoader}
              />
              {tranchList?.length > 0 && (
                <Pagination
                  className="tranch-table-pagination mb-16"
                  defaultCurrent={1}
                  defaultPageSize={itemsPerPage}
                  current={currentPage}
                  total={totalItem}
                  onChange={handleChange}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  pageSizeOptions={["6", "20", "50", "100"]}
                  locale={{
                    items_per_page: " ",
                  }}
                />
              )}
            </div>
          </Col>
        )}
      </Col>
    </Row>
  );
};

export default ManualInvestment;
