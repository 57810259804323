/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import DashboardLayout from "../../../Layouts/DashboardLayout/DashboardLayout";
import { Breadcrumb, Col, Layout, Row, Table } from "antd";
import "./style.css";
import ButtonDefault from "../../../Components/ButtonDefault/ButtonDefault";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import TranchInvestInfo from "./TranchInvestInfo";
import StartInvesting from "./StartInvesting";
import InvestDetails from "./InvestDetails";
import BondDescription from "./BondDescription";
// import BarCharts from "./AverageAPR";
import AssetsInfo from "./AssetsInfo";
import Document from "./Document";
import OutStandingPayment from "./OutStandingPayment";
import StartCapitalCallRequest from "./StartCapitalCallRequest";
import CapitalCallRequestLists from "./CapitalCallRequestList";

import { CapitalCallList, InvestTranche } from "../../../Apis/DashboardApi";
// import RePaymentRate from "./RePaymentRate";
import { britishFormatDate } from "../../../Utils/Helpers";
import GlobalVariabels from "../../../Utils/GlobalVariabels";
import ROUTES from "../../../Config/Routes";
import { useDispatch, useSelector } from "react-redux";
import FinishOnboarding from "../Investment/FinishOnboarding";
import {
  setCapitalRequests,
  setTrancheResponse,
} from "../../../Redux/Action/Investor";
import { ErrorResponse } from "../../../Utils/ErrorResponse";
import { getUser } from "../../../Apis/UserApi";
import { setUserDetails } from "../../../Redux/Action/User";
import { formatCurrency } from "../../../Utils/Reusables";
const { Content } = Layout;

const Borrower = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();
  const tranche = useSelector((state) => state?.investor?.tranche);
  const [TrancheRes, setTrancheRes] = useState(tranche);
  const [loader, setLoader] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const user = useSelector((state) => state.user);
  const [capitalRequestLoading, setCapitalRequestLoading] = useState(false);

  useEffect(() => {
    setTrancheRes(tranche);
    getUserDetails();
  }, [tranche]);

  const getUserDetails = async () => {
    try {
      const response = await getUser();
      if (response) {
        await setUserDetails(response, dispatch);
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  useEffect(() => {
    setLoader(true);
    const requestBody = {
      trancheUuid: slug,
    };
    InvestTranche(requestBody).then(async (tracheRes) => {
      await setTrancheResponse(tracheRes, dispatch);
      setLoader(false);
    });
  }, []);

  const capitalCallColumns = [
    {
      title: "Next Capital Call Dates",
      dataIndex: "NextCapitalCallDates",
    },
    {
      title: "Submit Notice by",
      dataIndex: "SubmitNoticeBy",
    },
  ];

  const capitalCallData = TrancheRes?.tranche?.capitalCallDates?.map(
    (data, index) => ({
      key: index,
      NextCapitalCallDates: britishFormatDate(data.nextCapitalCallDate),
      SubmitNoticeBy: britishFormatDate(data.nextCapitalCallRequestDeadline),
    })
  );

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const toggleLess = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const requestBody = {
      trancheUuid: slug,
    };
    if (TrancheRes?.investment?.debentureCount > 0) {
      getCapitalCallRequestList(requestBody);
    }
  }, [TrancheRes?.investment?.debentureCount]);

  const getCapitalCallRequestList = (requestBody) => {
    setCapitalRequestLoading(true);
    CapitalCallList(requestBody)
      .then(async (capitaRequestlist) => {
        if (Object.keys(capitaRequestlist)?.length > 0) {
          await setCapitalRequests(capitaRequestlist, dispatch);
          setCapitalRequestLoading(false);
        } else {
          setCapitalRequestLoading(false);
        }
      })
      .catch((error) => {
        ErrorResponse(error?.code);
        setCapitalRequestLoading(false);
      });
  };

  return (
    <div>
      <DashboardLayout>
        {loader === false ? (
          <Content className="borrower-page-div">
            {user?.investorStatus !== "ACTIVE" ||
            (user?.secondFactorAuth === null &&
              user?.twoFaCheckEnabled === true) ? (
              <div className="mb-24">
                <FinishOnboarding />
              </div>
            ) : null}
            <Breadcrumb>
              <Breadcrumb.Item
                onClick={() => navigate(ROUTES.TRANCH_LISTING)}
                className="cursor-pointer"
              >
                Invest
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {TrancheRes?.tranche?.details?.title}
              </Breadcrumb.Item>
            </Breadcrumb>
            <Row className="media-tranche-head">
              <Col sx={24} sm={24} className="mt-10 mb-8">
                {TrancheRes?.borrower?.companyLogo !== null ? (
                  <div className="company-logo-div">
                    <img
                      src={`${GlobalVariabels.VIEW_IMG}/${TrancheRes?.borrower?.companyLogo}`}
                      alt="borrower_icon"
                    />
                  </div>
                ) : null}
              </Col>
              <Col xs={24} md={24}>
                <p className="borrower-id-text mb-0  mt-0">
                  {TrancheRes?.tranche?.trancheNumber}
                </p>
                <h2 className="mt-0 mb-0 borrower-company-title">
                  {TrancheRes?.tranche?.details?.title}
                </h2>
              </Col>
            </Row>
            <div className="borrower-logo-div desktop-tranche-head">
              <div>
                <p className="borrower-id-text mb-0 mt-10">
                  {TrancheRes?.tranche?.trancheNumber}
                </p>
                <h2 className="mt-0 mb-0 borrower-company-title">
                  {TrancheRes?.tranche?.details?.title}
                </h2>
              </div>
              {TrancheRes?.borrower?.companyLogo !== null ? (
                <div className="company-logo-div">
                  <img
                    src={`${GlobalVariabels.VIEW_IMG}/${TrancheRes?.borrower?.companyLogo}`}
                    alt="borrower_icon"
                  />
                </div>
              ) : null}
            </div>
            <Row className="mt-24 mb-24 media-borrower-b-row">
              <TranchInvestInfo TrancheRes={TrancheRes} />
            </Row>
            <Row
              className={
                window.innerWidth <= 576
                  ? "mt-16 mb-24 media-borrower-b-row"
                  : "mt-24 mb-24 media-borrower-b-row"
              }
            >
              <Col className="gutter-row" lg={24} md={24} sm={24} xs={24}>
                <Row>
                  <Col
                    sm={24}
                    md={24}
                    lg={24}
                    className="gutter-row infomation-div"
                    style={{ flex: 1 }}
                  >
                    <p className="m-0 tranch-head">Overview</p>
                    <p className="business-dec-borrower">
                      <>
                        {TrancheRes?.tranche?.details?.summary?.shortDescription
                          .split("\n\n")
                          .map((paragraph, index) => (
                            <p key={index}>
                              {paragraph.split("\n").map((line, lineIndex) => (
                                <React.Fragment key={lineIndex}>
                                  {line}
                                  {lineIndex <
                                    paragraph.split("\n").length - 1 && <br />}
                                </React.Fragment>
                              ))}
                            </p>
                          ))}
                      </>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row
              className="mb-24 media-borrower-b-row t-desc-div"
              gutter={window.innerWidth >= 768 ? 20 : 0}
              style={{ display: "flex" }}
            >
              <Col
                sm={24}
                md={12}
                lg={12}
                className="gutter-row media-right-pad"
                style={{ flexGrow: 1 }}
              >
                <Row style={{ height: "100%" }}>
                  <StartInvesting
                    TrancheRes={TrancheRes}
                    setLoader={setLoader}
                  />
                  <InvestDetails
                    TrancheRes={TrancheRes}
                    setLoader={setLoader}
                  />
                </Row>
              </Col>
              <Col
                sm={24}
                md={12}
                lg={12}
                className="infomation-div gutter-row medium-tranch-col"
                style={{ flexGrow: 1 }}
              >
                <BondDescription TrancheRes={TrancheRes} />
              </Col>
            </Row>

            {TrancheRes?.tranche?.details?.keyFinancialsCsv !== "" &&
              TrancheRes?.tranche?.details?.keyFinancialsCsv !== null && (
                <Row className="media-margin left-marg-none mb-24">
                  {/* {TrancheRes?.tranche?.details?.averageApr !== "" && (
                  <Col
                    xs={24}
                    sm={24}
                    md={
                      TrancheRes?.tranche?.details?.repaymentRate === "" &&
                      (TrancheRes?.tranche?.details?.keyFinancialsCsv === "" ||
                        TrancheRes?.tranche?.details?.keyFinancialsCsv === null)
                        ? 24
                        : 12
                    }
                    lg={
                      TrancheRes?.tranche?.details?.repaymentRate === "" &&
                      (TrancheRes?.tranche?.details?.keyFinancialsCsv === "" ||
                        TrancheRes?.tranche?.details?.keyFinancialsCsv === null)
                        ? 24
                        : 12
                    }
                    className="gutter-row infomation-div"
                  >
                    <BarCharts />
                  </Col>
                )} */}

                  {/* {TrancheRes?.tranche?.details?.repaymentRate !== "" && (
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    className="gutter-row medium-tranch-col"
                  >
                    <RePaymentRate TrancheRes={TrancheRes} />
                  </Col>
                )} */}
                  {TrancheRes?.tranche?.details?.keyFinancialsCsv !== "" &&
                    TrancheRes?.tranche?.details?.keyFinancialsCsv !== null && (
                      <Col
                        xs={24}
                        sm={24}
                        md={
                          TrancheRes?.tranche?.details?.repaymentRate === ""
                            ? 24
                            : 24
                        }
                        lg={
                          TrancheRes?.tranche?.details?.repaymentRate === ""
                            ? 24
                            : 24
                        }
                        className={
                          TrancheRes?.tranche?.details?.repaymentRate === ""
                            ? "gutter-row"
                            : "gutter-row medium-tranch-col"
                        }
                      >
                        <AssetsInfo TrancheRes={TrancheRes} />
                      </Col>
                    )}
                </Row>
              )}

            <Row
              gutter={10}
              className={
                TrancheRes?.tranche?.details?.repaymentRate === "" ||
                TrancheRes?.tranche?.details?.keyFinancialsCsv === "" ||
                TrancheRes?.tranche?.details?.keyFinancialsCsv === null ||
                TrancheRes?.tranche?.details?.averageApr === ""
                  ? "infomation-div media-borrower-t-row t-desc-div left-marg-none mt-24"
                  : "infomation-div mt-24 media-borrower-t-row t-desc-div left-marg-none"
              }
            >
              <Col sm={24} md={24} lg={12} className="gutter-row">
                <Row>
                  <Col md={24} className="mb-40">
                    <p className="mt-0 tranch-head">
                      Business Description of the Borrower
                    </p>
                    {TrancheRes?.borrower?.description === "" ? (
                      "No Description found"
                    ) : (
                      <>
                        <p className="business-dec-borrower">
                          {expanded ? (
                            TrancheRes?.borrower?.description
                              .split("\n\n")
                              .map((paragraph, index) => (
                                <p key={index}>
                                  {paragraph
                                    .split("\n")
                                    .map((line, lineIndex) => (
                                      <React.Fragment key={lineIndex}>
                                        {line}
                                        {lineIndex <
                                          paragraph.split("\n").length - 1 && (
                                          <br />
                                        )}
                                      </React.Fragment>
                                    ))}
                                </p>
                              ))
                          ) : (
                            <p>
                              {`${TrancheRes?.borrower?.description.slice(
                                0,
                                100
                              )}`}
                              {!expanded && "..."}
                            </p>
                          )}
                        </p>
                        {expanded ? (
                          <ButtonDefault
                            title="Read less"
                            onClick={toggleLess}
                          />
                        ) : (
                          <ButtonDefault
                            title="Read more"
                            onClick={toggleExpand}
                          />
                        )}
                      </>
                    )}
                  </Col>
                  <Col md={24}>
                    <OwlCarousel
                      className="owl-main owl-theme"
                      items={1}
                      nav={true}
                      loop
                      dots={true}
                    >
                      {TrancheRes?.tranche?.pictures?.length > 0
                        ? TrancheRes?.tranche?.pictures?.map((data, index) => {
                            return (
                              <div className="item" key={index}>
                                <img
                                  src={`${GlobalVariabels.VIEW_IMG}/${data}`}
                                  alt="Client"
                                />
                              </div>
                            );
                          })
                        : "no picure found"}
                    </OwlCarousel>
                  </Col>
                </Row>
              </Col>
              <Col sm={24} md={24} lg={12}>
                <Row>
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={24}
                    className="trach-info mb-10 medium-tranch-col"
                  >
                    <Row>
                      <Col xs={12} lg={12}>
                        <p className="mb-5 mt-0 borrower-info-tag">Countries</p>
                      </Col>
                      <Col xs={12} lg={12} className="country-flag">
                        <p className="mb-5 mt-0 card-val-tag">
                          {TrancheRes?.borrower?.countries?.length > 0
                            ? TrancheRes?.borrower?.countries?.map(
                                (countryCode, index) => (
                                  <span
                                    style={{
                                      filter:
                                        "drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.10))",
                                      border: "1px solid black",
                                    }}
                                    key={index}
                                    className={`mb-5 ml-5 mt-0 fi fi-${countryCode.toLowerCase()}`}
                                  />
                                )
                              )
                            : "-"}
                        </p>
                      </Col>

                      {TrancheRes?.tranche?.trancheType === "REAL_ESTATE" ? (
                        <>
                          <Col xs={12} lg={12}>
                            <p className="mb-5 mt-0 borrower-info-tag">
                              Number of projects
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="mb-5 mt-0 card-val-tag">
                              {TrancheRes?.borrower?.numberOfProjects}
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="mb-5 mt-0 borrower-info-tag">
                              Current projects
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="mb-5 mt-0 card-val-tag">
                              {TrancheRes?.borrower?.currentProjects}
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="mb-5 mt-0 borrower-info-tag">
                              Experience
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="mb-5 mt-0 card-val-tag">
                              {TrancheRes?.borrower?.yearsOfExperience} years
                            </p>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col xs={12} lg={12}>
                            <p className="mb-5 mt-0 borrower-info-tag">
                              Kilde rating
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="mb-5 mt-0 card-val-tag">
                              {TrancheRes?.borrower?.creditRating}
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="m-0 mb-5 borrower-info-tag">
                              Loans originated
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            {" "}
                            <p className="m-0 mb-5 card-val-tag">
                              {TrancheRes?.borrower?.loansOriginated === 0
                                ? "NA"
                                : formatCurrency(
                                    TrancheRes?.borrower?.currencySymbol,
                                    TrancheRes?.borrower?.loansOriginated
                                  )}
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="m-0 mb-5 borrower-info-tag">
                              Loan portfolio
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="m-0 mb-5 card-val-tag">
                              {TrancheRes?.borrower?.loansOriginated === 0
                                ? "NA"
                                : formatCurrency(
                                    TrancheRes?.borrower?.currencySymbol,
                                    TrancheRes?.borrower?.loansPortfolio
                                  )}
                            </p>
                          </Col>
                          <Col xs={24} lg={24}>
                            <p className="m-0 mb-5 borrower-info-tag">
                              Issuance on Kilde
                            </p>
                          </Col>

                          {TrancheRes?.borrower?.borrowingsOnPlatform?.map(
                            (res, index) => (
                              <>
                                <Col xs={12} lg={12}>
                                  <p className="m-0 mb-5 borrower-info-tag-issuance">
                                    Bond in {res?.currencyCode}
                                  </p>
                                </Col>
                                <Col xs={12} lg={12}>
                                  <p className="m-0 mb-5 card-val-tag">
                                    {formatCurrency(
                                      res?.currencySymbol,
                                      res?.borrowingOnPlatform
                                    )}
                                  </p>
                                </Col>
                              </>
                            )
                          )}

                          <Col xs={12} lg={12}>
                            <p className="m-0 mb-5 borrower-info-tag">
                              Founded year
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="m-0 mb-5 card-val-tag">
                              {TrancheRes?.borrower?.foundedYear}
                            </p>
                          </Col>
                        </>
                      )}

                      <Col xs={12} lg={12}>
                        <p className="m-0 mb-5 borrower-info-tag">
                          Number of employees
                        </p>
                      </Col>
                      <Col xs={12} lg={12}>
                        <p className="m-0 mb-5 card-val-tag">
                          {TrancheRes?.borrower?.numberOfEmployees === 0
                            ? "NA"
                            : TrancheRes?.borrower?.numberOfEmployees}
                        </p>
                      </Col>
                      <Col xs={12} lg={12}>
                        <p className="m-0 mb-5 borrower-info-tag">Site</p>
                      </Col>
                      <Col xs={12} lg={12}>
                        <p className="m-0 mb-5 card-val-tag">
                          <Link
                            to={TrancheRes?.borrower?.companyWebsite}
                            className="borrower-desc-link"
                          >
                            {TrancheRes?.borrower?.companyWebsite}
                          </Link>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Document TrancheRes={TrancheRes} />
                </Row>
              </Col>
            </Row>

            <Row
              // gutter={
              //   window.innerWidth >= 768
              //     ? capitalRequestData?.length === 0
              //       ? 20
              //       : 0
              //     : 0
              // }
              gutter={window.innerWidth >= 768 ? 20 : 0}
              className="mt-24 media-borrower-t-row left-marg-none"
            >
              <Col
                xs={24}
                sm={24}
                md={
                  TrancheRes?.investment?.debentureCount > 0 ||
                  TrancheRes?.tranche?.capitalCallEnabled === false
                    ? 24
                    : 12
                }
                lg={
                  TrancheRes?.investment?.debentureCount > 0 ||
                  TrancheRes?.tranche?.capitalCallEnabled === false
                    ? 24
                    : 12
                }
                className="gutter-row infomation-div"
              >
                <OutStandingPayment TrancheRes={TrancheRes} />
              </Col>
              {TrancheRes?.tranche?.capitalCallEnabled === true && (
                <>
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    className={`gutter-row medium-tranch-col media-left-pad ${
                      TrancheRes?.investment?.debentureCount > 0
                        ? `mt-24`
                        : null
                    }`}
                  >
                    <div className="infomation-div" style={{ height: "100%" }}>
                      <p className="mt-0 tranch-head mb-15">Capital Calls</p>
                      {TrancheRes?.investment?.nextCapitalCallDate === null ? (
                        <p className="business-dec-borrower">
                          No capital call dates available
                        </p>
                      ) : (
                        <>
                          <p className="business-dec-borrower">
                            {`You have a right to require from the Borrower to execute
      the early repayment of the principal and accured but unpaid
      interest on the specific dates, as defined in the table
      below. You will need to give a ${TrancheRes?.tranche?.noticePeriodBeforeCapitalCall}-days
      advance notice to make the call.`}
                          </p>
                          <p className="business-dec-borrower">
                            {`Next capital call will be on ${britishFormatDate(
                              TrancheRes?.investment?.nextCapitalCallDate
                            )}, and you need to
      sumbit an advance notice to us by ${britishFormatDate(
        TrancheRes?.investment?.nextCapitalCallRequestDeadline
      )}.`}
                          </p>
                        </>
                      )}

                      <div className="mt-15 table-container">
                        <Table
                          columns={capitalCallColumns}
                          dataSource={capitalCallData}
                          className="trache-table outstanding-pay-table"
                          pagination={false}
                        />
                      </div>
                    </div>
                  </Col>
                  {TrancheRes?.investment?.debentureCount > 0 && (
                    <Col
                      sm={24}
                      md={12}
                      lg={12}
                      className="gutter-row media-right-pad mt-24"
                      style={{ flexGrow: 1 }}
                    >
                      <Row style={{ height: "100%" }}>
                        <StartCapitalCallRequest
                          TrancheRes={TrancheRes}
                          setCapitalRequestLoading={setCapitalRequestLoading}
                        />
                        <CapitalCallRequestLists
                          TrancheRes={TrancheRes}
                          capitalRequestLoading={capitalRequestLoading}
                          setCapitalRequestLoading={setCapitalRequestLoading}
                        />
                      </Row>
                    </Col>
                  )}
                </>
              )}
            </Row>
            <Row className="mt-24 media-borrower-t-row">
              <Col xs={24} md={24}>
                <div className="infomation-div">
                  <p className="m-0 tranch-head">Deal structure</p>
                  <p className="business-dec-borrower mb-0">
                    {TrancheRes?.tranche?.details?.summary?.dealStructure}
                  </p>
                </div>
              </Col>
            </Row>
          </Content>
        ) : (
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              zIndex: 99,
              transform: "translate(-37px, -33px)",
            }}
          >
            <ReactLoading
              type="spin"
              color="var(--kilde-blue)"
              height={60}
              width={60}
            />
          </div>
        )}
      </DashboardLayout>
    </div>
  );
};

export default Borrower;
