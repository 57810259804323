/* eslint-disable react-hooks/exhaustive-deps */
import { Col, message } from "antd";
import React, { useState } from "react";
import ButtonDefault from "../../../Components/ButtonDefault/ButtonDefault";
import InputDefault from "../../../Components/InputDefault/InputDefault";
import {
  CapitalCallList,
  CapitalCallRequest,
} from "../../../Apis/DashboardApi";
import { ErrorResponse } from "../../../Utils/ErrorResponse";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCapitalRequests } from "../../../Redux/Action/Investor";
import Borrower_warning from "../../../Assets/Images/Icons/Dashboard/borrower_warning.svg";
import { formatCurrency } from "../../../Utils/Reusables";

const StartCapitalCallRequest = ({ TrancheRes, setCapitalRequestLoading }) => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [capitalRequestLoader, setCapitalRequestLoader] = useState(false);
  const [investAmount, setInvestAmount] = useState({
    amount: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    amount: false,
  });
  const [backendError, setBackendError] = useState(null);

  const handleChange = (e) => {
    setBackendError(null);
    setValidationErrors({
      amount: false,
    });
    setInvestAmount({ ...investAmount, [e.target.name]: e.target.value });
  };

  const handleCapitalRequest = () => {
    setBackendError(null);
    setCapitalRequestLoader(true);
    if (investAmount?.amount === "") {
      setValidationErrors({
        amount: true,
      });
      message.error("Please enter an amount.");
      setCapitalRequestLoader(false);
    } else if (investAmount?.amount < TrancheRes?.tranche?.nominalValue) {
      setValidationErrors({
        amount: true,
      });
      message.error("Please enter amount more than nominal value");
      setCapitalRequestLoader(false);
    } else {
      setValidationErrors({
        amount: false,
      });
      const requestBody = {
        trancheUuid: TrancheRes?.tranche?.uuid,
        amount: investAmount?.amount,
      };
      CapitalCallRequest(requestBody)
        .then(async (commitInvestmentData) => {
          if (commitInvestmentData === "") {
            message.success("Request successfully added.");
            setInvestAmount({
              amount: "",
            });
            setCapitalRequestLoader(false);
            getCapitalCallRequestList();
          } else {
            if (
              commitInvestmentData?.fieldErrors?.amount ===
              "CAPITAL_CALL_EXCEEDS_TOTAL_INVESTMENTS"
            ) {
              setValidationErrors({
                amount: true,
              });
              setBackendError(commitInvestmentData?.fieldErrors?.amount);
              setCapitalRequestLoader(false);
            } else if (
              commitInvestmentData?.fieldErrors?.amount ===
              "NO_SPLIT_IN_DEBENTURE_AMOUNT"
            ) {
              setValidationErrors({
                amount: true,
              });
              setBackendError(commitInvestmentData?.fieldErrors?.amount);
              setCapitalRequestLoader(false);
            } else {
              setCapitalRequestLoader(false);
            }
          }
        })
        .catch((error) => {
          ErrorResponse(error?.code);
          setCapitalRequestLoader(false);
        });
    }
  };

  const getCapitalCallRequestList = () => {
    setCapitalRequestLoading(true);
    const requestBody = {
      trancheUuid: slug,
    };
    CapitalCallList(requestBody)
      .then(async (capitaRequestlist) => {
        if (Object.keys(capitaRequestlist)?.length > 0) {
          await setCapitalRequests(capitaRequestlist, dispatch);
          setCapitalRequestLoading(false);
        } else {
          setCapitalRequestLoading(false);
        }
      })
      .catch((error) => {
        ErrorResponse(error?.code);
        setCapitalRequestLoading(false);
      });
  };

  return (
    <Col className="gutter-row infomation-div" lg={24} md={24} sm={24} xs={24}>
      <p className="m-0 tranch-head">New Capital Call Request</p>

      <div
        className="mt-15 start-invest-div"
        style={{ display: "flex", alignItems: "start" }}
      >
        <div
          style={{ flex: 1 }}
          className={validationErrors.amount === true ? "error-border" : ""}
        >
          <div style={{ position: "relative" }}>
            <InputDefault
              value={investAmount?.amount}
              placeholder="Enter amount to withdraw"
              type="text"
              name="amount"
              onChange={handleChange}
              required={true}
              errorMsg={"Amount is Required"}
              style={{ backgroundColor: "#ffffff" }}
            />
            {validationErrors.amount === true && (
              <div className="borrower-warning-logo">
                <img src={Borrower_warning} alt="Borrower_warning" />
              </div>
            )}
          </div>
          {validationErrors.amount === true && investAmount.amount === "" ? (
            <span style={{ color: "red", fontSize: "12px" }}>
              Please enter an amount
            </span>
          ) : validationErrors.amount === true &&
            investAmount.amount !== "" &&
            investAmount?.amount < TrancheRes?.tranche?.nominalValue ? (
            <span style={{ color: "red", fontSize: "12px" }}>
              Please enter amount more than nominal value
            </span>
          ) : validationErrors.amount === true &&
            investAmount.amount !== "" &&
            backendError === "CAPITAL_CALL_EXCEEDS_TOTAL_INVESTMENTS" ? (
            <span style={{ color: "red", fontSize: "12px" }}>
              Capital call exceeds total funded investments
            </span>
          ) : validationErrors.amount === true &&
            investAmount.amount !== "" &&
            backendError === "NO_SPLIT_IN_DEBENTURE_AMOUNT" ? (
            <span style={{ color: "red", fontSize: "12px" }}>
              Amount cannot be split in debenture amounts
            </span>
          ) : null}
          <p className="capital-info-note mt-4 mb-0">
            Min. amount{" "}
            {formatCurrency(
              TrancheRes?.tranche?.currencyCode === "USD"
                ? "$"
                : TrancheRes?.tranche?.currencyCode === "SGD"
                ? "S$"
                : "€",
              TrancheRes?.tranche?.nominalValue
            )}{" "}
            , max{" "}
            {formatCurrency(
              TrancheRes?.tranche?.currencyCode === "USD"
                ? "$"
                : TrancheRes?.tranche?.currencyCode === "SGD"
                ? "S$"
                : "€",
              TrancheRes?.investment?.principalSettled
            )}
          </p>
        </div>
        <div>
          <ButtonDefault
            title="Request"
            className="invest-btn"
            loading={capitalRequestLoader}
            style={{ width: "100%" }}
            onClick={handleCapitalRequest}
          />
        </div>
      </div>
    </Col>
  );
};

export default StartCapitalCallRequest;
