import React from "react";
import CommonFilter from "./CommonFilter";

const FilterComponent = ({trancheCountry}) => {
  return (
    <div>
      <CommonFilter trancheCountry={trancheCountry}/>
    </div>
  );
};

export default FilterComponent;
