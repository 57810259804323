import React from "react";
import { Col, Row } from "antd";
import CalendarBlank from "../../../Assets/Images/Icons/Dashboard/CalendarBlank.svg";
import { britishFormatDate } from "../../../Utils/Helpers";
import { formatCurrency } from "../../../Utils/Reusables";

const WithdrawRequestCard = ({ request, index }) => {
  return (
    <Col xs={24} className="withdraw-card mt-8">
      <Row>
        <Col xs={12} lg={12} className="withraw-card-first-col mb-12">
          <div>
            <p className="m-0 withdraw-card-heading-data">
              <img src={CalendarBlank} alt="CalendarBlank" />{" "}
              {britishFormatDate(request.requestDate)}
            </p>
          </div>
        </Col>
        <Col xs={12} lg={12} className="withraw-card-sec-col mb-12">
          <div className="withdraw-status-card-div sb-text-align-end">
            {request.status === "PENDING" ? (
              <div className="withdraw-status-div">
                <span className="progress-circle-span"></span>
                <p className="progress-p m-0">In Progress</p>
              </div>
            ) : request.status === "SETTLED" ? (
              <div className="withdraw-status-div">
                <span className="settled-circle-span"></span>
                <p className="settled-p m-0">Settled</p>
              </div>
            ) : request.status === "EXPORTED" ? (
              <div className="withdraw-status-div">
                <span className="settled-circle-span"></span>
                <p className="settled-p m-0">Exported</p>
              </div>
            ) : (
              <div className="withdraw-status-div">
                <span className="settled-circle-span"></span>
                <p className="settled-p m-0">cancelled</p>
              </div>
            )}
          </div>
        </Col>
        <Col xs={12} lg={12}>
          <p className="mb-4 mt-0 card-info-tag">Amount</p>
        </Col>
        <Col xs={12} lg={12}>
          <p className="mb-4 mt-0 withdraw-card-tag">
            {formatCurrency("$", request?.amount)}
          </p>
        </Col>
        <Col xs={12} lg={12}>
          <p className="mb-4 mt-0 card-info-tag">Currency</p>
        </Col>
        <Col xs={12} lg={12}>
          <p className="mb-4 mt-0 withdraw-card-tag">{request.currencyCode}</p>
        </Col>
        {/* <Col xs={12} lg={12}>
          <p className="mb-12 mt-0 card-info-tag">Borrower</p>
        </Col>
        <Col xs={12} lg={12}>
          <p className="mb-12 mt-0 withdraw-card-tag">Fundbox Pte Ltd</p>
        </Col> */}
      </Row>
    </Col>
  );
};

export default WithdrawRequestCard;
