// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { config } from "../Config/config";
import { getStorage } from "@firebase/storage";

// Initialize Firebase
const app = initializeApp(config);
const analytics = getAnalytics(app);
const provider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");
const storage = getStorage(app);

const auth = getAuth(app);

export { auth, analytics, provider, appleProvider, storage };
