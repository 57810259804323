import { createSlice } from "@reduxjs/toolkit";

const walletInitialState = {
    bankAccount: {},
    withDrawRequest: [],

};

const walletSlice = createSlice({
    name: "wallet",
    initialState: walletInitialState,
    reducers: {
        setAccoount: (state, action) => {
            return { ...state, bankAccount: { ...action.payload } };
        },
        setWithdrawRequest: (state, action) => {
            return {
                ...state, withDrawRequest: [
                    ...action.payload
                ]
            };
        },
    },
});

export const { setAccoount, setWithdrawRequest } = walletSlice.actions;

export default walletSlice.reducer;
