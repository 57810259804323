/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Checkbox,
  Input,
  Modal,
  Progress,
  Row,
  Col,
  DatePicker,
  message,
} from "antd";

import InputDefault from "../../Components/InputDefault/InputDefault.jsx";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault.jsx";

import {
  RegisterSingpassApi,
  eventsApi,
  getSingPassUserData,
} from "../../Apis/UserApi.js";
import ROUTES from "../../Config/Routes.js";
import { validatePassword } from "../../Utils/Reusables.js";

import PrivacyPdf from "../../Assets/Pdf/Privacy-policy.pdf";
import SelectDefault from "../../Components/SelectDefault/SelectDefault.jsx";
import dayjs from "dayjs";
import {
  getCountries,
  getPasswordStrength,
  get_ga_clientid,
} from "../../Utils/Helpers.js";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import "../RegisterPage/style.css";
import Cookies from "js-cookie";
import AuthLayout from "../../Layouts/BlankHeaderLayout/AuthLayout.jsx";
import SingpassLoader from "./SingpassLoader.jsx";
import { PDF } from "../../Utils/Constant.js";
import { trackEvent } from "../../Utils/Analytics.js";

const SingpassRegisterPage = () => {
  const navigate = useNavigate();
  const [singPassUser, setSingPassUser] = useState();
  const singPassCode = sessionStorage.getItem("sCode");
  const [countryCode, setCountryCode] = useState("sg");
  const [mobileNumberClass, setMobileNumberClass] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const countryList = getCountries();

  useEffect(() => {
    const requestBody = {
      authCode: singPassCode,
      sid: Cookies.get("sid"),
    };
    if (singPassCode) {
      getSingPassUserData(requestBody)
        .then((userData) => {
          if (userData?.status === true) {
            setSingPassUser(userData?.data?.data_json);
            sessionStorage.setItem("singPassId", userData?.data?.id);
          } else {
            Cookies.remove("singpass_config");
            Cookies.remove("sid");
            navigate(ROUTES.REGISTER);
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, [singPassCode]);

  const [formData, setFormData] = useState({
    firstName: "",
    marriedName: "",
    sex: "Male",
    dob: null,
    nricFin: "",
    countryOfBirth: "",
    nationality: "",
    passExDate: "",
    registeredAddress: "",
    assemleIncome: "",
    phoneNumber: "",
    email: "",
    password: "",
    cPassword: "",
    referralCode: "",
  });

  useEffect(() => {
    if (singPassUser !== undefined) {
      setCountryCode(singPassUser?.mobileno?.areacode?.value);
      setFormData({
        ...formData,
        firstName: singPassUser?.name?.value,
        marriedName: singPassUser?.marital?.desc,
        sex: singPassUser?.sex?.desc,
        dob: new Date(singPassUser?.dob?.value),
        nricFin: singPassUser?.uinfin?.value,
        countryOfBirth: singPassUser?.regadd?.country?.desc,
        nationality: singPassUser?.nationality?.desc,
        registeredAddress: singPassUser?.regadd?.street?.value,
        phoneNumber:
          "+" +
          singPassUser?.mobileno?.areacode?.value +
          singPassUser?.mobileno?.nbr?.value,
        email: singPassUser?.email?.value,
      });
    }
  }, [singPassUser]);
  const strength = getPasswordStrength(formData?.password);
  // Validation status flags
  const [validationErrors, setValidationErrors] = useState({
    firstName: false,
    marriedName: false,
    sex: false,
    dob: false,
    nricFin: false,
    countryOfBirth: false,
    nationality: false,
    passExDate: false,
    registeredAddress: false,
    assemleIncome: false,
    phoneNumber: false,
    email: false,
    password: false,
    cPassword: false,
    referralCode: false,
  });
  const [loader, setLoader] = useState(false);
  const [noPass, setNoPass] = useState(false);
  const [cPassErr, setCPassErr] = useState(false);
  const [checked, setChecked] = useState(false);
  const [noCheckErr, setCheckErr] = useState(false);

  const [termsPdf, setTermsPdf] = useState(false);
  const [privacyPdf, setPrivacyPdf] = useState(false);
  const [referralCodeValid, setReferralCodeValid] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const handlePhoneChange = async (value, country) => {
    setCountryCode(country.dialCode);
    const number = value.substring(country.dialCode.length).trim();

    const isValueValid = value.trim() === "";
    setFormData((prevAddress) => ({
      ...prevAddress,
      phoneNumber: "+" + value,
    }));

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      phoneNumber: isValueValid,
    }));

    if (number !== "") {
      setMobileNumberClass("");
    } else {
      setMobileNumberClass("sb-phone-empty");
    }
  };

  const handleBlur = () => {
    const number = formData.phoneNumber.substring(countryCode.length).trim();
    if (number !== "") {
      setMobileNumberClass("");
    } else {
      setMobileNumberClass("sb-phone-empty");
    }
  };

  useEffect(() => {
    if (validationErrors?.phoneNumber === true) {
      setMobileNumberClass("sb-phone-empty");
    }
    if (formData?.phoneNumber) {
      setMobileNumberClass("");
    }
  }, [validationErrors?.phoneNumber]);

  const HandleCreateUser = async () => {
    setLoader(true);
    setValidationErrors({
      email: true,
      password: true,
      cPassword: true,
    });

    if (checked === false) {
      setCheckErr(true);
      setLoader(false);
    }

    if (!formData?.password || !validatePassword(formData?.password)) {
      setLoader(false);
      return setNoPass(true);
    } else if (formData?.cPassword !== formData?.password) {
      setLoader(false);
      return setCPassErr(true);
    }

    if (formData?.email && checked === true) {
      let data;
      if (
        formData?.referralCode === null ||
        formData?.referralCode === undefined ||
        formData?.referralCode === ""
      ) {
        data = {
          myInfoReference: Cookies.get("sid"),
          email: formData?.email,
          mobilePhone: formData?.phoneNumber,
          password: formData?.password,
          passwordRepeat: formData?.cPassword,
          acceptTermsOfUse: true,
          acceptPrivacyPolicy: true,
          gaClientId: get_ga_clientid(),
          url: `${window.location.origin}`,
          // singpass_id
        };
      } else {
        data = {
          myInfoReference: Cookies.get("sid"),
          email: formData?.email,
          mobilePhone: formData?.phoneNumber,
          password: formData?.password,
          passwordRepeat: formData?.cPassword,
          acceptTermsOfUse: true,
          acceptPrivacyPolicy: true,
          gaClientId: get_ga_clientid(),
          url: `${window.location.origin}`,
          referralCode: formData?.referralCode,
        };
      }
      RegisterSingpassApi(data)
        .then(async (response) => {
          if (response?.token) {
            trackEvent("Investor: registered");
            Cookies.set("auth_inv_token", response?.token);
            setLoader(false);
            navigate(ROUTES.EMAIL_VERIFICATION);
            message.success("We have sent verification email please check!");
            const eventData = {
              gaClientId: get_ga_clientid(),
              action: "potentialInvestor",
              category: "registration",
            };
            eventsApi(eventData);
          } else {
            if (Object.values(response?.fieldErrors).length > 0) {
              setLoader(false);
              if (
                response?.fieldErrors?.referralCode !== undefined &&
                response?.fieldErrors?.email !== undefined
              ) {
                setReferralCodeValid(true);
                setEmailValid(true);
              }
              if (response?.fieldErrors?.referralCode) {
                setReferralCodeValid(true);
              } else if (response?.fieldErrors?.email) {
                setEmailValid(true);
              }
            }
          }
        })
        .catch((error) => {
          console.error("Error during login:", error);
          setLoader(false);
        });
    }
  };

  const handleOpenTermsPdf = () => {
    setTermsPdf(true);
  };
  const handleCloseTermsPdf = () => {
    setTermsPdf(false);
  };

  const isDisabled = singPassUser === undefined ? false : true;

  return (
    <AuthLayout>
      {singPassUser !== undefined ? (
        <div className="sb-onboarding-form-container">
          <h2 className="sb-text-align-start m-0 fw-600">
            Create account with Singpass
          </h2>
          <h3 className="sb-text-align-start fw-600">Information</h3>
          <div className="sb-login-form">
            <Row gutter={16}>
              <Col
                className="gutter-row mb-10"
                xs={{ span: 24 }}
                md={{ span: 12 }}
              >
                <div>
                  <InputDefault
                    disabled={isDisabled}
                    value={formData?.firstName}
                    name="firstName"
                    type="input"
                    validationState={setValidationErrors}
                    focusing={validationErrors?.firstName}
                    onChange={({ target }) =>
                      setFormData({ ...formData, firstName: target.value })
                    }
                    required={true}
                    errorMsg={"First name is required"}
                  />
                </div>
              </Col>
              <Col
                className="gutter-row mb-10"
                xs={{ span: 24 }}
                md={{ span: 12 }}
              >
                <div>
                  <InputDefault
                    disabled={isDisabled}
                    value={formData?.marriedName}
                    name="marriedName"
                    type="input"
                    validationState={setValidationErrors}
                    focusing={validationErrors?.marriedName}
                    onChange={({ target }) =>
                      setFormData({ ...formData, marriedName: target.value })
                    }
                    required={true}
                    errorMsg={"marriedName is required"}
                  />
                </div>
              </Col>
              <Col
                className="gutter-row mb-10"
                xs={{ span: 12 }}
                md={{ span: 8 }}
              >
                <div>
                  <SelectDefault
                    disabled={isDisabled}
                    value={formData?.sex}
                    defaultValue={formData?.sex}
                    validationState={validationErrors?.sex}
                    MyValue={formData?.sex}
                    placeholder="Select a gender"
                    data={[
                      { key: "MALE", value: "MALE" },
                      { key: "FEMALE", value: "FEMALE" },
                    ]}
                    onChange={(value, key) => {
                      setFormData({ ...formData, sex: key?.value });
                    }}
                    required={true}
                    errorMsg={"Please select a gender."}
                  />
                </div>
              </Col>
              <Col
                className="gutter-row mb-10"
                xs={{ span: 12 }}
                md={{ span: 8 }}
              >
                <div>
                  <DatePicker
                    disabled={isDisabled}
                    style={{ width: "100%" }}
                    className="sb-input singpass-datepicker"
                    focusing={validationErrors?.dob}
                    validationState={setValidationErrors}
                    placeholder="DD/MM/YYYY"
                    onChange={(date, dateString) => {
                      const formattedDate = date
                        ? dayjs(date).format("DD/MM/YYYY")
                        : null;
                      setFormData({ ...formData, dob: formattedDate });
                    }}
                    name="dob"
                    required={true}
                    errorMsg={"Enter Date of birth"}
                    value={
                      formData?.dob ? dayjs(formData.dob, "DD/MM/YYYY") : null
                    }
                    disabledDate={disabledDate}
                    format="DD/MM/YYYY"
                  />
                </div>
              </Col>
              <Col
                className="gutter-row mb-10"
                xs={{ span: 12 }}
                md={{ span: 8 }}
              >
                <div>
                  <InputDefault
                    disabled={isDisabled}
                    value={formData?.nricFin}
                    name="nricFin"
                    type="input"
                    validationState={setValidationErrors}
                    focusing={validationErrors?.nricFin}
                    onChange={({ target }) =>
                      setFormData({ ...formData, nricFin: target.value })
                    }
                    required={true}
                    errorMsg={"nricFin is required"}
                  />
                </div>
              </Col>
              <Col
                className="gutter-row mb-10"
                xs={{ span: 12 }}
                md={{ span: 8 }}
              >
                <div>
                  <SelectDefault
                    disabled={isDisabled}
                    defaultValue={formData?.countryOfBirth}
                    validationState={validationErrors?.countryOfBirth}
                    MyValue={formData?.countryOfBirth}
                    data={countryList}
                    placeholder="Select country"
                    onChange={(value, key) => {
                      setFormData({
                        ...formData,
                        countryOfBirth: key?.value,
                      });
                    }}
                    value={formData?.countryOfBirth}
                    required={true}
                    errorMsg={"Enter country of birth"}
                  />
                </div>
              </Col>
              <Col
                className="gutter-row mb-10"
                xs={{ span: 12 }}
                md={{ span: 8 }}
              >
                <div>
                  <SelectDefault
                    disabled={isDisabled}
                    value={formData?.nationality}
                    defaultValue={formData?.nationality}
                    validationState={validationErrors?.nationality}
                    MyValue={formData?.nationality}
                    data={countryList}
                    placeholder="Select country"
                    onChange={(value, key) => {
                      setFormData({
                        ...formData,
                        nationality: key?.value,
                      });
                    }}
                    required={true}
                    errorMsg={"Select Nationality"}
                  />
                </div>
              </Col>
              <Col
                className="gutter-row mb-10"
                xs={{ span: 12 }}
                md={{ span: 8 }}
              >
                <div>
                  <InputDefault
                    disabled={isDisabled}
                    name="marriedName"
                    placeholder="Residential status"
                    type="input"
                    validationState={setValidationErrors}
                    focusing={validationErrors?.marriedName}
                    onChange={({ target }) =>
                      setFormData({ ...formData, marriedName: target.value })
                    }
                    required={true}
                    errorMsg={"marriedName is required"}
                  />
                </div>
              </Col>
              <Col
                className="gutter-row mb-10"
                md={{ span: 12 }}
                xs={{ span: 24 }}
              >
                <div>
                  <InputDefault
                    disabled={isDisabled}
                    name="marriedName"
                    placeholder="Pass type"
                    type="input"
                    validationState={setValidationErrors}
                    focusing={validationErrors?.marriedName}
                    onChange={({ target }) =>
                      setFormData({ ...formData, marriedName: target.value })
                    }
                    required={true}
                    errorMsg={"marriedName is required"}
                  />
                </div>
              </Col>
              {/* <Col className="gutter-row mb-10" span={12}>
                <div>
                  <label>Pass expiration date</label>
                  <DatePicker
                    style={{ width: 240 }}
                    className="sb-input"
                    focusing={validationErrors?.passExDate}
                    validationState={setValidationErrors}
                    placeholder="YYYY/MM/DD"
                    onChange={(value, date) => {
                      setFormData({
                        ...formData,
                        passExDate: dayjs(date).format("YYYY-MM-DD"),
                      });
                    }}
                    name="dob"
                    required={true}
                    errorMsg={"Enter Passport Expiry Date"}
                    value={
                      formData?.passExDate ? dayjs(formData?.passExDate) : null
                    }
                  />
                </div>
              </Col> */}
              <Col className="gutter-row mb-10" span={24}>
                <div>
                  {/* <label>Registered address</label> */}
                  <InputDefault
                    disabled={isDisabled}
                    value={formData?.registeredAddress}
                    name="registeredAddress"
                    placeholder="Registered address"
                    type="input"
                    validationState={setValidationErrors}
                    focusing={validationErrors?.registeredAddress}
                    onChange={({ target }) =>
                      setFormData({
                        ...formData,
                        registeredAddress: target.value,
                      })
                    }
                    required={true}
                    errorMsg={"registeredAddress is required"}
                  />
                </div>
              </Col>
              <Col className="gutter-row mb-10" span={24}>
                <div>
                  <InputDefault
                    disabled={isDisabled}
                    name="assemleIncome"
                    placeholder="Assemle Income"
                    type="input"
                    validationState={setValidationErrors}
                    focusing={validationErrors?.assemleIncome}
                    onChange={({ target }) =>
                      setFormData({ ...formData, assemleIncome: target.value })
                    }
                    required={true}
                    errorMsg={"assemle Income is required"}
                  />
                </div>
              </Col>
              <Col className="gutter-row mb-10" span={24}>
                {formData?.phoneNumber ? (
                  <PhoneInput
                    className={`sb-phone-field ${mobileNumberClass}`}
                    value={formData && formData?.phoneNumber}
                    onChange={handlePhoneChange}
                  />
                ) : (
                  <div>
                    <PhoneInput
                      className={`sb-phone-field ${mobileNumberClass}`}
                      placeholder="+91 12345-67890"
                      country={countryCode && countryCode}
                      value={formData && formData?.phoneNumber}
                      onChange={handlePhoneChange}
                      enableSearch
                      onBlur={handleBlur}
                    />
                    <span className="phone-error-msg">
                      {mobileNumberClass === "sb-phone-empty"
                        ? "Please enter mobile number"
                        : ""}
                    </span>
                  </div>
                )}
              </Col>
              <Col className="gutter-row mb-10" span={24}>
                <div className={emailValid === true ? "referral-error" : ""}>
                  <InputDefault
                    value={formData?.email}
                    name="email"
                    placeholder="Enter email"
                    type="input"
                    validationState={setValidationErrors}
                    focusing={validationErrors?.email}
                    onChange={({ target }) => {
                      setFormData({ ...formData, email: target.value });
                      setEmailValid(false);
                    }}
                    required={true}
                    errorMsg={"email is required"}
                  />
                </div>
              </Col>
              <Col className="gutter-row" span={12}>
                <div>
                  <Input.Password
                    name="password"
                    placeholder="************"
                    style={{ height: 40, borderRadius: "12px" }}
                    type="password"
                    onChange={({ target }) => {
                      setFormData({ ...formData, password: target.value });
                      if (noPass) {
                        if (validatePassword(formData?.password) === true) {
                          setNoPass(false);
                        } else {
                          setNoPass(true);
                        }
                      }
                    }}
                    status={noPass && "error"}
                    required={true}
                  />
                  <Progress percent={strength} size="small" showInfo={false} />
                  {noPass && (
                    <label
                      className="error-msg"
                      style={{
                        display: "block",
                        marginTop: "-8px",
                        marginBottom: 12,
                      }}
                    >
                      Use atleast 10 characters, 1 uppercase, lowecase, special
                      character and number in your password
                    </label>
                  )}
                </div>
              </Col>
              <Col className="gutter-row" span={12}>
                <div>
                  <Input.Password
                    name="password"
                    placeholder="************"
                    type="password"
                    style={{ height: 40, borderRadius: "12px" }}
                    validationState={setValidationErrors}
                    focusing={validationErrors?.cPassword}
                    onChange={({ target }) => {
                      setCPassErr(false);
                      setFormData({ ...formData, cPassword: target.value });
                      if (target.value !== formData?.password) {
                        setCPassErr(true);
                        setValidationErrors({
                          ...validationErrors,
                          cPassword: false,
                        });
                      }
                    }}
                    status={cPassErr && "error"}
                    onBlur={() => {
                      setCPassErr(false);
                      if (formData?.cPassword !== formData?.password) {
                        setCPassErr(true);
                      }
                    }}
                    required={true}
                  />
                  {cPassErr && (
                    <label
                      className="error-msg"
                      style={{ display: "block", marginTop: "1px" }}
                    >
                      Passwords don't match!
                    </label>
                  )}
                </div>
              </Col>
              <Col className="gutter-row mb-10" span={12}>
                <div className={referralCodeValid === true && "referral-error"}>
                  <InputDefault
                    name="ReferralCode"
                    placeholder="Referral code"
                    type="input"
                    validationState={setValidationErrors}
                    focusing={validationErrors?.referralCode}
                    onChange={({ target }) => {
                      setFormData({ ...formData, referralCode: target.value });
                      setReferralCodeValid(false);
                    }}
                    required={false}
                  />
                </div>
              </Col>
              <Col className="gutter-row mb-10" span={24}>
                <div className="sb-text-align-start">
                  <Checkbox
                    className="checkbox-kilde"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCheckErr(false);
                      } else {
                        setCheckErr(true);
                      }
                      setChecked(e.target.checked);
                    }}
                    style={{ marginRight: 5 }}
                  />{" "}
                  I accept{" "}
                  <span
                    className="cursor-pointer"
                    style={{ color: "var(--kilde-blue)" }}
                    onClick={() => setPrivacyPdf(true)}
                  >
                    Terms of use
                  </span>{" "}
                  and
                  <span
                    className="cursor-pointer"
                    style={{ color: "var(--kilde-blue)" }}
                    onClick={handleOpenTermsPdf}
                  >
                    {" "}
                    Privacy Policy
                  </span>
                </div>
                {noCheckErr && (
                  <label
                    className="error-msg"
                    style={{
                      display: "block",
                      marginTop: "-8px",
                      marginBottom: 12,
                    }}
                  >
                    Please accept Privacy Policy.
                  </label>
                )}
              </Col>
            </Row>
          </div>
          <div>
            <div className="mb-20 mt-10">
              <ButtonDefault
                title="Create account"
                onClick={HandleCreateUser}
                loading={loader}
                style={{ width: "100%" }}
              />
            </div>
            <div>
              <p>
                Already have an account?
                <Link
                  to={ROUTES.LOGIN}
                  className="cursor-pointer text-decoration-none   ml-5"
                  style={{ color: "var(--kilde-blue)" }}
                >
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <SingpassLoader />
      )}

      {/* Privacy PDF Modal */}
      <Modal
        className="sb-pdf-modal"
        centered
        open={termsPdf}
        onCancel={handleCloseTermsPdf}
        width={1000}
        footer={null}
      >
        <iframe
          className="mt-20"
          src={`${PrivacyPdf}#toolbar=0`}
          width="100%"
          height="500px"
          title="PDF Viewer"
        />
      </Modal>
      {/* Terms of use PDF Modal */}
      <Modal
        className="sb-pdf-modal"
        centered
        open={privacyPdf}
        onCancel={() => setPrivacyPdf(false)}
        width={1000}
        footer={null}
      >
        <iframe
          className="mt-20"
          src={`${PDF.TERM_OF_USE}#toolbar=0`}
          width="100%"
          height="500px"
          title="PDF Viewer"
        />
      </Modal>
    </AuthLayout>
  );
};

export default SingpassRegisterPage;
