import React, { useEffect } from "react";
import DashboardLayout from "../../Layouts/DashboardLayout/DashboardLayout";
import { Button, Col, Layout, Row } from "antd";
import "./style.css";
import Arrow from "../../Assets/Images/arrow.svg";
import InputDefault from "../../Components/InputDefault/InputDefault";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "../../Config/Routes";
import { checkStepStatus } from "../../Utils/Helpers";
import { getUser } from "../../Apis/UserApi";
import { setUserDetails } from "../../Redux/Action/User";
import AdditionalDoc from "../../Assets/Images/IconSetDoc.svg";
import TwoFAEnable from "../../Assets/Images/IconSet.svg";

import TwoFADisable from "../../Assets/Images/IconSetRed.svg";
import changePW from "../../Assets/Images/Key.svg";
import viewOB from "../../Assets/Images/viewOB.svg";

const { Content } = Layout;
const Settings = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserDetails = async () => {
    try {
      const response = await getUser();
      if (response) {
        await setUserDetails(response, dispatch);
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  const handleViewOnboarding = () => {
    if (
      checkStepStatus(user?.waitingVerificationSteps, "QUESTIONNAIRE") === false
    ) {
      if (user?.investorType === "INDIVIDUAL") {
        navigate(ROUTES.INDIVIDUAL_VERIFICATION);
      } else {
        navigate(ROUTES.ORGANIZATION_VERIFICATION);
      }
    } else {
      navigate(ROUTES.VERIFICATION);
    }
  };

  return (
    <div>
      <DashboardLayout>
        <Content className="setting-page-div">
          <p className="mt-0 setting-head">Personal Settings</p>

          <Row
            gutter={window.innerWidth >= 768 ? 16 : 0}
            style={{ display: "flex" }}
          >
            <Col
              xs={24}
              sm={24}
              md={14}
              lg={14}
              className="gutter-row normal-padd-right"
            >
              <div className="infomation-profile-div">
                <p className="mt-0 tranch-head mb-20">Account Information</p>
                <Row gutter={16} style={{ flex: 1 }}>
                  <Col className="gutter-row mb-16" md={12} sm={24} xs={24}>
                    <label>First Name</label>
                    <InputDefault
                      type="text"
                      style={{ width: "100%" }}
                      name="firstName"
                      required={true}
                      errorMsg={"Enter First Name"}
                      value={user?.firstName}
                      disabled="true"
                    />
                  </Col>
                  <Col className="gutter-row mb-16" md={12} sm={24} xs={24}>
                    <div>
                      <label>Last Name</label>
                      <InputDefault
                        type="text"
                        style={{ width: "100%" }}
                        name="lastName"
                        required={true}
                        errorMsg={"Enter Last Name"}
                        value={user?.lastName}
                        disabled="true"
                      />
                    </div>
                  </Col>
                  <Col className="gutter-row mb-16" md={12} sm={24} xs={24}>
                    <label>Mobile Number</label>
                    <PhoneInput
                      className="sb-phone-field"
                      value={user?.mobilePhone}
                      enableSearch
                      disabled="true"
                      placeholder=""
                    />
                  </Col>
                  <Col className="gutter-row mb-16" md={12} sm={24} xs={24}>
                    <div>
                      <label>Email</label>
                      <InputDefault
                        type="text"
                        style={{ width: "100%" }}
                        name="Email"
                        required={true}
                        value={user?.email}
                        disabled="true"
                      />
                    </div>
                  </Col>
                  <Col className="gutter-row" md={12} sm={24} xs={24}>
                    <div>
                      <label>Account number</label>
                      <InputDefault
                        type="text"
                        style={{ width: "100%" }}
                        name="Email"
                        required={true}
                        value={user?.number}
                        disabled="true"
                      />
                    </div>
                  </Col>
                </Row>
                <div className="setting-acc-info-div mt-20">
                  <p className="mb-0">
                    To change your account information, contact us at{" "}
                    <span>
                      <Link to="mailto:sales@kilde.sg">sales@kilde.sg</Link>
                    </span>
                  </p>
                </div>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={10}
              lg={10}
              className="gutter-row medium-tranch-col"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Row
                gutter={window.innerWidth >= 576 ? 16 : 0}
                style={{ flex: 1, justifyContent: "space-around" }}
              >
                <Col
                  className="gutter-row infomation-profile-div media-margin"
                  md={24}
                  sm={11}
                  xs={24}
                >
                  <p className="mt-0 tranch-head mb-16">
                    Two-factor authentication
                  </p>
                  {user?.registrationType !== "GOOGLE" && (
                    <Button
                      className="setting-btn"
                      onClick={() => navigate(ROUTES.CHANGE_PASSWORD)}
                    >
                      <img src={changePW} alt="changePW" />
                      Change password
                      <img src={Arrow} alt="arrow" />
                    </Button>
                  )}
                  {user?.secondFactorAuth === null ? (
                    <div>
                      <Button
                        className="setting-btn"
                        onClick={() => navigate(ROUTES.TWO_FACTOR_AUTH)}
                      >
                        <img src={TwoFADisable} alt="2FA" />
                        Two-factor autentication
                        <img src={Arrow} alt="arrow" />
                      </Button>
                      <p className="twofa-p-disable">
                        Your account security is not fully protected. Please
                        enable two-factor authentication immediately to protect
                        your investments.
                      </p>
                    </div>
                  ) : (
                    <div>
                      <Button
                        className="setting-btn"
                        onClick={() => navigate(ROUTES.TWO_FACTOR_AUTH)}
                      >
                        <img src={TwoFAEnable} alt="2FA" />
                        Two-factor autentication
                        <img src={Arrow} alt="arrow" />
                      </Button>
                      <p className="twofa-p-enable">
                        Your account security is high.
                      </p>
                    </div>
                  )}
                </Col>
                <Col
                  className="gutter-row infomation-profile-div"
                  md={24}
                  sm={11}
                  xs={24}
                >
                  <p className="mt-0 tranch-head mb-16">Other</p>
                  <Button
                    className="setting-btn"
                    onClick={() => navigate(ROUTES.ADDITIONAL_DOCUMENT)}
                  >
                    <img src={AdditionalDoc} alt="document" />
                    Additional documents
                    <img src={Arrow} alt="arrow" />
                  </Button>
                  {user?.investorStatus === "ACTIVE" &&
                    user?.newUser === true && (
                      <Button
                        className="setting-btn"
                        onClick={handleViewOnboarding}
                      >
                        <img src={viewOB} alt="onboarding" />
                        View Onboarding
                        <img src={Arrow} alt="arrow" />
                      </Button>
                    )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </DashboardLayout>
    </div>
  );
};

export default Settings;
