import React from "react";

import { Col, Row } from "antd";
import "./style.css";
import { formatCurrency } from "../../../Utils/Reusables";

const InvestmentCard = ({ dashboardData }) => {
  return (
    <Row>
      <Col xs={18} sm={24} md={24} lg={24} className="mb-16">
        <p className="m-0 tranch-head">Investments</p>
      </Col>

      <Col xs={12} lg={12} className="mb-8">
        <p className="m-0 add-doc-setting">Current</p>
      </Col>
      <Col xs={12} lg={12} className="mb-8">
        <div className="profit-val-detail">
          <p className="m-0 card-profit-val-tag">
            {formatCurrency(
              dashboardData?.investorSummary?.currencySymbol,
              dashboardData?.investorSummary?.investmentsCurrent
            )}
          </p>
        </div>
      </Col>
      <Col xs={12} lg={12} className="mb-8">
        <p className="m-0 add-doc-setting">Past due (1-15)</p>
      </Col>
      <Col xs={12} lg={12} className="mb-8">
        <div className="profit-val-detail">
          <p className="m-0 card-profit-val-tag">
            {formatCurrency(
              dashboardData?.investorSummary?.currencySymbol,
              dashboardData?.investorSummary?.investmentsPastDue15
            )}
          </p>
        </div>
      </Col>
      <Col xs={12} lg={12} className="mb-8">
        <p className="m-0 add-doc-setting">Past due (16-30)</p>
      </Col>
      <Col xs={12} lg={12} className="mb-8">
        <div className="profit-val-detail">
          <p className="m-0 card-profit-val-tag">
            {formatCurrency(
              dashboardData?.investorSummary?.currencySymbol,
              dashboardData?.investorSummary?.investmentsPastDue30
            )}
          </p>
        </div>
      </Col>
      <Col xs={12} lg={12} className="mb-8">
        <p className="m-0 add-doc-setting">Past due (31-60)</p>
      </Col>
      <Col xs={12} lg={12} className="mb-8">
        <div className="profit-val-detail">
          <p className="m-0 card-profit-val-tag">
            {formatCurrency(
              dashboardData?.investorSummary?.currencySymbol,
              dashboardData?.investorSummary?.investmentsPastDue60
            )}
          </p>
        </div>
      </Col>
      <Col xs={12} lg={12} className="mb-8">
        <p className="m-0 add-doc-setting">Past due (61+)</p>
      </Col>
      <Col xs={12} lg={12} className="mb-8">
        <div className="profit-val-detail">
          <p className="m-0 card-profit-val-tag">
            {formatCurrency(
              dashboardData?.investorSummary?.currencySymbol,
              dashboardData?.investorSummary?.investmentsDefault
            )}
          </p>
        </div>
      </Col>
      <Col xs={12} lg={12} className="mb-16">
        <p className="m-0 profit-total-income-tag">Total</p>
      </Col>
      <Col xs={12} lg={12} className="mb-16">
        <div className="profit-val-detail">
          <p className="m-0 profit-total-income-val-tag">
            {formatCurrency(
              dashboardData?.investorSummary?.currencySymbol,
              dashboardData?.investorSummary?.investments
            )}
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default InvestmentCard;
