import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../Config/Routes";
import { auth } from "../../Utils/firebase";
import "firebase/auth";
import { applyActionCode } from "firebase/auth";

const VerifyPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const currentUrl = window.location.href;

    // Parse the URLSearchParams from the URL
    const urlSearchParams = new URLSearchParams(new URL(currentUrl).search);
    const mode = urlSearchParams.get("mode");
    const oobCode = urlSearchParams.get("oobCode");
    const apiKey = urlSearchParams.get("apiKey");
    if (mode === "verifyEmail") {
      navigate(ROUTES.EMAIL_VERIFIED + `?oobCode=${oobCode}`);
    } else {
      navigate(ROUTES.FORGOT_PASSWORD + `?oobCode=${oobCode}&apiKey=${apiKey}`);
    }

    if (oobCode) {
      const user = auth.currentUser;
      applyActionCode(auth, oobCode)
        .then(async () => {
          await user.updateEmailVerified(true);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [navigate]);
};

export default VerifyPage;
