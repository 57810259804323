/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEntityDocumentDetails } from "../../../Redux/Action/KycOrganization";
import { Select, Row, Col, Upload, Modal, message, Spin } from "antd";
import {
  LoadingOutlined,
  UploadOutlined,
  EyeOutlined,
  DeleteOutlined,
  FileOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import DocumentModal from "../../KYCStepperOrganizationPage/EntityDocuments/DocumentModal";
import ArrowUpAndDownIcon from "../../../Assets/Images/SVGs/ArrowLineUpDown.svg";

import "./style.css";
import { checkStepStatus } from "../../../Utils/Helpers";
import { INVESTOR_TYPE } from "../../../Utils/Constant";
import { getUser } from "../../../Apis/UserApi";
import { setUserDetails } from "../../../Redux/Action/User";
import {
  getInvestorQuestionnaire,
  getRerefenceProofOfAccrediationApi,
} from "../../../Apis/InvestorApi";
import GlobalVariabels from "../../../Utils/GlobalVariabels";

const EntityDocuments = () => {
  const { Option } = Select;
  const viewIcon = <EyeOutlined />;
  const removeIcon = <DeleteOutlined />;
  const user = useSelector((state) => state.user);
  const [questionnaire, setQuestionnaire] = useState();
  const [getPoiLoader, setPoiLoader] = useState(false);

  const dispatch = useDispatch();
  const [documentObject, setDocumentObject] = useState(
    questionnaire?.investorSubType === INVESTOR_TYPE.ACCREDITED_INVESTOR
      ? {
          PaySlips: { loading: false },
        }
      : { letterEmployement: { loading: false } }
  );
  const [documentUrls, setDocumentUrls] = useState(
    questionnaire?.investorSubType === INVESTOR_TYPE.ACCREDITED_INVESTOR
      ? {
          PaySlips: "",
        }
      : { letterEmployement: "" }
  );
  const [documentNames, setDocumentNames] = useState([]);
  const [simplifiedDocumentObject, setSimplifiedDocumentObject] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [hoverStates, setHoverStates] = useState({});
  const [finalImagesArray, setFinalImagesArray] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageUrlModal, setSelectedImageUrlModal] = useState({});
  const [allReferences, setAllrefernces] = useState();
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState([]);
  const [title, setTitle] = useState("");

  let data;

  if (questionnaire?.investorSubType === INVESTOR_TYPE.ACCREDITED_INVESTOR) {
    data = [
      {
        key: "PaySlips",
        value: "Pay slips/Tax declaration",
      },
      { key: "BankStatements", value: "Bank statements" },
      { key: "InvestmentStatements", value: "Investment statements" },
      {
        key: "InvestmentLifePolicyStatement",
        value: "Investment life policy statement",
      },
      { key: "FinancialStatement", value: "Financial statement" },
      {
        key: "ExcerptRegistry",
        value: "Excerpt form land/property registry",
      },
      {
        key: "LetterOfEmployment",
        value: "Letter of employment",
      },
      {
        key: "CapitalMarketProductLicense",
        value: "Capital market products license",
      },
    ];
  } else {
    data = [
      {
        key: "letterEmployement",
        value: "letter of employement",
      },
      {
        key: "capitalMarketProductLicense",
        value: "capital market products license",
      },
    ];
  }

  const getUserDetails = async () => {
    const response = await getUser();
    if (response) {
      await setUserDetails(response, dispatch);
    } else {
      console.error("Error fetching data:");
    }
  };

  useEffect(() => {
    getUserDetails();
    if (
      checkStepStatus(
        user?.waitingVerificationSteps,
        "PROOF_OF_ACCREDITATION"
      ) === false
    ) {
      setPoiLoader(true);
      getRerefenceProofOfAccrediationApi()
        .then((refrencesResponse) => {
          setAllrefernces(refrencesResponse);
          setPoiLoader(false);
        })
        .catch((error) => {
          setPoiLoader(false);
          console.log("error", error);
        });
    }
  }, []);

  useEffect(() => {
    if (allReferences) {
      const newDocumentNames = [];
      let newImagesArray = [];

      allReferences.forEach((data) => {
        const name = addUnderscoreBeforeText(data?.fileName);
        newDocumentNames.push(name);
        newImagesArray.push(data);
      });
      setDocumentNames(newDocumentNames);
      setFinalImagesArray(newImagesArray);
    }
  }, [allReferences]);

  useEffect(() => {
    if (finalImagesArray.length > 0) {
      const imageInfoArray = [];

      const imagePromises = finalImagesArray.map(async (item) => {
        try {
          const url = await `${GlobalVariabels.VIEW_IMG}/${item.fileReference}`;
          imageInfoArray.push({ name: item.fileName, url });
        } catch (error) {
          console.error("Error fetching image URL:", error);
        }
      });

      Promise.all(imagePromises)
        .then(() => {
          setImageUrls(imageInfoArray);
        })
        .catch((error) => {
          console.error("Error fetching image URLs:", error);
        });
    }
  }, [finalImagesArray]);

  useEffect(() => {
    simplifiedDocumentObject?.forEach((selectedValue) => {
      if (!documentObject.hasOwnProperty(selectedValue)) {
        const newDocumentObject = {
          ...documentObject,
          [selectedValue]: { loading: false, file: null },
        };
        const newDocumentUrls = {
          ...documentUrls,
          [selectedValue]: "",
        };

        // Update the state with the new objects
        setDocumentObject(newDocumentObject);
        setDocumentUrls(newDocumentUrls);
      }
    });
  }, [documentObject, documentUrls, simplifiedDocumentObject]);

  function addUnderscoreBeforeText(sentence) {
    const parts = sentence.split("_");
    if (parts.length > 1) {
      return parts[0];
    } else {
      // If there's no underscore, just return the original sentence
      return sentence;
    }
  }

  const handleChange = (value) => {
    setSelectedDocumentTypes(value);
    setSimplifiedDocumentObject(value);
    const newDocumentObject = { ...documentObject };

    for (const key in newDocumentObject) {
      if (!value?.includes(key)) {
        delete newDocumentObject[key];
      }
    }

    // Update the state with the modified object
    setDocumentObject(newDocumentObject);
  };

  useEffect(() => {
    setEntityDocumentDetails(documentObject, dispatch);
  }, [dispatch, documentObject]);

  const handleCustomRequest = ({ file, onSuccess, onError }) => {
    setTimeout(() => {
      onSuccess();
    }, 1000);
  };

  const uploadButton = (documentType) => (
    <div className="sb-document-upload">
      <span className="sb-fileUpload-icon">
        {documentObject[documentType].loading ? (
          <LoadingOutlined />
        ) : (
          <UploadOutlined />
        )}
      </span>
      <div>
        <span className="sb-file-span">
          Click to browse or drop here JPG, JPEG, PNG, BMP, PDF or ZIP
        </span>
      </div>
    </div>
  );

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const allowedFileTypes = [
    "image/png",
    "image/jpeg",
    "image/bmp",
    "application/pdf",
    "application/zip",
  ];

  const handleFileChange = (info, documentType) => {
    setTitle(info?.file?.name);
    if (info?.file?.size) {
      const newDocumentObject = { ...documentObject };

      if (info.file.status === "done") {
        if (info?.file?.size > 1536000) {
          message.error("File size must be under 1.5MB");
          newDocumentObject[documentType] = {
            ...newDocumentObject[documentType],
            loading: false,
            file: null,
          };
        } else if (allowedFileTypes.includes(info.file.type) === false) {
          message.error(
            "You can only upload PNG, JPG, JPEG, BMP, PDF, or ZIP file!"
          );
          newDocumentObject[documentType] = {
            ...newDocumentObject[documentType],
            loading: false,
            file: null,
          };
        } else {
          getBase64(info.file.originFileObj, (url) => {
            setDocumentUrls((prevUrls) => ({
              ...prevUrls,
              [documentType]: url,
            }));
          });
          // File upload is completed
          newDocumentObject[documentType] = {
            ...newDocumentObject[documentType],
            loading: false,
            file: info.file.originFileObj,
          };
        }
      } else if (info.file.status === "uploading") {
        newDocumentObject[documentType] = {
          ...newDocumentObject[documentType],
          loading: true,
          file: null,
        };
      }
      setDocumentObject(newDocumentObject);
    }
  };

  const handleViewDocument = (documentType) => {
    setModalVisible(true);
    setModalContent(documentType);
  };

  const handleRemoveDocument = (documentType) => {
    setDocumentUrls((prevUrls) => ({
      ...prevUrls,
      [documentType]: "",
    }));

    setDocumentObject((prevObject) => ({
      ...prevObject,
      [documentType]: {
        ...prevObject[documentType],
        loading: false,
        file: null,
      },
    }));
  };

  const handleMouseEnter = (documentType) => {
    setHoverStates((prevHoverStates) => ({
      ...prevHoverStates,
      [documentType]: true,
    }));
  };

  const handleMouseLeave = (documentType) => {
    setHoverStates((prevHoverStates) => ({
      ...prevHoverStates,
      [documentType]: false,
    }));
  };

  function getFileTypeFromBase64(content) {
    const matches = content.match(/^data:([A-Za-z-+/]+);base64/);
    if (matches && matches.length > 1) {
      return matches[1];
    }
    return null;
  }

  function renameFile(file, documentType) {
    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts.pop();
    const newFileName = `${documentType}_${fileNameParts.join(
      "."
    )}.${fileExtension}`;
    return new File([file], newFileName, { type: file.type });
  }

  function getFileExtension(filename) {
    const dotIndex = filename.lastIndexOf(".");
    if (dotIndex !== -1) {
      return filename.slice(dotIndex + 1).toLowerCase();
    }
    return "";
  }

  function extractWordBeforeUnderscore(sentence) {
    const parts = sentence.split("_");
    if (parts.length > 1) {
      return parts[0];
    } else {
      return sentence;
    }
  }

  const handleMouseEnters = (imageUrl, fileExtension, name) => {
    setSelectedImageUrl(imageUrl);
    setSelectedImageUrlModal({
      url: imageUrl,
      extension: fileExtension,
      name: name,
    });
  };

  const getQuestionarries = async () => {
    const response = await getInvestorQuestionnaire();
    if (response) {
      setQuestionnaire(response);
    } else {
      console.error("Error fetching data:");
    }
  };

  useEffect(() => {
    getQuestionarries();
  }, []);

  useEffect(() => {
    setSelectedDocumentTypes(
      questionnaire?.investorSubType === INVESTOR_TYPE.ACCREDITED_INVESTOR
        ? ["PaySlips"]
        : ["letterEmployement"]
    );
    setDocumentObject(
      questionnaire?.investorSubType === INVESTOR_TYPE.ACCREDITED_INVESTOR
        ? {
            PaySlips: { loading: false },
          }
        : { letterEmployement: { loading: false } }
    );
  }, [questionnaire]);

  return (
    <div className="document-form">
      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: 50, color: "var(--kilde-blue)" }}
          />
        }
        spinning={getPoiLoader}
      >
        <div className="mb-40">
          <p className="kl-pi-title" style={{ marginBottom: 12 }}>
            Confirm Investor Status
          </p>
          <div className="sb-isc-info">
            <p className="sb-onboarding-subtitle mt-0 mb-10">
              Please confirm your status as an accredited/expert investor by
              uploading and sharing your documents with us.
            </p>
            <p className="sb-onboarding-subtitle m-0">
              To ensure accuracy, please provide income statements containing
              data from the preceding 12 months. For specific documents such as
              the annual Tax Declaration report, kindly provide the latest
              iteration available. Personal and financial asset statements
              should not be older than 3 months.
            </p>
          </div>
        </div>
        <div className="kl-pi-subdiv">
          <div>
            <div className="d-flex">
              {questionnaire?.investorSubType ===
              INVESTOR_TYPE.ACCREDITED_INVESTOR ? (
                <p className="kl-isc-subdivselect-active mr-5">
                  Accredited Investor Confirmation
                </p>
              ) : (
                <p className="kl-isc-subdivselect-active ml-5">
                  Expert Investor Confirmation
                </p>
              )}
            </div>
            <p className="kl-isc-subtitle">
              {questionnaire?.investorSubType ===
              INVESTOR_TYPE.ACCREDITED_INVESTOR
                ? "If you are an accredited investor, please provide any of the following documents to confirm your status. Below are some examples of documents you could provide:"
                : "If you are an expert investor, please provide any of the following document to confirm your status. Below are some examples of documents you could provide:"}
            </p>
            {questionnaire?.investorSubType ===
            INVESTOR_TYPE.ACCREDITED_INVESTOR ? (
              <ul className="documents-ul">
                <li>
                  <p className="color-blank">Pay slips/Tax declaration</p>
                </li>
                <li>
                  <p className="color-blank">Bank statements</p>
                </li>
                <li>
                  <p className="color-blank">
                    Investment statements (Capital Market Products, Bullion,
                    Stock, Cryptocurrency holdings)
                  </p>
                </li>
                <li>
                  <p className="color-blank">
                    Investment life policy statement
                  </p>
                </li>
                <li>
                  <p className="color-blank">
                    Financial statements and business registry excerpt
                    confirming the value of your shares in private companies
                  </p>
                </li>
                <li>
                  <p className="color-blank">
                    Excerpt from land/property registry
                  </p>
                </li>
              </ul>
            ) : (
              <ul className="documents-ul">
                <li>
                  <p className="color-blank">
                    Letter of employment stating the position and
                    responsibilities in the company; AND
                  </p>
                </li>
                <li>
                  <p className="color-blank">
                    Source document confirming the company deals with capital
                    market products (e.g. license)
                  </p>
                </li>
              </ul>
            )}
          </div>

          <div className=" sb-flex-wrap mt-40 w-100">
            {documentNames.length > 0 && (
              <Select
                style={{ width: "100%" }}
                suffixIcon={<img src={ArrowUpAndDownIcon} alt="arrow-icon" />}
                mode="multiple"
                placeholder="Select document types"
                defaultValue={documentNames && documentNames} // Set the default values from your array
                optionLabelProp="label"
                disabled
              >
                {data &&
                  data.map((option, id) => (
                    <Option key={id} value={option.key}>
                      {option.value}
                    </Option>
                  ))}
              </Select>
            )}

            {documentNames.length === 0 && (
              <Select
                style={{ width: "100%" }}
                suffixIcon={<img src={ArrowUpAndDownIcon} alt="arrow-icon" />}
                mode="multiple"
                placeholder="Select document types"
                value={selectedDocumentTypes}
                onChange={handleChange}
                optionLabelProp="label"
              >
                {data &&
                  data.map((option, id) => (
                    <Option key={id} value={option.key}>
                      {option.value}
                    </Option>
                  ))}
              </Select>
            )}
          </div>

          {imageUrls.length > 0 ? (
            <div className="sb-flex-wrap">
              {imageUrls.map((imageUrl, index) => {
                const fileExtension = getFileExtension(imageUrl.name);

                return (
                  <Col
                    key={finalImagesArray[index].id}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}
                  >
                    <div
                      className="image-container"
                      onMouseEnter={() =>
                        handleMouseEnters(
                          imageUrl.url,
                          fileExtension,
                          imageUrl.name
                        )
                      }
                      onMouseLeave={handleMouseLeave}
                    >
                      <p>{extractWordBeforeUnderscore(imageUrl?.name)}</p>
                      {fileExtension === "pdf" ? (
                        <div className="mr-10">
                          <div
                            style={{
                              width: "100%",
                              height: "195px",
                              objectFit: "contain",
                              border: "1px solid #d9d9d9",
                            }}
                          >
                            <FileOutlined className="pdf-icon" />
                          </div>
                        </div>
                      ) : fileExtension === "zip" ? (
                        <div
                          className="mr-10"
                          style={{
                            border: "1px solid #ddd",
                            height: "195px",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              top: "40%",
                              paddingLeft: 6,
                              display: "flex",
                              alignItems: "center",
                              gap: 6,
                              position: "absolute",
                            }}
                          >
                            <PaperClipOutlined style={{ color: "#1a202c" }} />
                            <p>{imageUrl?.name}</p>
                          </div>
                        </div>
                      ) : (
                        <div className="mr-10">
                          <img
                            style={{
                              width: "100%",
                              height: "200px",
                              objectFit: "contain",
                              border: "1px solid #d9d9d9",
                              marginRight: "5px",
                              marginLeft: "5px",
                              borderRadius: "12px",
                            }}
                            className="w-100"
                            src={imageUrl?.url}
                            alt={imageUrl?.name}
                          />
                        </div>
                      )}
                      {selectedImageUrl === imageUrl.url && (
                        <div
                          className="overlay"
                          onClick={() => {
                            if (selectedImageUrlModal.extension === "pdf") {
                              window.open(imageUrl.url, "_blank");
                            } else {
                              setIsModalOpen(true);
                              setTitle(selectedImageUrlModal?.name);
                            }
                          }}
                        >
                          <EyeOutlined className="eye-icon" />
                        </div>
                      )}
                    </div>
                  </Col>
                );
              })}
            </div>
          ) : (
            <Row gutter={8}>
              {Object.keys(documentObject).map((documentType) => {
                let fileExtension;
                fileExtension = documentUrls[documentType]
                  ? documentUrls[documentType].split(".").pop()
                  : "";

                if (fileExtension.startsWith("data:")) {
                  fileExtension = getFileTypeFromBase64(fileExtension);
                }
                return (
                  <Col key={documentType} xs={24} sm={12} md={9}>
                    <div className="sb-document-upload">
                      <label>{documentType}</label>

                      <div
                        className={`${
                          documentUrls[documentType] &&
                          hoverStates[documentType]
                            ? "hover-buttons"
                            : ""
                        }`}
                        onMouseEnter={() => handleMouseEnter(documentType)}
                        onMouseLeave={() => handleMouseLeave(documentType)}
                        key={documentType} // Use a unique key based on documentType
                      >
                        {documentUrls[documentType] ? (
                          <div className="image-container">
                            {fileExtension === "pdf" ||
                            fileExtension === "application/pdf" ? (
                              <div className="show-img">
                                <iframe
                                  src={documentUrls[documentType]}
                                  title="PDF Document"
                                  className="w-100"
                                  style={{ height: "190px" }}
                                />
                              </div>
                            ) : fileExtension ===
                              "application/x-zip-compressed" ? (
                              <div
                                className="mr-10"
                                style={{
                                  border: "1px solid #ddd",
                                  height: "195px",
                                  position: "relative",
                                }}
                              >
                                <div
                                  style={{
                                    top: "40%",
                                    left: "30%",
                                    paddingLeft: 6,
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 6,
                                    position: "absolute",
                                  }}
                                >
                                  <PaperClipOutlined
                                    style={{ color: "#1a202c" }}
                                  />
                                  <p>Zip file</p>
                                </div>
                              </div>
                            ) : (
                              <img
                                src={documentUrls[documentType]}
                                alt={documentType}
                                style={{
                                  width: "100%",
                                  height: "195px",
                                  objectFit: "contain",
                                  // borderRadius: "8px",
                                  border: "1px solid #d9d9d9",
                                }}
                              />
                            )}
                            {hoverStates[documentType] && (
                              <div className="hover-icon">
                                <div
                                  onClick={() =>
                                    handleViewDocument(
                                      documentUrls[documentType]
                                    )
                                  }
                                >
                                  {viewIcon}
                                </div>
                                <div
                                  onClick={() =>
                                    handleRemoveDocument(documentType)
                                  }
                                >
                                  {removeIcon}
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <Upload
                            name={documentType}
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={(file) => {
                              const renamedFile = renameFile(
                                file,
                                documentType
                              );
                              return renamedFile;
                            }}
                            customRequest={handleCustomRequest}
                            onChange={(info) =>
                              handleFileChange(info, documentType)
                            }
                          >
                            {uploadButton(documentType)}
                          </Upload>
                        )}
                      </div>
                    </div>
                  </Col>
                );
              })}
              <DocumentModal
                open={modalVisible}
                content={modalContent}
                onClose={() => setModalVisible(false)}
                title={title}
              />
            </Row>
          )}

          <Modal
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={null}
            width={500} // Set the width to your preference
            className="view-modal"
            title={title}
          >
            {selectedImageUrlModal.extension === "pdf" ? (
              <iframe
                src={`${selectedImageUrlModal.url}#toolbar=0`}
                title="PDF Document"
                style={{ width: "100%", height: "500px" }}
              />
            ) : selectedImageUrlModal.extension === "zip" ? (
              <div></div>
            ) : (
              <img
                src={selectedImageUrlModal.url}
                alt="Selected"
                className="w-100"
              />
            )}
          </Modal>
        </div>
      </Spin>
    </div>
  );
};

export default EntityDocuments;
