import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import DeviceMobile from "../../Assets/Images/DeviceMobile.svg";
import ROUTES from "../../Config/Routes.js";
import { getUser, twoFaAuth, twoFaInit } from "../../Apis/UserApi.js";

import "./style.css";
import Cookies from "js-cookie";
import AuthLayout from "../../Layouts/BlankHeaderLayout/AuthLayout.jsx";
import OtpInput from "react18-input-otp";
import { Link } from "react-router-dom";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault.jsx";
import { redirectToVue } from "../../Utils/Helpers.js";
import { message } from "antd";
import GlobalVariabels from "../../Utils/GlobalVariabels.js";
import { getSystemId, statusCheck } from "../../Apis/InvestorApi.js";
import { setStatusCheckResponse } from "../../Redux/Action/KycIndividual.js";
import { Redirection } from "../LoginPage/Redirection.jsx";
import { SupportChatButton } from "../../Utils/Reusables.js";
import { identify } from "../../Utils/Analytics.js";

const TwoFAPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState(false);
  const [code, setCode] = useState("");
  const [time, setTime] = useState(59);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [secFactorAuth, setSecFactorAuth] = useState("");
  const [mobile, setMobile] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  let currentEnv = GlobalVariabels.NODE_ENV;

  useEffect(() => {
    twoFaInit()
      .then((res) => {
        if (res?.length === 0) {
          message.error("Something went wrong, Try again!");
        } else {
          setSecFactorAuth(res?.type);
          setCode(res?.authenticationCode);
          setMobile(res?.mobilePhone);
          if (res?.type === "TOTP") {
            message.success(
              "Please check your authentication app for current one time password"
            );
          } else {
            message.success(
              "We've sent an OTP to your mobile number. Please check your messages."
            );
          }
        }
      })
      .catch((err) => {
        console.log("2Fa api error", err);
      });
  }, []);

  const resendInit = async () => {
    await twoFaInit()
      .then((res) => {
        if (res?.length === 0) {
          message.error("Something went wrong, Try again!");
        } else {
          setCode(res?.authenticationCode);
          setMobile(res?.mobilePhone);
          setIsTimerRunning(true);
          setTime(59);
          message.success(
            "We've sent an OTP to your mobile number. Please check your messages."
          );
        }
      })
      .catch((err) => {
        console.log("2Fa api error", err);
      });
  };

  const handleAuthentication = async () => {
    setLoader(true);
    if (otp !== "") {
      const requestBody = {
        code: otp,
      };
      const response = await twoFaAuth(requestBody);
      if (response?.token) {
        window?.dataLayer?.push({
          event: "authenticationTwoFactorConfirm",
        });
        Cookies.set("auth_inv_token", response.token);
        getUser()
          .then(async (profileResponse) => {
            identify(profileResponse);
            Cookies.set("user", JSON.stringify(profileResponse));
            if (profileResponse?.investorStatus === "REJECTED") {
              return navigate(ROUTES.KILDE_REJECTED_USER);
            }
            let regtankStatus;
            if (
              profileResponse?.vwoFeatures?.identityVerificationSystem
                ?.idvSystemToUse === "regtank"
            ) {
              const getSystId = await getSystemId();
              if (
                getSystId?.systemId !== null ||
                getSystId?.systemId !== undefined
              ) {
                regtankStatus = await statusCheck({
                  email: profileResponse?.email,
                  systemId: getSystId?.systemId,
                });
                await setStatusCheckResponse(regtankStatus, dispatch);
                Cookies.set("systemId", getSystId?.systemId);
              }
            }
            if (
              profileResponse?.vwoFeatures.redirectApp?.appToRedirect === "vue"
            ) {
              redirectToVue(
                profileResponse?.vwoFeatures.redirectApp?.appToRedirect
              );
              setLoader(false);
            } else {
              Redirection(
                setLoader,
                profileResponse,
                regtankStatus,
                dispatch,
                navigate,
                profileResponse?.vwoFeatures?.redirectApp
              );
            }
          })
          .catch((error) => {
            console.log("profile err", error);
            setLoader(false);
          });
      } else {
        setLoader(false);
      }
    } else {
      setLoader(false);
      message.error("Please enter OTP!");
    }
  };

  useEffect(() => {
    const intervalTimer = setInterval(() => {
      if (isTimerRunning) {
        setTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(intervalTimer);
            setIsTimerRunning(false);
          }
          return prevTime - 1;
        });
      }
    }, 1000);

    return () => clearInterval(intervalTimer);
  }, [isTimerRunning]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const inputStyle = {
    border: "1px solid #1A202C1A",
    borderRadius: "16px",
    width: windowWidth <= 380 ? "35px" : windowWidth <= 576 ? "45px" : "56px",
    marginLeft: "8px",
    marginRight: "8px",
    height: windowWidth <= 380 ? "35px" : windowWidth <= 576 ? "45px" : "56px",
    fontSize:
      windowWidth <= 380 ? "14px" : windowWidth <= 576 ? "16px" : "18px",
    fontWeight: "400",
  };

  return (
    <AuthLayout>
      <div className="sb-onboarding-form-container">
        <div className="sb-flex-column-item-center">
          <img src={DeviceMobile} alt="mobile" className="kl-mobile" />
        </div>
        <div>
          <p className="kl-title m-0">Two-Factor Authentication</p>

          <p className="kl-subtitle mt-10">
            {secFactorAuth === "TOTP"
              ? "Enter current one time password from your authenticarion app"
              : "Enter the verification code we sent to"}
          </p>
        </div>
        <div className="kl-title">
          <p style={{ fontSize: 18 }}>{mobile}</p>
          <p style={{ fontSize: 14 }}>Type your 6 digit security code</p>
          <div className="sb-otp-input-div">
            <OtpInput
              value={otp}
              onChange={setOtp}
              inputStyle={inputStyle}
              numInputs={6}
              renderSeparator={<span></span>}
              isInputNum={true}
              inputProps={{ type: "number", inputMode: "numeric" }}
              renderInput={(props) => (
                <input {...props} type="number" inputMode="numeric" />
              )}
            />
          </div>
        </div>
        <div className="sb-TwoFa-actions">
          {secFactorAuth === "SMS" && currentEnv === "DEV" && (
            <div>
              <p style={{ textAlign: "right", color: "#ddd" }}>
                Authentication Code: {code}
              </p>
            </div>
          )}
          <ButtonDefault
            title="Submit"
            block={true}
            loading={loader}
            onClick={handleAuthentication}
          />
        </div>
        <div className="sb-flex-column-item-center">
          {secFactorAuth === "SMS" && isTimerRunning && (
            <div className="kl-countdown resend-otp">
              <p>Resend OTP in</p>
              <span id="timer">{isTimerRunning ? `(${time}s)` : ""}</span>
            </div>
          )}
          <p className="kl-subtitle mt-10">
            Didn’t get the code ?{" "}
            {secFactorAuth === "SMS" && (
              <Link
                style={{ fontSize: 14 }}
                onClick={isTimerRunning ? null : resendInit}
                id="actionTimer"
                className={isTimerRunning ? "kl-p-none" : "kl-cursor-pointer"}
              >
                Resend
              </Link>
            )}
            {secFactorAuth === "SMS" && " or "}
            <Link
              style={{ fontSize: 14, color: "var(--kilde-blue)" }}
              to="tel:+65 695 077 68"
            >
              Call Us
            </Link>
          </p>
        </div>
      </div>
      <SupportChatButton />
    </AuthLayout>
  );
};

export default TwoFAPage;
